import React, { useEffect, useState } from "react";
import { Layout, Form, Input, Button, message, Divider, Row, Col, Card, DatePicker, TimePicker, Select, Typography } from 'antd';
import axios from 'axios';
import { UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { baseUrl, secretKey } from '../config';
import moment from 'moment';
import CryptoJS from 'crypto-js';



const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;


const { Header, Content } = Layout;

const FullMoonForm = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [txnDate, setTxnDate] = useState(moment());
  const [timeInDate, setTimeInDate] = useState(moment());
  const [timeOutDate, setTimeOutDate] = useState(null);


  const onFinish = async (values) => {
    values.action = 'save_send';

    if (txnDate) {
      const formattedDate = moment(txnDate).format('YYYY-MM-DD');
      values.visit_date = formattedDate;
    } else {
      values.visit_date = '';
    }

    if (timeInDate) {
      const formattedDate = moment(timeInDate).format('h:mm:ss A');
      values.visit_time_in = formattedDate;
    } else {
      values.visit_time_in = '';
    }

    if (timeOutDate) {
      const formattedDate = moment(timeOutDate).format('h:mm:ss A');
      values.visit_time_out = formattedDate;
    } else {
      values.visit_time_out = '';
    }


    axios.post(`${baseUrl}/visitor/create`, values)
      .then(response => {
        if (response.status === 200) {
          message.success('Thank you for visiting');
          form.resetFields();
          // const id = response.data.data.user.id;
          //const encryptedId = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();

          // const encodedId = encodeURIComponent(encryptedId);
          history.push(`/visitors`)

        } else {
          message.error(response.message);
        }
      })
      .catch(error => {
        message.error('There was an error saving the form data.');
      });
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setTxnDate(formattedDate)
    } else {
      setTxnDate(null);
    }
  };


  const onFinishFailed = (errorInfo) => {
    message.error('Please fill all required fields!');
  };

  const addVisitor = () => {
    history.push(`/visitors`)
  }

  return (
    <Layout style={{

      backgroundSize: 'cover',
      minHeight: '100vh'
    }}>

      <Header style={{ backgroundColor: 'rgba(251, 189, 70, 0.78)', textAlign: 'center', padding: '20px 10', height: "auto" }}>
        <h1 style={{ color: 'red', fontSize: '28px', fontWeight: 'bold', marginBottom: '10px' }}>
          जय गुरु महाराज री सा
        </h1>

        <h2 style={{ color: '#fff', fontWeight: 'bold', fontSize: '16px', lineHeight: '1.5' }}>
          अखिल भारतीय राजपुरोहित समाज विकास संस्थान <br /> श्री खेतेश्वर ब्रह्मधाम तीर्थ, आसोतरा
        </h2>
      </Header>

      <Divider />

      {/* Content with improved styling and responsive form */}
      <Content style={{ padding: '10px', minHeight: '80vh' }}>
        <Row justify="center">
        <Col xs={24} sm={24} md={20} lg={16} xl={12}>
            <Card
              title="New Visitor"
              bordered={false}
              style={{
                background: 'rgba(255, 255, 255, 0.8)', // Transparent background
                borderRadius: '10px',
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)', 
              }}
              headStyle={{
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: 'bold',
                color: '#333',
              }}
              extra={
                <div style={{ display: 'flex', gap: '10px' }}> 
                
                  <Button type="primary" onClick={addVisitor}  >Vistor List</Button>

                </div>
              }
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{ 
                  visit_branch: "श्री खेतेश्वर गुरुकुल, मायलावास",
                  visit_date: moment(), // Default current date
                  visit_time_in: moment(), // Default current time
                 }}
              >

                <Form.Item
                  label="Phone No"
                  name="phone_no"
                  rules={[
                    { required: true, message: 'Please enter Phone No' },
                    { pattern: /^[0-9]{10}$/, message: 'Enter Valid Phone No' }
                  ]}
                >
                  <Input placeholder="Enter Phone No" />
                </Form.Item>


                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please enter name' }]}
                >
                  <Input placeholder="Enter Name" />
                </Form.Item>


                <Form.Item
                  label="Visit Branch"
                  name="visit_branch"
                  rules={[{ required: true, message: 'Please enter Visit branch' }]}
                >
                  <Select placeholder="Select Branch" defaultValue="श्री खेतेश्वर गुरुकुल, मायलावास">
                    <Option value="अखिल भारतीय राजपुरोहित समाज विकास संस्थान">अखिल भारतीय राजपुरोहित समाज विकास संस्थान</Option>
                    <Option value="श्री खेतेश्वर गुरुकुल, मायलावास">श्री खेतेश्वर गुरुकुल, मायलावास</Option>
                    <Option value="श्री खेतेश्वर गुरुकुल, बिजरोल खेड़ा">श्री खेतेश्वर गुरुकुल, बिजरोल खेड़ा</Option>
                    <Option value="श्री खेतेश्वर गुरुकुल, सायला">श्री खेतेश्वर गुरुकुल, सायला</Option>
                    <Option value="श्री खेतेश्वर गुरुकुल, सवाई छोटी">श्री खेतेश्वर गुरुकुल, सवाई छोटी</Option>
                    <Option value="श्री खेतेश्वर भवन, हरिद्वार">श्री खेतेश्वर भवन, हरिद्वार</Option>
                  </Select>

                </Form.Item>


                <Form.Item
                  label="Purpose of Visit"
                  name="purpose_visit"
                >
                  <Input placeholder="Purpose of Visit  " />
                </Form.Item>


                <Form.Item
                  label="Visit Date"
                  rules={[{ required: true, message: 'Please enter Visit Date' }]}
                >
                  <DatePicker onChange={handleDateChange} value={txnDate && txnDate != "0000-00-00" && moment(txnDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select DOB" prefix={<CalendarOutlined />} />

                </Form.Item>


                <Form.Item
                  label="Visit Time In"
                  name="visit_time_in" 
                >
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    value={timeInDate} // Pass valid moment object or null
                    onChange={(time) => setTimeInDate(time)}
                  />
                </Form.Item>


                <Form.Item
                  label="Visit Time Out"
                  name="visit_time_out"
                >
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    value={timeOutDate} // Pass valid moment object or null
                    onChange={(time) => setTimeOutDate(time)}
                  />


                </Form.Item>


                <Form.Item
                  label="Remark"
                  name="remark"

                >
                  <Input placeholder="Remark" />
                </Form.Item>



                {/* Submit Button */}
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default FullMoonForm;
