import React, { useState, useEffect } from "react";
import { Result, Button, Spin, Table, Layout, Form, Input, message, Divider, Row, Col, Card, Typography } from "antd";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { baseUrl, secretKey } from "../config";
import CryptoJS from 'crypto-js';
import moment from 'moment';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const { Title, Text } = Typography;

const ThankYouPage = () => {
  const query = useQuery();
  const id = query.get('id');
  const navigate = useHistory();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch user details by id from API
    const fetchUserData = async () => {

      if (!id) {
        setError("No ID provided.");
        setLoading(false);
        return;
      }


      try {
        // Decode the ID if it's URL-encoded
        const decodedId = decodeURIComponent(id);
        // Decrypt the ID
        const bytes = CryptoJS.AES.decrypt(decodedId, secretKey);
        const decryptedId = bytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedId) {
          setError("Invalid encrypted ID.");
          setLoading(false);
          return;
        }

        if (!decryptedId) {
          setError("Invalid encrypted ID.");
          setLoading(false);
          return;
        }


        const response = await axios.get(`${baseUrl}/onlinePaymentUser/paymentSuccessDetail/${decryptedId}`);
        setUserData(response.data.result); // Assuming response.data contains user details
        setLoading(false);

      } catch (err) {
        setError("Failed to fetch user details.");
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const handleBackHome = () => {

    navigate.push("/");

    navigate.push("/purnima-member");

  };

  if (loading) {
    return <Spin tip="Loading user details..." style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />;
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Something went wrong"
        subTitle={error}
        extra={[
          <Button type="primary" key="home" onClick={handleBackHome}>
            Back to Home
          </Button>,
        ]}
      />
    );
  }

  // Define the columns for the Ant Design table
  const columns = [
    {
      title: "समर्पण",
      dataIndex: "field",
      key: "field",
      responsive: ["md"], // Show only on medium to large screens
      width: 150,
    },
    {
      title: "विवरण",
      dataIndex: "value",
      key: "value",
      render: (text) => <strong>{text}</strong>,
    },
  ];

  // Define the data for the table from the userData
  const data = [
    { key: "1", field: "Transaction Details", value: userData?.utr_number },
    { key: "2", field: "नाम", value: userData?.name },
    { key: "3", field: "संपर्क सूत्र", value: userData?.phone_no },
    {
      key: "5",
      field: " समर्पण  दिनांक",
      value: userData?.createdAt
        ? moment(userData.createdAt).format('DD MMM YYYY')
        : '-'
    },

  ];

  return (
    <div>

      {/* Loading Overlay */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(251, 252, 253, 0.5)", // Semi-transparent blue background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Spin tip="Processing payment..." size="large" />
        </div>
      )}

      <Row justify="center" style={{ padding: "40px 0", backgroundColor: "#f5f7fa" }}>
        <Col xs={24} md={16} lg={12}>
          <Card
            style={{
              backgroundColor: "#ffffff",
              padding: "20px",
              borderRadius: "12px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
            bodyStyle={{ padding: 0 }}
          >
            <div style={{ padding: "20px", textAlign: "center" }}>

              <Row className="inVoiceheader" style={{ backgroundColor: "#FFFF00", padding: "20px" }}>
                <Col xs={24} sm={8} style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <Title style={{ fontSize: '16px', margin: 0 }} level={5}>|| ॐ ब्रह्मणे नमः ||</Title>
                </Col>
                <Col xs={24} sm={8} style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <Title style={{ fontSize: '16px', margin: 0 }} level={5}>|| श्री गणेशाय नम: ||</Title>
                </Col>
                <Col xs={24} sm={8} style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <Title style={{ fontSize: '16px', margin: 0 }} level={5}>|| श्री खेतेश्वराय नमः ||</Title>
                </Col>
              </Row>



              <Result
                status="success"
                title={<Title level={3} style={{ color: "#1890ff" }}>अखिल भारतीय राजपुरोहित समाज विकास संस्थान आसोतरा</Title>}
                subTitle={<Text type="secondary"><strong>समाज शिक्षा निधि समर्पण योजना</strong></Text>}
                style={{ marginBottom: "5px", paddingTop: "0px", marginTop: "0px" }}
              />

              <Text style={{ fontSize: "16px", color: "#595959", marginBottom: "20px", display: "block" }}>
                आदरणीय, आप द्वारा दिए जा रहे शिक्षा समर्पण के लिए बहुत बहुत धन्यवाद।
              </Text>

              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                style={{ backgroundColor: "#fafafa", borderRadius: "8px", marginBottom: "20px" }}
                rowKey="key"
              />

              <div style={{ marginBottom: "20px", textAlign: "left" }}>

                <div style={{ marginBottom: "20px", display: "flex", gap: "20px" }}>

                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
                    <Text strong style={{ fontSize: "16px", color: "#595959", marginBottom: "8px" }}>
                    समर्पण राशि: 
                    </Text>
                    <div
                      style={{
                        fontWeight: "600",
                        padding: "10px",
                        borderRadius: "8px",
                        border: "1px solid #d9d9d9",
                        backgroundColor: "#fafafa",
                        width: "100%",
                        fontSize: "16px",
                        color: "#595959",
                      }}
                    >
                      Rs. {userData?.amount}
                    </div>
                  </div>


                </div>


              </div>

              <Text style={{ padding: "20px", backgroundColor: "#FFFF00", fontSize: "14px", fontWeight: "600", color: "#000", display: "block", marginTop: "20px" }}>
                नोट - शिक्षा समर्पण प्राप्त की पुष्टि होने के बाद (0 - 12) घण्टे के भीतर रसीद आपके व्हाट्सएप नंबर पर भेजी जायेगी।
                संपर्क सूत्र - 7023131008
              </Text>


            </div>
          </Card>
        </Col>
      </Row>
    </div>

  );
};

export default ThankYouPage;
