import React, { useState, useEffect } from "react";
import { Result, Button, Spin, Table, Row, Col, Typography, Input, Card, message } from "antd";
import { useLocation,useHistory } from "react-router-dom";
import { PlusOutlined, MinusOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';


import axios from "axios";
import { baseUrl, secretKey,rozerpay_key_id } from "../config";
import CryptoJS from "crypto-js";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import moment from 'moment';
import dayjs from 'dayjs';
import 'antd/dist/antd.css';
import '../assets/styles/custom_style.css';
 

//npm install --save-dev @types/crypto-js --force
//npm install --save-dev @types/react-router-dom --force
//npm install --save-dev @types/react-dom  

const { Title, Text } = Typography;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

 


// const id = 58;
// const encryptedId = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();   
// const encodedId = encodeURIComponent(encryptedId);
// console.log(encodedId);
 
 

const DecodePayment = () => { 

  const query = useQuery();
  const id = query.get("id");

  //const id = 5432;
const encryptedId = CryptoJS.AES.encrypt(id ?? ''.toString(), secretKey).toString();   
const encodedId = encodeURIComponent(encryptedId);
console.log(encodedId);

  
  return (
    <div> 
       <h1>User ID : {encodedId} </h1>
    </div>
  );
};

export default DecodePayment;
