import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Image, Typography, notification, Form, Input, InputNumber, Select, Upload, Checkbox, DatePicker, Modal, message, Avatar, Tabs,Descriptions,Divider,AutoComplete } from "antd";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import { UploadOutlined, UserOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined, PhoneOutlined, MailOutlined, CalendarOutlined, HomeOutlined } from '@ant-design/icons';
import '../../assets/styles/custom_style.css';
import UserModalContent from './UserModalContent';
import moment from 'moment';
import PdfImage from "../../assets/images/document.png";
import OccupationDetails from "../sn_payments/OccupationDetails";
import FamilyDetails from "../sn_payments/FamilyDetails";
import SocialStatus from "../sn_payments/SocialStatus";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TabPane } = Tabs;

function UsersForm() {
    const { id } = useParams(); // Extract id from URL
    const history = useHistory();
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [bussinessURL, setBussinessURL] = useState(false);
    const [bussinessImageUrl, setBussinessImageUrl] = useState(false);
    const [bussinessFile, setBussinessFile] = useState(null);  // state to store the selected file
    const [file, setFile] = useState(null);  // state to store the selected file
    const [multiDocument, setMultiDocument] = useState([])
    const [fileList, setFileList] = useState([]);
    const [permanentAddress, setPermanentAddress] = useState({
        per_address_en: "", // Add default values for permanent address fields
        per_village_en: "",
        per_post_en: "",
        per_tahsil_en: "",
        per_pin_code_en: "",
        per_district_en: "",
        per_state_en: ""
    });
    const [temporaryAddress, setTemporaryAddress] = useState({
        tem_address_en: "", // Add default values for temporary address fields
        tem_street_en: "",
        tem_city_en: "",
        tem_district_en: "",
        tem_sambhag_en: "",
        tem_state_en: "",
        tem_country_en: ""
    });
    const [socialMediaLinks, setSocialMediaLinks] = useState({
        facebook_url: "", // Add default values for social media links
        instagram_url: "",
        twitter_url: ""
    });

    const handleImageChange = (e) => {
        // Handle file selection here
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onload = (event) => {
            setImageUrl(event.target.result);
        };
        reader.readAsDataURL(file);
        message.info(`Selected file: ${file.name}`);
    };



    const handleButtonClick = () => {
        // Programmatically trigger the input file selection
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };

    const handleBussinessImageChange = (e) => {
        // Handle file selection here
        const file = e.target.files[0];
        console.log("file", file);
        setBussinessFile(file);
        const reader = new FileReader();
        reader.onload = (event) => {
            console.log("event.target.result", event.target.result);
            setBussinessImageUrl(event.target.result);
        };
        reader.readAsDataURL(file);
        message.info(`Selected file: ${file.name}`);
    };

    const handleBussinessButtonClick = () => {
        // Programmatically trigger the input file selection
        const fileInput = document.getElementById('fileBussinessInput');
        fileInput.click();
    };

    useEffect(() => {
        // Check if id exists to determine if it's an update mode
        if (id) {
            setIsUpdateMode(true);
            fetchUserDetails();
        }
    }, [id]);

    const fetchUserDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/users/read/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                // setRoleData(response.data.result);
                const userData = response.data.result;
                userData.dob = userData.dob ? moment(userData.dob) : null;
                setPermanentAddress({
                    per_address_en: userData.per_address_en,
                    per_village_en: userData.per_village_en,
                    per_post_en: userData.per_post_en,
                    per_tahsil_en: userData.per_tahsil_en,
                    per_pin_code_en: userData.per_pin_code_en,
                    per_district_en: userData.per_district_en,
                    per_state_en: userData.per_state_en,
                    per_address_hn: userData.per_address_hn,
                    per_village_hn: userData.per_village_hn,
                    per_post_hn: userData.per_post_hn,
                    per_tahsil_hn: userData.per_tahsil_hn,
                    per_pin_code_hn: userData.per_pin_code_hn,
                    per_district_hn: userData.per_district_hn,
                    per_state_hn: userData.per_state_hn
                });
                setMultiDocument(userData?.uploadDocuments ? JSON.parse(userData?.uploadDocuments) : [])
                // Set default values for temporary address
                setTemporaryAddress({
                    tem_address_en: userData.tem_address_en,
                    tem_street_en: userData.tem_street_en,
                    tem_city_en: userData.tem_city_en,
                    tem_district_en: userData.tem_district_en,
                    tem_sambhag_en: userData.tem_sambhag_en,
                    tem_state_en: userData.tem_state_en,
                    tem_country_en: userData.tem_country_en,
                    tem_address_hn: userData.tem_address_hn,
                    tem_street_hn: userData.tem_street_hn,
                    tem_city_hn: userData.tem_city_hn,
                    tem_district_hn: userData.tem_district_hn,
                    tem_sambhag_hn: userData.tem_sambhag_hn,
                    tem_state_hn: userData.tem_state_hn,
                    tem_country_hn: userData.tem_country_hn
                });

                // Set default values for social media links
                setSocialMediaLinks({
                    facebook_url: userData.facebook_url,
                    instagram_url: userData.instagram_url,
                    twitter_url: userData.twitter_url
                });
                form.setFieldsValue(userData);

                if (userData.profile_img) {
                    setImageUrl(userData.profile_img);
                }

            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            Object.keys(values).forEach(key => {
                formData.append(key, values[key] ? values[key] : '');
            });
            if (file) {
                formData.append("file", file);
            }

            if (fileList.length > 0) {
                fileList.forEach(file => {
                    const blob = new Blob([file.binary], { type: file.type });
                    formData.append('multiFiles', blob, file.name);
                });
            }

            // Iterate over the keys of the permanentAddress object
            for (const key in permanentAddress) {
                // Check if the value is not empty
                if (permanentAddress[key]) {
                    // Append the key-value pair to the FormData object
                    formData.append(key, permanentAddress[key]);
                }
            }

            // Iterate over the keys of the temporaryAddress object
            for (const key in temporaryAddress) {
                // Check if the value is not empty
                if (temporaryAddress[key]) {
                    // Append the key-value pair to the FormData object
                    formData.append(key, temporaryAddress[key]);
                }
            }

            // Iterate over the keys of the socialMediaLinks object
            for (const key in socialMediaLinks) {
                // Check if the value is not empty
                if (socialMediaLinks[key]) {
                    // Append the key-value pair to the FormData object
                    formData.append(key, socialMediaLinks[key]);
                }
            }

            if (isUpdateMode) {
                const response = await axios.patch(`${baseUrl}/users/update/${id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'User updated successfully!',
                        placement: 'topRight'
                    });
                    history.push('/users');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            } else {
                const response = await axios.post(`${baseUrl}/users/create`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'User added successfully!',
                        placement: 'topRight'
                    });
                    //Show model data here
                    setAddedUserData(values); // Save added user data
                    setIsModalVisible(true); // Show modal
                    form.resetFields();
                    // history.push('/users');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleImageUpload = (file, fileList) => {
        // Perform your upload logic here
        console.log("Uploading image:", file);
        // Return false to prevent automatic upload
        return false;
    };

    const [showPermanentAddress, setShowPermanentAddress] = useState(false);
    const [showTemporaryAddress, setShowTemporaryAddress] = useState(false);
    const [showSocialLink, setShowSocialLink] = useState(false);
    const [showUloadFile, setshowUloadFile] = useState(false);

    const handlePermanentAddressCheckboxChange = (e) => {
        setShowPermanentAddress(e.target.checked);
    };

    const handleTemporaryAddressCheckboxChange = (e) => {
        setShowTemporaryAddress(e.target.checked);
    };
    const handleSocialLinkCheckboxChange = (e) => {
        setShowSocialLink(e.target.checked);
    };

    const handleUploadFileCheckboxChange = (e) => {
        setshowUloadFile(e.target.checked);
    };

    const beforeUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setFileList(prevState => [...prevState, { name: file.name, binary: e.target.result }]);
        };
        reader.readAsArrayBuffer(file);
        return false; // Prevent automatic upload
    };

    // Function to handle input change for permanent address fields
    const handlePermanentAddressChange = (fieldName, value) => {
        // Update the state with the new value
        setPermanentAddress({
            ...permanentAddress,
            [fieldName]: value
        });
    };

    // Function to handle input change for temporary  address fields
    const handleTemporaryAddressChange = (fieldName, value) => {
        // Update the state with the new value
        setTemporaryAddress({
            ...temporaryAddress,
            [fieldName]: value
        });
    };

    // Function to handle input change for social media fields
    const handleSocialMediaChange = (fieldName, value) => {
        // Update the state with the new value
        setSocialMediaLinks({
            ...socialMediaLinks,
            [fieldName]: value
        });
    };

    //For model show data
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addedUserData, setAddedUserData] = useState(null);
    const handleModalClose = () => {
        setIsModalVisible(false);
        setAddedUserData(null);
        history.push('/users');
    };
    const userModalContent = addedUserData ? (
        <div>
            <p><strong>Phone No.:</strong> {addedUserData.phone_no}</p>
            {/* Add more fields as required */}
        </div>
    ) : null;


    // Mapping between English and Hindi values
    const relationMapping = {
        'Son Of': 'पुत्र श्री',
        'Daughter Of': 'पुत्री श्री',
        'Wife Of': 'पत्‍नी श्री'
    };

    // Function to handle change
    const handleRelationChange = (value) => {
        const hindiValue = relationMapping[value];
        form.setFieldsValue({
            relation_en: value,
            relation_hn: hindiValue
        });
    };

    const handleUpperCaseChange = (fieldName, e) => {
        const value = e.target.value;
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        form.setFieldsValue({
            [fieldName]: capitalizedValue
        });
    };

    const [selectedAddressSection, setSelectedAddressSection] = useState(null);

    const handleAddressCheckboxChange = (formType) => {
        setSelectedAddressSection(selectedAddressSection === formType ? null : formType);
    };

    return (
        <div className="tabled">
            <Tabs defaultActiveKey="1" type="card"
                size='large'>
                <TabPane tab="Basic Details" key="1">
                    <Row gutter={[24, 0]}>
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                            >
                                <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                                    <Row gutter={[16, 16]}>


                                        <Col xs={24}><Title level={5}>User Details</Title> </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Phone No." name="phone_no"
                                                rules={[{ required: true, message: 'Please enter phone no' },
                                                {
                                                    pattern: /^\d{10,12}$/,
                                                    message: 'Phone no must be between 10 and 12 digits!',
                                                }
                                                ]} >
                                                <Input placeholder="Enter Phone no." prefix={<PhoneOutlined />} readOnly />
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Email Address" name="email_address"
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: 'The input is not a valid email address!',
                                                    },
                                                ]} >
                                                <Input placeholder="Enter Email Address" prefix={<MailOutlined />} />
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="DOB" name="dob" >
                                                <DatePicker className="filter_dob" format="DD-MM-YYYY" placeholder="Select Date of Birth" prefix={<CalendarOutlined />} />
                                            </Item>
                                        </Col>


                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Name" name="name_eng" rules={[{ required: true, message: 'Please enter name' }]}>
                                                <Input placeholder="Enter User Name" prefix={<UserOutlined />} />
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Relation" name="relation_en"  >
                                                <Select placeholder="Please select Relation" onChange={handleRelationChange}>
                                                    <Option value="Son Of">Son Of</Option>
                                                    <Option value="Wife Of">Wife Of</Option>
                                                    <Option value="Daughter Of">Daughter Of</Option>
                                                </Select>
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Father Name" name="father_name_en" >
                                                <Input placeholder="Enter Father Name" prefix={<UserOutlined />} />
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="नाम" name="name_hn" >
                                                <Input placeholder="कृपया नाम दर्ज करें।" prefix={<UserOutlined />} />
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="संबंध" name="relation_hn" >
                                                <Select placeholder="कृपया संबंध चुनें।">
                                                    <Option value="पुत्र श्री">पुत्र श्री</Option>
                                                    <Option value="पुत्री श्री">पुत्री श्री</Option>
                                                    <Option value="पत्‍नी श्री">पत्‍नी श्री</Option>
                                                </Select>
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="पिता का नाम" name="father_name_hn" >
                                                <Input placeholder="कृपया पिता का नाम दर्ज करें।" prefix={<UserOutlined />} />
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Caste" name="caste" >
                                                <Input placeholder="Caste Name" onChange={(e) => handleUpperCaseChange('caste', e)} />
                                            </Item>
                                        </Col>


                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Gotra" name="gotra" >
                                                <Input placeholder="Gotra Name" onChange={(e) => handleUpperCaseChange('gotra', e)} />
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Kuldevi" name="kuldevi" >
                                                <Input placeholder="Kuldevi Name" onChange={(e) => handleUpperCaseChange('kuldevi', e)} />
                                            </Item>
                                        </Col>
                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Kulrishi" name="kulrishi" >
                                                <Input placeholder="Kulrishi name" onChange={(e) => handleUpperCaseChange('kulrishi', e)} />
                                            </Item>
                                        </Col>



                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Alternate Phone No" name="alternate_phone_no" >
                                                <Input placeholder="Enter Alternate Phone No" prefix={<PhoneOutlined />} />
                                            </Item>
                                        </Col>
                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Alt Phone No2" name="alternate_phone_no2" >
                                                <Input placeholder="Alt Phone No2" prefix={<PhoneOutlined />}
                                                    rules={[{ message: 'Please enter phone no' },
                                                    {
                                                        pattern: /^\d{10,12}$/,
                                                        message: 'Phone no must be between 10 and 12 digits!',
                                                    }
                                                    ]}
                                                />
                                            </Item>
                                        </Col>
                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Status" name="status" rules={[{ required: true, message: 'Please select status' }]} >
                                                <Select placeholder="Please select status">
                                                    <Option value="Active">Active</Option>
                                                    <Option value="Inactive">Inactive</Option>
                                                </Select>
                                            </Item>
                                        </Col>

                                        <Col xs={24} sm={12} lg={4}>
                                            <Item label="Profile Photo" name="image" valuePropName="fileList" getValueFromEvent={normFile}>
                                                <div className="">
                                                    <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleImageChange} />
                                                    <div>
                                                        <Avatar size={80} icon={<UserOutlined />}
                                                            src={imageUrl ? imageUrl : localStorage.getItem('profile_img')}
                                                            style={{ marginBottom: 16 }}
                                                        />
                                                        <div>
                                                            <Button icon={<UploadOutlined />} onClick={handleButtonClick}> Upload </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Item>
                                        </Col>


                                        <Descriptions bordered column={{ xs: 1, sm: 2, lg: 4 }}>
                                            <Descriptions.Item label="Permanent Address">
                                                <Checkbox
                                                    checked={selectedAddressSection === 'permanentAddress'}
                                                    onChange={() => handleAddressCheckboxChange('permanentAddress')}
                                                ></Checkbox>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Temporary Address">
                                                <Checkbox
                                                    checked={selectedAddressSection === 'temporaryAddress'}
                                                    onChange={() => handleAddressCheckboxChange('temporaryAddress')}
                                                ></Checkbox>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Social Media Link">
                                                <Checkbox
                                                    checked={selectedAddressSection === 'socialLink'}
                                                    onChange={() => handleAddressCheckboxChange('socialLink')}
                                                ></Checkbox>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Upload File/document">
                                                <Checkbox
                                                    checked={selectedAddressSection === 'uploadFile'}
                                                    onChange={() => handleAddressCheckboxChange('uploadFile')}
                                                ></Checkbox>
                                            </Descriptions.Item>
                                        </Descriptions>

                                       

                                        {selectedAddressSection === 'permanentAddress' && (
                                            <>
                                                <Col xs={24}><Title level={5}>Permanent Address</Title> </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Street/Building/House No.">
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_address_en", e.target.value)
                                                        }
                                                            value={permanentAddress.per_address_en} placeholder="Enter Street/Building/House No." prefix={<HomeOutlined />} />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Village" >
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_village_en", e.target.value)
                                                        }
                                                            value={permanentAddress.per_village_en} placeholder="Enter Village Name" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Post"  >
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_post_en", e.target.value)
                                                        }
                                                            value={permanentAddress.per_post_en} placeholder="Enter Post" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Tahsil" >
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_tahsil_en", e.target.value)
                                                        }
                                                            value={permanentAddress.per_tahsil_en} placeholder="Enter Tahsil" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Pin Code"  >
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_pin_code_en", e.target.value)
                                                        }
                                                            value={permanentAddress.per_pin_code_en} placeholder="Enter Pin Code" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="District"  >
                                                        <Select onChange={(value) =>
                                                            handlePermanentAddressChange("per_district_en", value)
                                                        }
                                                            value={permanentAddress.per_district_en} placeholder="Select District">
                                                            <Option value="Ajmer">Ajmer</Option>
                                                            <Option value="Jaipur">Jaipur</Option>
                                                            <Option value="Nagour">Nagour</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="State"  >
                                                        <Select onChange={(value) =>
                                                            handlePermanentAddressChange("per_state_en", value)
                                                        }
                                                            value={permanentAddress.per_state_en} placeholder="Please select State">
                                                            <Option value="Rajasthan">Rajasthan</Option>
                                                            <Option value="Punjab">Punjab</Option>
                                                            <Option value="Hariyana">Hariyana</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>

                                                

                                                <Col xs={24}><Title level={5}>स्थाई पता</Title> </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="गली/बास/मकान नंबर."  >
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_address_hn", e.target.value)
                                                        }
                                                            value={permanentAddress.per_address_hn} placeholder="गली/बास/मकान नंबर दर्ज करें" prefix={<HomeOutlined />} />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="गांव" >
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_village_hn", e.target.value)
                                                        }
                                                            value={permanentAddress.per_village_hn} placeholder="गांव दर्ज करें" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="पोस्ट" >
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_post_hn", e.target.value)
                                                        }
                                                            value={permanentAddress.per_post_hn} placeholder="पोस्ट दर्ज करें" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="तहसील" >
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_tahsil_hn", e.target.value)
                                                        }
                                                            value={permanentAddress.per_tahsil_hn} placeholder="तहसील दर्ज करें" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="पिनकोड" >
                                                        <Input onChange={(e) =>
                                                            handlePermanentAddressChange("per_pin_code_hn", e.target.value)
                                                        }
                                                            value={permanentAddress.per_pin_code_hn} placeholder="पिनकोड दर्ज करें" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="जिला" >
                                                        <Select onChange={(value) =>
                                                            handlePermanentAddressChange("per_district_hn", value)
                                                        }
                                                            value={permanentAddress.per_district_hn} placeholder="जिला दर्ज करें">
                                                            <Option value="Ajmer">Ajmer</Option>
                                                            <Option value="Jaipur">Jaipur</Option>
                                                            <Option value="Nagour">Nagour</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="राज्य" >
                                                        <Select onChange={(value) =>
                                                            handlePermanentAddressChange("per_state_hn", value)
                                                        }
                                                            value={permanentAddress.per_state_hn} placeholder="कृपया राज्य दर्ज करें">
                                                            <Option value="Rajasthan">Rajasthan</Option>
                                                            <Option value="Punjab">Punjab</Option>
                                                            <Option value="Hariyana">Hariyana</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>

                                            </>
                                        )}

                                         
                                        {selectedAddressSection === 'temporaryAddress' && (
                                            <>
                                                <Col xs={24}><Title level={5}>Temporary Address</Title> </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="House No/Flat No" >
                                                        <Input onChange={(e) =>
                                                            handleTemporaryAddressChange("tem_address_en", e.target.value)
                                                        }
                                                            value={temporaryAddress.tem_address_en} placeholder="House No/Flat No/Building Name" prefix={<HomeOutlined />} />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Town/City/Ward" >
                                                        <Input onChange={(e) =>
                                                            handleTemporaryAddressChange("tem_street_en", e.target.value)
                                                        }
                                                            value={temporaryAddress.tem_street_en} placeholder="Enter Town/City/Ward" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="City" >
                                                        <Input onChange={(e) =>
                                                            handleTemporaryAddressChange("tem_city_en", e.target.value)
                                                        }
                                                            value={temporaryAddress.tem_city_en} placeholder="Enter City" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="District" >
                                                        <Select onChange={(value) =>
                                                            handleTemporaryAddressChange("tem_district_en", value)
                                                        }
                                                            value={temporaryAddress.tem_district_en} placeholder="Please select District">
                                                            <Option value="Ajmer">Ajmer</Option>
                                                            <Option value="Jaipur">Jaipur</Option>
                                                            <Option value="Nagour">Nagour</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>
 

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="State" >
                                                        <Select onChange={(value) =>
                                                            handleTemporaryAddressChange("tem_state_en", value)
                                                        }
                                                            value={temporaryAddress.tem_state_en} placeholder="Please select State">
                                                            <Option value="Rajasthan">Rajasthan</Option>
                                                            <Option value="Punjab">Punjab</Option>
                                                            <Option value="Hariyana">Hariyana</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Country" >
                                                        <Select onChange={(value) =>
                                                            handleTemporaryAddressChange("tem_country_en", value)
                                                        }
                                                            value={temporaryAddress.tem_country_en} placeholder="Please select Country">
                                                            <Option value="India">India</Option>
                                                            <Option value="USA">USA</Option>
                                                            <Option value="UK">UK</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>

                                                <Col xs={24}><Title level={5}>अस्थाई पता</Title> </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="मकान नंबर/ फ्लैट नम्बर/बिल्डिंग नाम" >
                                                        <Input onChange={(e) =>
                                                            handleTemporaryAddressChange("tem_address_hn", e.target.value)
                                                        }
                                                            value={temporaryAddress.tem_address_hn} placeholder="मकान नंबर/ फ्लैट नम्बर/बिल्डिंग नाम दर्ज करें" prefix={<HomeOutlined />} />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="नगर/कस्बा/वार्ड" >
                                                        <Input onChange={(e) =>
                                                            handleTemporaryAddressChange("tem_street_hn", e.target.value)
                                                        }
                                                            value={temporaryAddress.tem_street_hn} placeholder="नगर/कस्बा/वार्ड दर्ज करें" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="सिटी" >
                                                        <Input onChange={(e) =>
                                                            handleTemporaryAddressChange("tem_city_hn", e.target.value)
                                                        }
                                                            value={temporaryAddress.tem_city_hn} placeholder="सिटी दर्ज करें" />
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="जिला" >
                                                        <Select onChange={(value) =>
                                                            handleTemporaryAddressChange("tem_district_hn", value)
                                                        }
                                                            value={temporaryAddress.tem_district_hn} placeholder="जिला दर्ज करें">
                                                            <Option value="Ajmer">Ajmer</Option>
                                                            <Option value="Jaipur">Jaipur</Option>
                                                            <Option value="Nagour">Nagour</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>

                                             


                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="राज्य" >
                                                        <Select onChange={(value) =>
                                                            handleTemporaryAddressChange("tem_state_hn", value)
                                                        }
                                                            value={temporaryAddress.tem_state_hn} placeholder="राज्य दर्ज करें">
                                                            <Option value="Rajasthan">Rajasthan</Option>
                                                            <Option value="Punjab">Punjab</Option>
                                                            <Option value="Hariyana">Hariyana</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>

                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="देश"  >
                                                        <Select onChange={(value) =>
                                                            handleTemporaryAddressChange("tem_country_hn", value)
                                                        }
                                                            value={temporaryAddress.tem_country_hn} placeholder="देश दर्ज करें">
                                                            <Option value="India">India</Option>
                                                            <Option value="USA">USA</Option>
                                                            <Option value="UK">UK</Option>
                                                        </Select>
                                                    </Item>
                                                </Col>

                                            </>
                                        )}

                                       

                                        {selectedAddressSection === 'socialLink' && (
                                            <>
                                              <Col xs={24}><Title level={5}>Social Media Link</Title> </Col>
                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Facebook Profile URL" >
                                                        <Input onChange={(e) =>
                                                            handleSocialMediaChange("facebook_url", e.target.value)
                                                        }
                                                            value={socialMediaLinks.facebook_url} placeholder="Facebook Profile URL" prefix={<FacebookOutlined />} />
                                                    </Item>
                                                </Col>
                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Instagram Profile URL" >
                                                        <Input onChange={(e) =>
                                                            handleSocialMediaChange("instagram_url", e.target.value)
                                                        }
                                                            value={socialMediaLinks.instagram_url} placeholder="Instagram Profile URL" prefix={<InstagramOutlined />} />
                                                    </Item>
                                                </Col>
                                                <Col xs={24} sm={12} lg={4}>
                                                    <Item label="Twitter Profile URL" >
                                                        <Input onChange={(e) =>
                                                            handleSocialMediaChange("twitter_url", e.target.value)
                                                        }
                                                            value={socialMediaLinks.twitter_url} placeholder="Twitter Profile URL" prefix={<TwitterOutlined />} />
                                                    </Item>
                                                </Col>
                                            </>
                                        )}

                                       

                                        {selectedAddressSection === 'uploadFile' && (
                                            <>
                                            <Col xs={24}><Title level={5}>Upload File/document</Title> </Col>

                                                <Col xs={24} sm={12} lg={12}>
                                                    <Upload
                                                        beforeUpload={beforeUpload}
                                                        multiple
                                                        fileList={fileList.map(file => ({
                                                            uid: file.name,
                                                            name: file.name,
                                                            status: 'done',
                                                        }))}
                                                        onRemove={(file) => {
                                                            setFileList(prevState => prevState.filter(item => item.name !== file.name));
                                                        }}
                                                    >
                                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                    </Upload>
                                                    <Image.PreviewGroup
                                                        preview={{
                                                            onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                                        }}
                                                    >
                                                        {multiDocument.map((item, i) => {
                                                            const isPdf = item.endsWith('.pdf');
                                                            if (isPdf) {
                                                                return (
                                                                    <div key={i} style={{ width: 200, height: 200, position: 'relative' }}>
                                                                        <a
                                                                            href={item}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 10 }}
                                                                        >
                                                                            <img
                                                                                src={PdfImage} // Replace with a suitable thumbnail image path
                                                                                alt="PDF thumbnail"
                                                                                style={{ width: '100%', height: '100%' }}
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <Image
                                                                        key={i}
                                                                        width={200}
                                                                        src={item}
                                                                    />
                                                                );
                                                            }
                                                        })}
                                                    </Image.PreviewGroup>
                                                </Col>
                                            </>
                                        )}


                                   
                                    </Row>

                                    <br />

                                    <Row>
                                        <Col xs={24} sm={12} lg={4}>
                                            <Item>
                                                <Button type="primary" htmlType="submit">
                                                    {isUpdateMode ? "Update" : "Save User"}
                                                </Button>
                                            </Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
                {/* {userCheckId && (
            <> */}
                <TabPane tab="Occupation/Bussiness details" key="2">
                    {/* <p>Content for Occupation Details</p> */}
                    <OccupationDetails user_id={id} />
                    {/* Add your form or details here */}
                </TabPane>
                <TabPane tab="Family Details" key="3">
                    {/* <p>Content for Family Details</p> */}
                    <FamilyDetails user_id={id} />
                    {/* Add your form or details here */}
                </TabPane>
                <TabPane tab="Social Status" key="4">
                    {/* <p>Content for Socail Status</p> */}
                    <SocialStatus user_id={id} />
                    {/* Add your form or details here */}
                </TabPane>
                {/* </>
          )} */}
            </Tabs>

            <Modal width={1000}
                title="User Information"
                open={isModalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleModalClose}>
                        OK
                    </Button>,
                ]}
            >
                <div className="scrollable-modal-content">
                    {addedUserData && <UserModalContent userData={addedUserData} />}
                </div>

            </Modal>

        </div >

    );
}

export default UsersForm;