// import React from "react";
// import {
//   Layout,
//   Button,
//   Card,
//   Form,
//   Input,
//   message
// } from "antd";
// import axios from 'axios';
// import logo1 from "../assets/images/logo1.png";
// import { useHistory } from "react-router-dom";
// import { baseUrl } from "../config";


// const { Content } = Layout;

// function SignIn() {
//   const history = useHistory();

//   const onFinish = async (values) => {
//     try {
//       const response = await axios.post(`${baseUrl}/login`, values);
//       console.log("Success:", response.data);
//       if (response.data.status === 'success') {
//         message.success(response.data.message);
//         localStorage.setItem('accessToken', response.data.user.token);
//         localStorage.setItem('permission', JSON.stringify(response.data.user.permission));
//         localStorage.setItem('userType', response.data.user.userType);
//         localStorage.setItem('firstName', response.data.user.firstName);
//         localStorage.setItem('lastName', response.data.user.lastName === undefined ? '' : response.data.user.lastName);
//         localStorage.setItem('userId', response.data.user._id);
//         localStorage.setItem('email', response.data.user.local.email);
//         history.push('/dashboard');
//       } else {
//         message.error(response.data.message);
//       }
//       // Do something with the successful response, like updating state or redirecting the user
//     } catch (error) {
//       console.log("Failed:", error.response.data);
//       message.error(error.response.data.message);
//       // Handle the error, display a message to the user, or perform any other action
//     }
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log("Failed:", errorInfo);
//     // Handle the failed form submission
//   };
//   return (
//     <>
//       <div className="layout-default ant-layout layout-sign-up">


//         <Content className="p-0">
//           <div className="sign-up-header">
//             <div className="content">
//               {/* <Title>LOGIN</Title> */}
//               {/* <p className="text-lg">
//                   Use these awesome forms to login or create new account in your
//                   project for free.
//                 </p> */}
//             </div>
//           </div>

//           <Card
//             className="card-signup header-solid ant-card pt-0"
//             title={<img className="login-image-logo" src={logo1} />}
//             bordered="false"
//           >
//             <Form
//               onFinish={onFinish}
//               onFinishFailed={onFinishFailed}
//               layout="vertical"
//               className="row-col"
//             >

//               <Form.Item
//                 className="username"
//                 label="Email"
//                 name="email"
//                 rules={[
//                   {
//                     required: true,
//                     message: "Please input your email!",
//                   },
//                 ]}
//               >
//                 <Input placeholder="Email" />
//               </Form.Item>
//               <Form.Item
//                 className="username"
//                 label="Password"
//                 name="password"
//                 rules={[
//                   {
//                     required: true,
//                     message: "Please input your password!",
//                   },
//                 ]}
//               >
//                 <Input type="password" placeholder="Password" />
//               </Form.Item>

//               {/* <Form.Item name="remember" valuePropName="checked">
//                   <Checkbox>
//                     I agree the{" "}
//                     <a href="#pablo" className="font-bold text-dark">
//                       Terms and Conditions
//                     </a>
//                   </Checkbox>
//                 </Form.Item> */}

//               <Form.Item>
//                 <Button
//                   style={{ width: "100%" }}
//                   type="primary"
//                   htmlType="submit"
//                 >
//                   LOGIN
//                 </Button>
//               </Form.Item>
//             </Form>
//             {/* <p className="font-semibold text-muted text-center">
//                 Already have an account?{" "}
//                 <Link to="/sign-in" className="font-bold text-dark">
//                   Sign In
//                 </Link>
//               </p> */}
//           </Card>
//         </Content>

//       </div>
//     </>
//   );
// }

// export default SignIn;


// import React, { useState, useEffect, useRef } from "react";
// import { Layout, Button, Card, Form, Input, message, Select } from "antd";
// import axios from "axios";
// import logo1 from "../assets/images/logo1.png";
// import { useHistory } from "react-router-dom";
// import { baseUrl } from "../config";

// const { Content } = Layout;
// const { Option } = Select;

// function SignIn() {
//   const history = useHistory();
//   const inputRefs = useRef([]);
//   const [isOtpSent, setIsOtpSent] = useState(false); // Track if OTP is sent
//   const [loginData, setLoginData] = useState({}); // Store login info
//   const [otpForm] = Form.useForm(); // Form instance for OTP
//   const [mainForm] = Form.useForm(); // Form instance for main login
//   const [remainingTime, setRemainingTime] = useState(120); // Timer state for 2 minutes
//   const [isTimerActive, setIsTimerActive] = useState(false); // Track if timer is running
//   const [otp, setOtp] = useState('');

//   useEffect(() => {
//     if (isOtpSent && remainingTime > 0) {
//       const timer = setInterval(() => {
//         setRemainingTime((prevTime) => prevTime - 1);
//       }, 1000);
//       return () => clearInterval(timer); // Clear timer when component unmounts or time changes
//     } else if (remainingTime === 0) {
//       message.error("OTP expired! Please resend the OTP.");
//       setIsTimerActive(false); // Disable verification after timeout
//     }
//   }, [isOtpSent, remainingTime]);

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const secs = seconds % 60;
//     return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
//   };

//   const sendOtp = async (values) => {
//     try {
//       const response = await axios.post(`${baseUrl}/login/send-otp`, values); // Request OTP
//       if (response.data.status) {
//         message.success(response.data.message);
//         setIsOtpSent(true); // Show OTP form
//         setLoginData(values); // Store the login data to use later for verification
//         setRemainingTime(120); // Reset the timer to 2 minutes
//         setIsTimerActive(true); // Activate the timer
//       } else {
//         message.error(response.data.message);
//       }
//     } catch (error) {
//       console.error("OTP Request Failed:", error.response?.data);
//       message.error(error.response?.data?.message || "OTP request failed");
//     }
//   };

//   const verifyOtp = async (values) => {
//     if (!isTimerActive) {
//       message.error("OTP expired! Please request a new one.");
//       return;
//     }

//     try {
//       const response = await axios.post(`${baseUrl}/login`, {
//         ...loginData, // Pass the login data (email/phone and password)
//         otp: otp, // OTP entered by the user
//       });
//       if (response.data.status === "success") {
//         message.success(response.data.message);
//         localStorage.setItem("accessToken", response.data.user.token);
//         localStorage.setItem("permission", JSON.stringify(response.data.user.permission));
//         localStorage.setItem("userType", response.data.user.userType);
//         localStorage.setItem("firstName", response.data.user.firstName);
//         localStorage.setItem("lastName", response.data.user.lastName || "");
//         localStorage.setItem("userId", response.data.user._id);
//         localStorage.setItem("email", response.data.user.local.email);
//         history.push("/dashboard");
//       } else {
//         message.error(response.data.message);
//       }
//     } catch (error) {
//       console.error("OTP Verification Failed:", error.response?.data);
//       message.error(error.response?.data?.message || "OTP verification failed");
//     }
//   };

//   const handleInputChange = (index, event) => {
//     const value = event.target.value;

//     if (value.length === 1) {
//       // Move to the next input if a single digit is entered
//       if (index < 5) {
//         inputRefs.current[index + 1].focus();
//       }
//     } else if (value.length > 1) {
//       // Handle multi-digit input
//       const digits = value.split('').slice(0, 6); // Only take first 6 digits
//       setOtp(digits.join('')); // Update the OTP state
//       digits.forEach((digit, idx) => {
//         if (inputRefs.current[index + idx]) {
//           inputRefs.current[index + idx].value = digit; // Set value directly
//         }
//       });
//       // Focus the last filled input
//       inputRefs.current[index + digits.length - 1].focus();
//     }

//     // Update the OTP state for any input
//     setOtp(prev => {
//       const newOtp = prev.split('');
//       newOtp[index] = value.charAt(0); // Update the specific index
//       return newOtp.join('');
//     });
//   };

//   const handleKeyDown = (index, event) => {
//     if (event.key === 'Backspace') {
//       if (!event.target.value && index > 0) {
//         // Move to the previous input if backspacing from an empty input
//         inputRefs.current[index - 1].focus();
//       }
//     }
//   };

//   return (
//     <>
//       <div className="layout-default ant-layout layout-sign-up">
//         <Content className="p-0">
//           <div className="sign-up-header">
//             <div className="content"></div>
//           </div>
//           <Card
//             className="card-signup header-solid ant-card pt-0"
//             title={<img className="login-image-logo" src={logo1} />}
//             bordered="false"
//           >
//             {!isOtpSent ? (
//               // Initial Login Form
//               <Form
//                 form={mainForm}
//                 onFinish={sendOtp}
//                 layout="vertical"
//                 className="row-col"
//               >
//                 {/* Select branch field */}
//                 <Form.Item
//                   className="branch"
//                   label="Select Branch"
//                   name="branch"
//                   rules={[{ required: true, message: "Please select your branch!" }]}
//                 >
//                   <Select placeholder="Select Branch">
//                     <Option value="branch1">ABRSVS - SN (Head)</Option>
//                     {/* Add more branches as needed */}
//                   </Select>
//                 </Form.Item>

//                 <Form.Item
//                   className="username"
//                   label="Email or Phone"
//                   name="identifier" // Single field for both email or phone
//                   rules={[
//                     { required: true, message: "Please input your email or phone!" },
//                   ]}
//                 >
//                   <Input placeholder="Email or Phone" />
//                 </Form.Item>
//                 <Form.Item
//                   className="password"
//                   label="Password"
//                   name="password"
//                   rules={[
//                     { required: true, message: "Please input your password!" },
//                   ]}
//                 >
//                   <Input type="password" placeholder="Password" />
//                 </Form.Item>
//                 <Form.Item>
//                   <Button style={{ width: "100%" }} type="primary" htmlType="submit">
//                     Send OTP
//                   </Button>
//                 </Form.Item>
//               </Form>
//             ) : (
//               // OTP Form with Timer
//               <Form
//                 form={otpForm}
//                 onFinish={verifyOtp}
//                 layout="vertical"
//                 className="row-col"
//               >
//                 <p>Time remaining: {formatTime(remainingTime)}</p> {/* Timer display */}
//                 {/* <Form.Item
//                   className="otp"
//                   label="OTP"
//                   name="otp"
//                   rules={[
//                     { required: true, message: "Please input the OTP!" },
//                   ]}
//                 >
//                   <Input placeholder="Enter OTP" />
//                 </Form.Item> */}
//                 <Form.Item label="OTP" name="otp" rules={[{ required: true, message: "Please input the OTP!" }]}>
//         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//           {Array.from({ length: 6 }).map((_, index) => (
//             <Form.Item key={index} style={{ margin: '0' }}>
//               <Input
//                 ref={el => (inputRefs.current[index] = el)}
//                 maxLength={1}
//                 placeholder="0"
//                 onChange={(e) => handleInputChange(index, e)}
//                 onKeyDown={(e) => handleKeyDown(index, e)}
//                 onFocus={(e) => e.target.select()} // Select input text when focused
//               />
//             </Form.Item>
//           ))}
//         </div>
//       </Form.Item>

//                 <Form.Item>
//                   <Button
//                     style={{ width: "100%" }}
//                     type="primary"
//                     htmlType="submit"
//                     disabled={remainingTime === 0} // Disable button when timer reaches 0
//                   >
//                     Verify OTP
//                   </Button>
//                 </Form.Item>
//               </Form>
//             )}
//           </Card>
//         </Content>
//       </div>
//     </>
//   );
// }

// export default SignIn;

import React, { useState, useEffect, useRef } from "react";
import { Layout, Button,Spin, Card, Form, Input, message, Select } from "antd";
import axios from "axios";
import logo1 from "../assets/images/logo1.png";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../config";


const { Content } = Layout;
const { Option } = Select;

function SignIn() {
  const history = useHistory();
  const inputRefs = useRef([]);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [otpForm] = Form.useForm();
  const [mainForm] = Form.useForm();
  const [remainingTime, setRemainingTime] = useState(120);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false); // State to manage the loade

  useEffect(() => {
    if (isOtpSent && remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (remainingTime === 0) {
      message.error("OTP expired! Please resend the OTP.");
      setIsTimerActive(false);
    }
  }, [isOtpSent, remainingTime]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const sendOtp = async (values) => {
    setLoading(true); // Start loader
    try {
      const response = await axios.post(`${baseUrl}/login/send-otp`, values);
      if (response.data.status) {
        setLoading(false); // Start loader
        message.success(response.data.message);
        setIsOtpSent(true);
        setLoginData(values);
        setRemainingTime(120);
        setIsTimerActive(true);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("OTP Request Failed:", error.response?.data);
      message.error(error.response?.data?.message || "OTP request failed");
    }
  };

  const verifyOtp = async (values) => {
    if (!isTimerActive) {
      message.error("OTP expired! Please request a new one.");
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/login`, {
        ...loginData,
        otp: otp,
      });
      if (response.data.status === "success") {
        message.success(response.data.message);
        // Store user data in local storage
        localStorage.setItem("accessToken", response.data.user.token);
        localStorage.setItem("permission", JSON.stringify(response.data.user.permission));
        localStorage.setItem("userType", response.data.user.userType);
        localStorage.setItem("firstName", response.data.user.firstName);
        localStorage.setItem("lastName", response.data.user.lastName || "");
        localStorage.setItem("userId", response.data.user._id);
        localStorage.setItem("email", response.data.user.local.email);
        history.push("/dashboard");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("OTP Verification Failed:", error.response?.data);
      message.error(error.response?.data?.message || "OTP verification failed");
    }
  };

  const handleResendOtp = async () => {
    if (isTimerActive) {
      message.warning("Please wait for the current OTP to expire before resending.");
      return;
    }
    await sendOtp(loginData);
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    if (value.length === 1) {
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length > 1) {
      const digits = value.split('').slice(0, 6);
      setOtp(digits.join(''));
      digits.forEach((digit, idx) => {
        if (inputRefs.current[index + idx]) {
          inputRefs.current[index + idx].value = digit;
        }
      });
      inputRefs.current[index + digits.length - 1].focus();
    }

    setOtp(prev => {
      const newOtp = prev.split('');
      newOtp[index] = value.charAt(0);
      return newOtp.join('');
    });
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      if (!event.target.value && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <>
     {loading && (
        <div className="full-page-loader">
          <Spin size="large" />
        </div>
      )}

      <div className="layout-default ant-layout layout-sign-up">
        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content"></div>
          </div>
          <Card
            className="card-signup header-solid ant-card pt-0"
            title={<img className="login-image-logo" src={logo1} />}
            bordered="false"
          >
            {!isOtpSent ? (
              <Form
                form={mainForm}
                onFinish={sendOtp}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="branch"
                  label="Select Branch"
                  name="branch"
                  rules={[{ required: true, message: "Please select your branch!" }]}
                >
                  <Select placeholder="Select Branch">
                    <Option value="branch1">ABRSVS - SN (Head)</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Email or Phone"
                  name="identifier"
                  rules={[{ required: true, message: "Please input your email or phone!" }]}
                >
                  <Input placeholder="Email or Phone" />
                </Form.Item>
                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Please input your password!" }]}
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item>
                  <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                    Send OTP
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form
                form={otpForm}
                onFinish={verifyOtp}
                layout="vertical"
                className="row-col"
              >
                <p>Time remaining: {formatTime(remainingTime)}</p>
                <Form.Item label="OTP" name="otp" rules={[{ required: true, message: "Please input the OTP!" }]}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <Form.Item key={index} style={{ margin: '0', marginLeft: 4 }}>
                        <Input
                          ref={el => (inputRefs.current[index] = el)}
                          maxLength={1}
                          style={{textAlign: "center"}}
                          placeholder=""
                          onChange={(e) => handleInputChange(index, e)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          onFocus={(e) => e.target.select()}
                        />
                      </Form.Item>
                    ))}
                  </div>
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                    disabled={remainingTime === 0}
                  >
                    Verify OTP
                  </Button>
                </Form.Item>
                {remainingTime === 0 && (
                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      type="default"
                      onClick={handleResendOtp}
                    >
                      Resend OTP
                    </Button>
                  </Form.Item>
                )}
              </Form>
            )}
          </Card>
        </Content>
      </div>
    </>
  );
}

export default SignIn;

