import React, { useState } from 'react';
import { Modal, Button, Upload, Input, notification, Row, Col,Spin } from 'antd';
import { FileExcelOutlined, UploadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import { baseUrl } from '../../../config';
import axios from 'axios'; 


const ImportListModal = ({ isVisible, onClose, onSuccess }) => {

    const [file, setFile] = useState(null);  
    const [loading, setLoading] = useState(false); // State to manage the loade

    const SampleFileDownload = () => {
        // This is the sample Excel file download function
        const handleSampleDownload = () => {
            const sampleFileUrl = `${baseUrl}/resources/sample/Village_import.xlsx`; // Path to your sample file
            saveAs(sampleFileUrl, 'village-sample-template.xlsx');
        };

        return (
            <a onClick={handleSampleDownload} style={{ marginBottom: '20px', display: 'block' }}>
                Download Sample Template
            </a>
        );
    };

    // const handleFileChange = (e) => {
    //     setFile(e.target.files[0]);
    // };

    const handleFileChange = (info) => {
        setFile(info.file);
    };

    const triggerUpload = async () => {

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                setLoading(true);

                const response = await axios.post(`${baseUrl}/village/import-data`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.status === 200) {
                    setLoading(false);
                    notification.success({
                        message: 'Success',
                        description: 'Record updated successfully!',
                        placement: 'topRight'
                    });
                    onSuccess()
                    // history.push('/purnima-form');
                } else {
                    setLoading(false);
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
                console.log(response.data);
            } catch (error) {
                notification.info({
                    message: 'Info',
                    description: error.message,
                    placement: 'topRight'
                });
                console.error('Error uploading file:', error);
            }
        } else {
            alert('Please select a file before uploading.');
        }
    };

    return (
        <>
        {loading && (
            <div className="full-page-loader">
              <Spin size="large" />
            </div>
          )}
        <Modal
            open={isVisible}
            footer={null}
            onCancel={onClose}
        >
            {/* Sample download link */}
            <SampleFileDownload />
            {/* File Upload Field */}
            <Row gutter={16}>
                <Col span={12}>
                    <Upload
                        beforeUpload={() => false} // Prevent auto-upload
                        showUploadList={false}
                        onChange={handleFileChange}
                    >
                        <Input
                            placeholder="Select Excel File"
                            value={file ? file.name : ''}
                            readOnly
                        />
                    </Upload>
                </Col>
                <Col span={12}>
                    {/* Upload Button */}
                    <Button
                        icon={<UploadOutlined />}
                        onClick={triggerUpload}
                    >
                        Upload Excel File
                    </Button>
                </Col>
            </Row>

        </Modal>
        </>
    );
};

export default ImportListModal;

