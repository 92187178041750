import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Layout, Form, Input, Button, message, Divider, Row, Col, Card, DatePicker, TimePicker, Select, Typography, notification, Table, Tooltip } from 'antd';
import axios from 'axios';
import { UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined, FieldTimeOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { baseUrl, secretKey } from '../config';
import moment from 'moment';
import CryptoJS from 'crypto-js';



const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;


// Table columns definition
const columns = [

  {
    title: "#",
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },

  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },

  {
    title: "Time Out",
    dataIndex: "visit_time_out",
    key: "visit_time_out",
  },

];



const { Header, Content } = Layout;

const FullMoonForm = () => {
  const [form] = Form.useForm();
  const history = useHistory();



  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPageQuery = parseInt(queryParams.get('page')) || 1;
  const limitQuery = parseInt(queryParams.get('limit')) || 20;

  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(limitQuery);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    name: queryParams.get('name') || '',
    email: queryParams.get('email') || '',
    phone: queryParams.get('phone') || '',
    visit_branch: queryParams.get('visit_branch') || '',
    purpose_visit: queryParams.get('purpose_visit') || '',
    visit_date: queryParams.get('visit_date') || '',
    createdAt: queryParams.get('createdAt') || '',
  });


  const [txnDate, setTxnDate] = useState(moment());
  const [timeInDate, setTimeInDate] = useState(moment());
  const [timeOutDate, setTimeOutDate] = useState(null);




  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setTxnDate(formattedDate)
    } else {
      setTxnDate(null);
    }
  };

  useEffect(() => {
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }, [history.location]);



  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/visitor/newVisitorList?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const handleLimitChange = value => {
    setLimit(value);

    // Update URL with the new limit and reset page to 1
    const updatedQueryParams = new URLSearchParams(location.search);
    updatedQueryParams.set('limit', value);
    updatedQueryParams.set('page', 1); // Reset to first page whenever limit changes

    // Push new URL with updated query params
    history.push(`?${updatedQueryParams.toString()}`);

    // Call the API to fetch data with updated limit
    getUserList(1, value);  // Assuming your API call accepts page and limit
  };



  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);

    // Update both current page and limit in the history
    history.push(`?page=${current}&limit=${pageSize}`);

    // Fetch the updated payment list
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };

  const addVisitor = () => {
    history.push(`/new-visitors`)
  }


  const handleTimeout = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/visitor/updateTimeout`, { id }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      });
      if (response.data.status) {
        message.success("Timeout updated successfully!");
        getUserList(); // Refresh the list
      } else {
        message.error(response.data.message || "Failed to update timeout");
      }
    } catch (error) {
      message.error("An error occurred while updating timeout");
    }
  };



  return (
    <Layout style={{

      backgroundSize: 'cover',
      minHeight: '100vh'
    }}>

      <Header style={{ backgroundColor: 'rgba(251, 189, 70, 0.78)', textAlign: 'center', padding: '20px 10', height: "auto" }}>
        <h1 style={{ color: 'red', fontSize: '28px', fontWeight: 'bold', marginBottom: '10px' }}>
          जय गुरु महाराज री सा
        </h1>

        <h2 style={{ color: '#fff', fontWeight: 'bold', fontSize: '16px', lineHeight: '1.5' }}>
          अखिल भारतीय राजपुरोहित समाज विकास संस्थान <br /> श्री खेतेश्वर ब्रह्मधाम तीर्थ, आसोतरा
        </h2>
      </Header>

      <Divider />

      {/* Content with improved styling and responsive form */}
      <Content style={{ padding: '10px', minHeight: '80vh' }}>
        <Row justify="center">
        <Col xs={24} sm={24} md={20} lg={16} xl={12}>
            <Card
              title="Today Visitor"
              bordered={false}
              style={{
                background: 'rgba(255, 255, 255, 0.8)', // Transparent background
                borderRadius: '10px',
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)', 
                
              }}
              headStyle={{
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: 'bold',
                color: '#333',
              }}

              extra={
                <div style={{ display: 'flex', gap: '10px' }}>

                  <Button type="primary" onClick={addVisitor}  >Add Vistor</Button>

                </div>
              }
            >


              <div style={{ overflowX: 'auto', margin: '0 -10px' }}>
                <Table
                  columns={columns}
                  pagination={false}
                  scroll={{ x: 'max-content' }}
                  bordered
                  title={() => (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Row gutter={[16, 16]}>



                        <Col xs={24}>
                          <span style={{ marginRight: 10 }}>Records per page:</span>
                          <Select defaultValue={20} onChange={handleLimitChange}>
                            <Option value={20}>20</Option>
                            <Option value={40}>40</Option>
                            <Option value={60}>60</Option>
                            <Option value={80}>80</Option>
                            <Option value={100}>100</Option>
                          </Select>
                        </Col>
                      </Row>
                    </div>
                  )}


                  dataSource={userList.map((user, index) => {

                    return {
                      key: (limit * (currentPage - 1)) + index + 1,
                      name: (
                        <>
                          <div className="author-info">
                            <Title level={5}><UserOutlined /> {user.name}   </Title>
                            <p><PhoneOutlined /> {user.phone_no}</p>
                            <p><CalendarOutlined /> {user.visit_date && user.visit_date != "0000-00-00" ? moment(user.visit_date).format('DD MMM YYYY') : '-'}</p>
                            <p><FieldTimeOutlined /> {user.visit_time_in && user.visit_time_in !== "00:00:00"
                              ? moment(user.visit_time_in, "HH:mm:ss").format("h:mm A") // Format as 12-hour time with AM/PM
                              : "-"}</p>
                          </div>
                        </>
                      ),
                      visit_time_out: (
                        <>
                          <div className="author-info">
                            {user.visit_time_out && user.visit_time_out !== "00:00:00" ? (
                              <p>{moment(user.visit_time_out, "HH:mm:ss").format("h:mm A")}</p>
                            ) : (
                              <Button
                                type="primary"
                                icon={<ClockCircleOutlined />}
                                onClick={() => handleTimeout(user.id)}
                              >
                                 Timeout
                              </Button>
                            )}
                          </div>
                        </>
                      ),


                    };
                  })}
                  pagination={{
                    ...pagination,
                    showTotal: total => `Total ${total} records`,
                    style: { marginRight: 20 }
                  }}
                  onChange={handleTableChange}
                  className="ant-border-space"
                />
              </div>

            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default FullMoonForm;
