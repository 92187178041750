import React, { useState, useEffect } from "react";
import { Result, Button, Spin, Table } from "antd";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { baseUrl, secretKey } from "../config";
import CryptoJS from 'crypto-js';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ThankYouPage = () => {
    const query = useQuery();
    const id = query.get('id');
    const navigate = useHistory();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch user details by id from API
        const fetchUserData = async () => {
 
            if (!id) {
                setError("No ID provided.");
                setLoading(false);
                return;
            }

 
            try {
                // Decode the ID if it's URL-encoded
                const decodedId = decodeURIComponent(id);
                // Decrypt the ID
                const bytes = CryptoJS.AES.decrypt(decodedId, secretKey);
                const decryptedId = bytes.toString(CryptoJS.enc.Utf8);
 
                 if (!decryptedId) {
                     setError("Invalid encrypted ID.");
                     setLoading(false);
                     return;
                 }
 
                if (!decryptedId) {
                    setError("Invalid encrypted ID.");
                    setLoading(false);
                    return;
                }
 
                const response = await axios.get(`${baseUrl}/purnima_form_web/read/${decryptedId}`);
                setUserData(response.data.result); // Assuming response.data contains user details
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch user details.");
                setLoading(false);
            }
        };

        fetchUserData();
    }, [id]);

    const handleBackHome = () => {
 
        navigate.push("/");
 
        navigate.push("/purnima-member");
 
    };

    if (loading) {
        return <Spin tip="Loading user details..." style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />;
    }

    if (error) {
        return (
            <Result
                status="error"
                title="Something went wrong"
                subTitle={error}
                extra={[
                    <Button type="primary" key="home" onClick={handleBackHome}>
                        Back to Home
                    </Button>,
                ]}
            />
        );
    }

    // Define the columns for the Ant Design table
    const columns = [
        {
            title: "Field",
            dataIndex: "field",
            key: "field",
            responsive: ["md"], // Show only on medium to large screens
            width: 150,
        },
        {
            title: "Details",
            dataIndex: "value",
            key: "value",
            render: (text) => <strong>{text}</strong>,
        },
    ];

    // Define the data for the table from the userData
    const data = [
        { key: "1", field: "Name", value: userData?.name },
        { key: "2", field: "Phone No", value: userData?.phone_no },
        { key: "3", field: "Father's Name", value: userData?.father_name },
        { key: "4", field: "Village", value: userData?.village },
        { key: "5", field: "Tehsil", value: userData?.tehsil },
        { key: "6", field: "District", value: userData?.district },
        { key: "7", field: "Occupation", value: userData?.occupation },
        { key: "8", field: "Occupation District", value: userData?.occ_district },
        { key: "9", field: "Inspiration Remark", value: userData?.inspiration_remark },
    ];

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                flexDirection: "column",
            }}
        >
            <Result
                status="success"
                title="जय ब्रह्माजी री सा। आपका पूर्णिमा सूचना फ़ॉर्म सफलतापूर्वक भर दिया गया है।"
                subTitle="Your submission details are shown below:"
            />
 
            <div style={{ width: "100%", maxWidth: 600, textAlign: "center" }}>
 
            <Table
                columns={columns}
                dataSource={data}
                pagination={false} // Disable pagination for this table
                bordered
                style={{ width: "100%", maxWidth: 600 }} // Center the table and limit its width
                rowKey="key"
            />
 

            <Button
                type="primary"
                size="large"  // Makes the button bigger 
                block
                onClick={handleBackHome}
                style={{ marginTop: 20 }}  // Adds space between the table and button
            >
                Back
            </Button>
            </div>
 
        </div>
    );
};

export default ThankYouPage;
