import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Typography,
  notification,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Space
} from "antd";
import axios from "axios";
import { UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

function StaffForm({ userId }) {


  // const { id } = useParams(); // Extract id from URL
  const history = useHistory();
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [txnDate, setTxnDate] = useState();
  const [staffList, setStaffList] = useState([]);
  const [meetingDate, setMeetingDate] = useState();
  const [startDate,setStartDate] = useState();
  const [status, setStatus] = useState('Pending');


  useEffect(() => {

    getStaffList();
    // Check if id exists to determine if it's an update mode
    if (userId) {
      setIsUpdateMode(true);
      fetchRoleDetails();
    }
  }, [userId]);



  const fetchRoleDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/inquiry/read/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        // setRoleData(response.data.result);
        form.setFieldsValue(response.data.result); // Populate form fields with fetched data
        setTxnDate(response.data.result.inquiry_date ? response.data.result.inquiry_date : null) // Format the date if present)
        setMeetingDate(response.data.result.resolved_date ? response.data.result.resolved_date : null) // Format the date if present)
        setStartDate(response.data.result.start_date ? response.data.result.start_date : null) // Format the date if present)
        setStatus(response.data.result.status); // Populate status from fetched data

      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const onFinish = async (values) => {

    try {
      if (txnDate) {
        const formattedDate = moment(txnDate).format('YYYY-MM-DD');
        values.inquiry_date = formattedDate;
      } else {
        values.inquiry_date = '';
      }

      if (meetingDate) {
        const formattedDate = moment(meetingDate).format('YYYY-MM-DD');
        values.resolved_date = formattedDate;
      } else {
        values.resolved_date = '';
      }

      if (startDate) {
        const formattedDate = moment(startDate).format('YYYY-MM-DD');
        values.start_date = formattedDate;
      } else {
        values.start_date = '';
      }

      

      if (isUpdateMode) {
        const response = await axios.patch(`${baseUrl}/inquiry/update/${userId}`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Record updated successfully!',
            placement: 'topRight'
          });
          history.push('/inquiry');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      } else {
        const response = await axios.post(`${baseUrl}/inquiry/create`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Inquiry added successfully!',
            placement: 'topRight'
          });
          form.resetFields();
          history.push('/inquiry');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };



  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setTxnDate(formattedDate)
    } else {
      setTxnDate(null);
    }
  };

  const handleMeetingDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setMeetingDate(formattedDate)
    } else {
      setMeetingDate(null);
    }
  };

  const handleStartDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setStartDate(formattedDate)
    } else {
      setStartDate(null);
    }
  };

  async function getStaffList() {
    try {
      const response = await axios.get(`${baseUrl}/staff/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setStaffList(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRighft'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

    // Handle status change
    const handleStatusChange = (value) => {
      setStatus(value);
    };



  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={isUpdateMode ? "Update Call/Message Inquiry" : "Add Call/Message Inquiry"}
          >
              <Form 
                     initialValues={{ status: "Pending" }}
                    style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                      <Row gutter={[16, 16]}>


                        <Col xs={24} sm={4} lg={4}>
                          <Item
                            label="Phone No"
                            name="phone_no"
                            rules={[{ required: true, message: 'Please Enter  Phone no' },
                            {
                              pattern: /^\d{10,12}$/,
                              message: 'Phone no must be between 10 and 12 digits!',
                            }
                            ]}
                          >

                            <Input type="number" placeholder="Enter Phone no" />
                          </Item>
                        </Col>


                        <Col xs={24} sm={4} lg={4}>
                          <Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please Enter Name' }]}
                          >
                            <Input placeholder="Enter Name" />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item
                            label="Inquiry Regards"
                            name="inquiry_regards"
                          >
                            <Input placeholder="Enter Inquiry Regards" />
                          </Item>
                        </Col>

                        <Col xs={24} sm={4} lg={4}>
                          <Item label="Inquiry Date" >
                            <DatePicker onChange={handleDateChange} value={txnDate && txnDate != "0000-00-00" && moment(txnDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select Inquiry Date" prefix={<CalendarOutlined />} />

                          </Item>
                        </Col>

                        <Col xs={24} sm={6} lg={4}>
                        <Item className="custom-form-item" label="Assigned By" name="attend_by">
                          <Select   style={{ width: '100%' }} placeholder="Select Staff"  >
                            <Option value="">Select Staff</Option>
                            {staffList && staffList.map((item, i) => (
                              <Option value={item.id}>{item.firstName} {item.lastName}</Option>
                            ))}
                          </Select>
                          </Item> 

                        </Col>

                        <Col xs={24} sm={6} lg={4}>
                        <Item className="custom-form-item" label="Received Call No." name="received_call">
                          <Select   style={{ width: '100%' }} placeholder="Received Call No."  >
                            <Option value="">Received Call No.</Option>
                            <Option value="9001000071">9001000071</Option>
                                <Option value="7023131008">7023131008</Option>
                                <Option value="9030301008">9030301008</Option>
                                <Option value="9030191008">9030191008</Option>
                                <Option value="9030131008">9030131008</Option>
                                <Option value="9492861008">9492861008</Option>
                                <Option value="9492871008">9492871008</Option>
                                <Option value="9492881008">9492881008</Option>
                                <Option value="9951513144">9951513144</Option>
                                <Option value="9951516145">9951516145</Option>
                                <Option value="9951516155">9951516155</Option>
                          </Select>
                          </Item> 

                        </Col>


                        <Col xs={24} sm={6} lg={4}>

                        <Item className="custom-form-item" label="Assigned To" name="assigned_to">
                          <Select   style={{ width: '100%' }} placeholder="Select Staff"  >
                            <Option value="">Select Staff</Option>
                            {staffList && staffList.map((item, i) => (
                              <Option value={item.id}>{item.firstName} {item.lastName}</Option>
                            ))}
                          </Select>
                          </Item>

                        </Col>

                        <Col xs={24} sm={12} lg={4}>
                          <Item
                            label="Resolved Remark"
                            name="resolved_remark"
                          >
                            <Input placeholder="Enter Resolved Remark" />
                          </Item>
                        </Col>

                        <Col xs={24} sm={4} lg={4}>
                          <Item label="Resolved Date" >
                            <DatePicker onChange={handleMeetingDateChange} value={meetingDate && meetingDate != "0000-00-00" && moment(meetingDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select Resolved Date" prefix={<CalendarOutlined />} />

                          </Item>
                        </Col>

                        <Col xs={24} sm={12} lg={4}>  

                          <Item className="custom-form-item" label="Status" name="status"
                            rules={[{ required: true, message: 'Select Status' }]}
                          >
                            <Select  value={status} onChange={handleStatusChange}  className="status" placeholder="Select Account" defaultValue="Pending" >
                             <Option value="Pending">Pending</Option>
                              <Option value="InProcess">InProcess</Option>
                              <Option value="Completed">Completed</Option>
                            </Select>
                          </Item> 
                        </Col>

                        {status === 'InProcess' && (
                        <Col xs={24} sm={4} lg={4}>
                          <Item label="Work Start Date" >
                            <DatePicker onChange={handleStartDateChange} value={startDate && startDate != "0000-00-00" && moment(startDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select Start Date" prefix={<CalendarOutlined />} />

                          </Item>
                        </Col>
                         )}
 

                      </Row>
                      <Row style={{ textAlign: 'right' }}>
                        <Col xs={24} sm={24} lg={24}>
                          <Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{ marginRight: '10px' }} // Add space between buttons
                            >
                              {isUpdateMode ? "Update" : "Save"}
                            </Button>


                          </Item>
                        </Col>
                      </Row>

                    </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default StaffForm;
