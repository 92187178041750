import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  Row, Col, Card, Table, Button, Input, AutoComplete,
  notification, Modal, Select, Avatar, Typography, TableColumnsType,
  Checkbox, DatePicker, Drawer, Form, InputNumber, Space, Tooltip
} from "antd";


import axios from "axios";
import { baseUrl } from "../../config";
import {
  DeleteOutlined, FileExcelOutlined, CalendarOutlined, SendOutlined,
  FilterOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined,
  EyeOutlined, UserOutlined
} from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';
import ImportListModal from './components/importListModal';
import AddUserDetailsModel from './Form';


const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;
const { Item } = Form;
const { TextArea } = Input;


// Table columns definition
const columns = [

  {
    title: "#",
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },
  {
    title: "गाँव",
    dataIndex: "name_hn",
    key: "name_hn",
    fixed: 'left',
    width: 150,
  },
  {
    title: "पोस्ट",
    dataIndex: "post_hn",
    key: "post_hn",
  },
  {
    title: "तहसील",
    dataIndex: "tehsil_hn",
    key: "tehsil_hn",
  },
  {
    title: 'पिनकोड',
    dataIndex: 'pincode_hn',
    key: 'pincode_hn',
  },
  {
    title: "जिला",
    dataIndex: "district_hn",
    key: "district_hn",
  },
  {
    title: "राज्य",
    dataIndex: "state_hn",
    key: "state_hn"
  },



  {
    title: "Post",
    dataIndex: "post_en",
    key: "post_en",
  },
  {
    title: "Tehsil",
    dataIndex: "tehsil_en",
    key: "tehsil_en",
  },

  {
    title: "Pincode",
    dataIndex: "pincode_en",
    key: "pincode_en"
  },

  {
    title: "District",
    dataIndex: "district_en",
    key: "district_en",
  },
  {
    title: "State",
    key: "state_en",
    dataIndex: "state_en",
  },
  {
    title: "History/Remark",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "createdAt",
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    fixed: 'right',
  }
];

function SuspenseEntryList() {

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPageQuery = parseInt(queryParams.get('page')) || 1;
  const limitQuery = parseInt(queryParams.get('limit')) || 20;

  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(limitQuery);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [filters, setFilters] = useState({
    name_hn: queryParams.get('name_hn') || '',
    post_hn: queryParams.get('post_hn') || '',
    tehsil_hn: queryParams.get('tehsil_hn') || '',
    pincode_hn: queryParams.get('pincode_hn') || '',
    district_hn: queryParams.get('district_hn') || '',
    state_hn: queryParams.get('state_hn') || '',

    // English Fields
    name_en: queryParams.get('name_en') || '',
    post_en: queryParams.get('post_en') || '',
    tehsil_en: queryParams.get('tehsil_en') || '',
    pincode_en: queryParams.get('pincode_en') || '',
    district_en: queryParams.get('district_en') || '',
    state_en: queryParams.get('state_en') || '',

  });

  const [showUserFilter, setShowUserFilter] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const { id } = useParams(); // Extract id from URL 
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [txnDate, setTxnDate] = useState();
  const [addForm, setAddForm] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
  const [userId, setuserId] = useState();
  const [isModalVisibleImportList, setIsModalVisibleImportList] = useState(false);

  useEffect(() => {
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }, [history.location]);


  const AddUserDetailsClose = () => {
    setAddUserDetailsModel(false)
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }



  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/village/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  async function handleDelete(id) {
    try {
      const response = await axios.delete(`${baseUrl}/village/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 200) {
        getUserList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
        notification.success({
          message: 'Success',
          description: 'Record deleted successfully!',
          placement: 'topRight'
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);

    // Update both current page and limit in the history
    history.push(`?page=${current}&limit=${pageSize}`);

    // Fetch the updated payment list
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };



  const handleLimitChange = value => {
    setLimit(value);

    // Update URL with the new limit and reset page to 1
    const updatedQueryParams = new URLSearchParams(location.search);
    updatedQueryParams.set('limit', value);
    updatedQueryParams.set('page', 1); // Reset to first page whenever limit changes

    // Push new URL with updated query params
    history.push(`?${updatedQueryParams.toString()}`);

    // Call the API to fetch data with updated limit
    getUserList(1, value);  // Assuming your API call accepts page and limit
  };




  const handleFilterChange = (field, value) => {
    const newFilters = {
      ...filters,
      [field]: value
    };
    setFilters(newFilters);

    // Optionally, update the URL without triggering a search immediately
    const updatedQueryParams = new URLSearchParams(location.search);
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key]) {
        updatedQueryParams.set(key, newFilters[key]);
      } else {
        updatedQueryParams.delete(key); // Remove empty filters from the URL
      }
    });
    history.push(`?${updatedQueryParams.toString()}`);
  };


  const handleUserFilterCheckboxChange = (e) => {
    setShowUserFilter(e.target.checked);

  };



  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    try {
      if (txnDate) {
        const formattedDate = moment(txnDate).format('YYYY-MM-DD');
        values.dob = formattedDate;
      } else {
        values.dob = '';
      }
      values.action = sendMessage ? 'save_send' : 'save';
      if (isUpdateMode) {
        const response = await axios.patch(`${baseUrl}/village/update/${id}`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Record updated successfully!',
            placement: 'topRight'
          });
          history.push('/suspense-entries');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      } else {
        const response = await axios.post(`${baseUrl}/village/create`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Village added successfully!',
            placement: 'topRight'
          });
          form.resetFields();
          setTxnDate('');

          getUserList();

          // history.push('/village');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };



  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setTxnDate(formattedDate)
    } else {
      setTxnDate(null);
    }
  };

  const toggleFormVisibility = () => {
    setAddForm(prevState => !prevState); // Toggle between true and false
  };


  const handleResendMessage = async (userId) => {
    Modal.confirm({
      title: 'Are you sure you want to Re-send message?',
      onOk: async () => {
        try {
          const response = await axios.patch(`${baseUrl}/village/messageresend/${userId}`, { send: 'yes' }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            },
          });

          if (response.status === 200) {

            const { state } = history.location; // Get the state from history
            const restoredPage = state?.page || currentPage; // Restore page from state or use current page
            const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

            // Call getUserList with restored page and limit
            getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);

            notification.success({
              message: 'Success',
              description: 'Message sent successfully',
              placement: 'topRight',

            });
          }
        } catch (error) {
          notification.info({
            message: 'Info',
            description: "Failed to verify payment",
            placement: 'topRight'
          });

        }
      },
    });
  }

  const exportToExcel = async () => {
    const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const filterParams = Object.keys(filters)
      .map(key => `${key}=${filters[key]}`)
      .join('&');
    const response = await axios.get(`${baseUrl}/village/list?page=${1}&limit=all${sortParam}${searchParam}&${filterParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
    if (response.data.status) {
      const formattedData = response.data.result.map((user, index) => ({
        "Sr.No": index + 1,
        "Village Name Eng  ": user.name_en || '-',
        "Village Name Hn": user.name_hn || '-',
        "Post Eng ": user.post_en || '-',
        "Post Hn ": user.post_hn || '-',
        "Tehsil Eng ": user.tehsil_en || '-',
        "Tehsil Hn ": user.tehsil_hn || '-',
        "Pincode Eng ": user.pincode_en || '-',
        "Pincode Hn  ": user.pincode_hn || '-',
        "District Eng ": user.district_en || '-',
        "District Hn ": user.district_hn || '-',
        "State Eng ": user.state_en || '-',
        "State Hn ": user.state_hn || '-',
        "Date": user.createdAt ? moment(user.createdAt).format('DD-MM-YYYY') : '-',

      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Village-list");
      XLSX.writeFile(workbook, "Village-List.xlsx");
    } else {
      notification.info({
        message: 'Info',
        description: response.data.message,
        placement: 'topRight'
      });
    }
  };


  const AddUserDetails = async (userId) => {
    console.log('uid', userId)
    setuserId(userId);
    setAddUserDetailsModel(true)

  }

  const handleCancelImportList = () => {
    setIsModalVisibleImportList(false);
  };

  const handleOnSuccess = () => {
    setIsModalVisibleImportList(false);
    getUserList();
  };



  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>



        {addForm && (
          <Col xs="24" xl={24}>
            <div className="tabled">
              <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                  <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title={isUpdateMode ? "Update Village Form" : "Add Village Form"}
                  >
                    <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                      <Row gutter={[16, 16]}>



                        {/* Hindi Fields */}
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="गाँव नाम " name="name_hn" rules={[{ required: true, message: 'Please Enter Name' }]}>
                            <Input placeholder="नाम दर्ज करें " />
                          </Item>
                        </Col>

                        <Col xs={24} sm={6} lg={4}>
                          <Item label="पोस्ट " name="post_hn">
                            <Input placeholder="पोस्ट दर्ज करें " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="तहसील " name="tehsil_hn">
                            <Input placeholder="तहसील दर्ज करें " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="पिनकोड " name="pincode_hn">
                            <Input placeholder="पिनकोड दर्ज करें " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="जिला " name="district_hn">
                            <Input placeholder="जिला दर्ज करें " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="राज्य " name="state_hn">
                            <Input placeholder="राज्य दर्ज करें " />
                          </Item>
                        </Col>

                        {/* English Fields */}
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="Village Name " name="name_en">
                            <Input placeholder="Enter Name " />
                          </Item>
                        </Col>

                        <Col xs={24} sm={6} lg={4}>
                          <Item label="Post " name="post_en">
                            <Input placeholder="Enter Post " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="Tehsil " name="tehsil_en">
                            <Input placeholder="Enter Tehsil " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="Pincode " name="pincode_en">
                            <Input placeholder="Enter Pincode " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="District " name="district_en">
                            <Input placeholder="Enter District " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="State " name="state_en">
                            <Input placeholder="Enter State " />
                          </Item>
                        </Col>

                        <Col xs={24} sm={6} lg={8}>
                          <Item label="History/Remark " name="remark">
                            <Input placeholder="Enter History/Remark " />
                          </Item>
                        </Col>


                      </Row>
                      <Row style={{ textAlign: 'right' }}>
                        <Col xs={24} sm={24} lg={24}>
                          <Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() => setSendMessage(false)}
                              style={{ marginRight: '10px' }} // Add space between buttons
                            >
                              {isUpdateMode ? "Update" : "Save"}
                            </Button>


                          </Item>
                        </Col>
                      </Row>

                    </Form>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>

        )}





        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="New Village Form List"
            extra={
              <div style={{ display: 'flex', gap: '10px' }}>

                <Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={() => setIsModalVisibleImportList(true)}
                  style={{
                    padding: '4px 15px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    borderRadius: '5px',
                  }}
                >
                  Import List
                </Button>

                {hasPermission('createPermission', 'Create User') && (

                  <button onClick={toggleFormVisibility} className="custom-btn">Add Village Form</button>


                )}
                <Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={exportToExcel}
                  style={{
                    padding: '4px 15px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    borderRadius: '5px',
                  }}
                >
                  Excel Download
                </Button>
              </div>
            }

          >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <FilterOutlined /> <Checkbox onChange={handleUserFilterCheckboxChange}><Title style={{ fontSize: 13 }} level={5}>Form Filter</Title></Checkbox>
                      </Col>
                      {showUserFilter && (
                        <>
                          {/* Hindi Fields */}
                          <Col span={4}>
                            <Input
                              placeholder="गाँव नाम (Hindi)"
                              value={filters.name_hn}
                              onChange={e => handleFilterChange('name_hn', e.target.value)}
                            />
                          </Col>

                          <Col span={4}>
                            <Input
                              placeholder="पोस्ट (Hindi)"
                              value={filters.post_hn}
                              onChange={e => handleFilterChange('post_hn', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input
                              placeholder="तहसील (Hindi)"
                              value={filters.tehsil_hn}
                              onChange={e => handleFilterChange('tehsil_hn', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input
                              placeholder="पिनकोड (Hindi)"
                              value={filters.pincode_hn}
                              onChange={e => handleFilterChange('pincode_hn', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input
                              placeholder="जिला (Hindi)"
                              value={filters.district_hn}
                              onChange={e => handleFilterChange('district_hn', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input
                              placeholder="राज्य (Hindi)"
                              value={filters.state_hn}
                              onChange={e => handleFilterChange('state_hn', e.target.value)}
                            />
                          </Col>

                          {/* English Fields */}
                          <Col span={4}>
                            <Input
                              placeholder="Village Name (English)"
                              value={filters.name_en}
                              onChange={e => handleFilterChange('name_en', e.target.value)}
                            />
                          </Col>

                          <Col span={4}>
                            <Input
                              placeholder="Post (English)"
                              value={filters.post_en}
                              onChange={e => handleFilterChange('post_en', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input
                              placeholder="Tehsil (English)"
                              value={filters.tehsil_en}
                              onChange={e => handleFilterChange('tehsil_en', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input
                              placeholder="Pincode (English)"
                              value={filters.pincode_en}
                              onChange={e => handleFilterChange('pincode_en', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input
                              placeholder="District (English)"
                              value={filters.district_en}
                              onChange={e => handleFilterChange('district_en', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input
                              placeholder="State (English)"
                              value={filters.state_en}
                              onChange={e => handleFilterChange('state_en', e.target.value)}
                            />
                          </Col>



                        </>
                      )}

                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select defaultValue={20} onChange={handleLimitChange}>
                          <Option value={20}>20</Option>
                          <Option value={40}>40</Option>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}


                dataSource={userList.map((user, index) => {



                  return {
                    key: (limit * (currentPage - 1)) + index + 1,

                    name_hn: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.name_hn}</Title>
                        </div>
                      </>
                    ),
                    post_hn: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.post_hn}</Title>
                        </div>
                      </>
                    ),
                    tehsil_hn: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.tehsil_hn}</Title>
                        </div>
                      </>
                    ),
                    pincode_hn: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.pincode_hn}</Title>
                        </div>
                      </>
                    ),
                    district_hn: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.district_hn}</Title>
                        </div>
                      </>
                    ),
                    state_hn: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.state_hn}</Title>
                        </div>
                      </>
                    ),
                    name_en: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.name_en}</Title>
                        </div>
                      </>
                    ),

                    post_en: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.post_en}</Title>
                        </div>
                      </>
                    ),
                    tehsil_en: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.tehsil_en}</Title>
                        </div>
                      </>
                    ),
                    pincode_en: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.pincode_en}</Title>
                        </div>
                      </>
                    ),
                    district_en: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.district_en}</Title>
                        </div>
                      </>
                    ),
                    state_en: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.state_en}</Title>
                        </div>
                      </>
                    ),
                    remark: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.remark}</Title>
                        </div>
                      </>
                    ),

                    createdAt: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.createdAt ? moment(user.createdAt).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),

                    action: (
                      <div className="button-container" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>

                        <Button onClick={() => AddUserDetails(user.id)} className="update-btn">
                          <EditOutlined />
                        </Button>

                        <Button onClick={() => showDeleteConfirm(user.id)} className="delete-btn">
                          <DeleteOutlined />
                        </Button>



                      </div>
                    )
                  };
                })}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>




      <Drawer
        title="Registration Details"
        width={1000}
        onClose={AddUserDetailsClose}
        open={AddUserDetailsFormModel}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        maskClosable={false}
      >
        <AddUserDetailsModel userId={userId} />

      </Drawer>

      <ImportListModal
        isVisible={isModalVisibleImportList}
        onClose={handleCancelImportList}
        onSuccess={handleOnSuccess}
      />



    </div>
  );
}


export default SuspenseEntryList;
