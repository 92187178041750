import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import * as XLSX from 'xlsx';
import {
  Row, Col, Card, Table, Button, Input, AutoComplete,
  notification, Modal, Select, Avatar, Typography, TableColumnsType,
  Checkbox, DatePicker, Drawer, Form, InputNumber, Space, Tooltip
} from "antd";


import axios from "axios";
import { baseUrl } from "../../config";
import {
  DeleteOutlined, FileExcelOutlined, CalendarOutlined, SendOutlined,
  FilterOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined,
  EyeOutlined, UserOutlined
} from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';

import AddUserDetailsModel from './Form';
import ImportListModal from './components/importListModal';


const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;
const { Item } = Form;
const { TextArea } = Input;


// Table columns definition
const columns = [

  {
    title: "#",
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },
  {
    title: "Phone no",
    dataIndex: "phone_no",
    key: "phone_no",
    fixed: 'left',
    width: 150,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },

  {
    title: 'Father Name',
    dataIndex: 'father_name',
    key: 'father_name',
  },
  
  {
    title: "Announced Type",
    dataIndex: "Announce_type",
    key: "Announce_type",
  },

  {
    title: "Announced Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Announced Date",
    dataIndex: "last_date",
    key: "last_date",
  },
  {
    title: "Dob",
    dataIndex: "dob",
    key: "dob",
  },
  {
    title: "Village",
    dataIndex: "village",
    key: "village"
  },
  {
    title: "District",
    dataIndex: "district",
    key: "district",
  },

  {
    title: "Occ/Post",
    dataIndex: "occ_address",
    key: "occ_address",
  },
  {
    title: "Occ/District",
    dataIndex: "occ_address",
    key: "occ_address",
  },
  {
    title: "Alternative No",
    dataIndex: "alternate_no",
    key: "alternate_no",
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
  },

  {
    title: "Payment Status",
    dataIndex: "payment_status",
    key: "payment_status"
  },

  {
    title: "Remark",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    fixed: 'right',
  }
];

function SuspenseEntryList() {

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPageQuery = parseInt(queryParams.get('page')) || 1;
  const limitQuery = parseInt(queryParams.get('limit')) || 20;

  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(limitQuery);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [filters, setFilters] = useState({
    name: queryParams.get('name') || '',
    email: queryParams.get('email') || '',
    phone: queryParams.get('phone') || '',
    father_name: queryParams.get('father_name') || '',
    village: queryParams.get('village') || '',
    district: queryParams.get('district') || '',
    occ_address: queryParams.get('occ_address') || '',
    amount: queryParams.get('amount') || '',
    dob: queryParams.get('dob') || '',
    createdAt: queryParams.get('createdAt') || '',
  });

  const [showUserFilter, setShowUserFilter] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const { id } = useParams(); // Extract id from URL 
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [txnDate, setTxnDate] = useState();
  const [lastDate, setLastDate] = useState();
  const [addForm, setAddForm] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
  const [userId, setuserId] = useState();
  const [isModalVisibleImportList, setIsModalVisibleImportList] = useState(false);

  useEffect(() => {
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }, [history.location]);


  const AddUserDetailsClose = () => {
    setAddUserDetailsModel(false)
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }



  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/announced/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  async function handleDelete(id) {
    try {
      const response = await axios.delete(`${baseUrl}/announced/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 200) {
        getUserList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
        notification.success({
          message: 'Success',
          description: 'Record deleted successfully!',
          placement: 'topRight'
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);

    // Update both current page and limit in the history
    history.push(`?page=${current}&limit=${pageSize}`);

    // Fetch the updated payment list
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };



  const handleLimitChange = value => {
    setLimit(value);

    // Update URL with the new limit and reset page to 1
    const updatedQueryParams = new URLSearchParams(location.search);
    updatedQueryParams.set('limit', value);
    updatedQueryParams.set('page', 1); // Reset to first page whenever limit changes

    // Push new URL with updated query params
    history.push(`?${updatedQueryParams.toString()}`);

    // Call the API to fetch data with updated limit
    getUserList(1, value);  // Assuming your API call accepts page and limit
  };




  const handleFilterChange = (field, value) => {
    const newFilters = {
      ...filters,
      [field]: value
    };
    setFilters(newFilters);

    // Optionally, update the URL without triggering a search immediately
    const updatedQueryParams = new URLSearchParams(location.search);
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key]) {
        updatedQueryParams.set(key, newFilters[key]);
      } else {
        updatedQueryParams.delete(key); // Remove empty filters from the URL
      }
    });
    history.push(`?${updatedQueryParams.toString()}`);
  };


  const handleUserFilterCheckboxChange = (e) => {
    setShowUserFilter(e.target.checked);

  };



  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    try {
      if (txnDate) {
        const formattedDate = moment(txnDate).format('YYYY-MM-DD');
        values.dob = formattedDate;
      } else {
        values.dob = '';
      }

      if (lastDate) {
        const formattedDate = moment(lastDate).format('YYYY-MM-DD');
        values.last_date = formattedDate;
      } else {
        values.last_date = '';
      }



      values.action = sendMessage ? 'save_send' : 'save';
      if (isUpdateMode) {
        const response = await axios.patch(`${baseUrl}/announced/update/${id}`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Record updated successfully!',
            placement: 'topRight'
          });
          history.push('/suspense-entries');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      } else {
        const response = await axios.post(`${baseUrl}/announced/create`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Registration added successfully!',
            placement: 'topRight'
          });
          form.resetFields();
          setTxnDate('');
          setLastDate('');



          getUserList();

          // history.push('/announced');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };



  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setTxnDate(formattedDate) 

    } else {
      setTxnDate(null);
    }
  };
  const handleDateChangeLast = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD'); 
      setLastDate(formattedDate)

    } else {
      setLastDate(null);
    }
  };

  const toggleFormVisibility = () => {
    setAddForm(prevState => !prevState); // Toggle between true and false
  };


  const handleResendMessage = async (userId) => {
    Modal.confirm({
      title: 'Are you sure you want to Re-send message?',
      onOk: async () => {
        try {
          const response = await axios.patch(`${baseUrl}/announced/messageresend/${userId}`, { send: 'yes' }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            },
          });

          if (response.status === 200) {

            const { state } = history.location; // Get the state from history
            const restoredPage = state?.page || currentPage; // Restore page from state or use current page
            const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

            // Call getUserList with restored page and limit
            getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);

            notification.success({
              message: 'Success',
              description: 'Message sent successfully',
              placement: 'topRight',

            });
          }
        } catch (error) {
          notification.info({
            message: 'Info',
            description: "Failed to verify payment",
            placement: 'topRight'
          });

        }
      },
    });
  }

  const exportToExcel = async () => {
    const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const filterParams = Object.keys(filters)
      .map(key => `${key}=${filters[key]}`)
      .join('&');
    const response = await axios.get(`${baseUrl}/announced/list?page=${1}&limit=all${sortParam}${searchParam}&${filterParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
    if (response.data.status) {
      const formattedData = response.data.result.map((user, index) => ({
        "Sr.No": index + 1,
        "Phone no": user.phone_no,
        "Name": user.name,
        "Announced Amount": user.amount,
        "Announced Date": user.last_date ? moment(user.last_date).format('DD-MM-YYYY') : '-',
        "Father Name": user.father_name,
        "Dob": user.dob ? moment(user.dob).format('DD-MM-YYYY') : '-',
        "Village": user.village,
        "District": user.district,
        "Occ/Post": user.occ_post,
        "Occ Address": user.occ_address,
        "Alternate No": user.alternate_no,
        "Date": user.createdAt ? moment(user.createdAt).format('DD-MM-YYYY') : '-',
        "Payment Status": user.phone_no === user.user_phone_no ? "Received" : 'Pending',
        "Remark": user.remark
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Announced-list");
      XLSX.writeFile(workbook, "Announced-List.xlsx");
    } else {
      notification.info({
        message: 'Info',
        description: response.data.message,
        placement: 'topRight'
      });
    }
  };


  const AddUserDetails = async (userId) => {
    console.log('uid', userId)
    setuserId(userId);
    setAddUserDetailsModel(true)

  }

  const handleCancelImportList = () => {
    setIsModalVisibleImportList(false);
  };

  const handleOnSuccess = () => {
    setIsModalVisibleImportList(false);
    getUserList();
  };



  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>



        {addForm && (
          <Col xs="24" xl={24}>
            <div className="tabled">
              <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                  <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title={isUpdateMode ? "Update Announced Form" : "Add Announced Form"}
                  >
                    <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                      <Row gutter={[16, 16]}>


                        <Col xs={24} sm={4} lg={4}>
                          <Item
                            label="Phone No"
                            name="phone_no"
                            rules={[{ required: true, message: 'Please Enter  Phone no' },
                            {
                              pattern: /^\d{10,12}$/,
                              message: 'Phone no must be between 10 and 12 digits!',
                            }
                            ]}
                          >

                            <Input type="number" placeholder="Enter Phone no" />
                          </Item>
                        </Col>
                        <Col xs={24} sm={4} lg={4}>
                          <Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please Enter Name' }]}
                          >
                            <Input placeholder="Enter Name" />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item
                            label="Father Name"
                            name="father_name"
                          >
                            <Input placeholder="Enter Father Name" />
                          </Item>
                        </Col>

                        <Col xs={24} sm={6} lg={4}>
                          <Item
                            label="Village"
                            name="village"
                          >
                            <Input placeholder="Enter Village" />
                          </Item>
                        </Col>

                        <Col xs={24} sm={12} lg={4}>
                          <Item
                            label="District"
                            name="district"
                          >
                            <Input placeholder="Enter District" />
                          </Item>
                        </Col>
                        <Col xs={24} sm={12} lg={4}>
                          <Item
                            label="Occ/Post"
                            name="occ_post"
                          >
                            <Input placeholder="Enter Occ/Post" />
                          </Item>
                        </Col>

                        <Col xs={24} sm={12} lg={4}>
                          <Item
                            label="Occ/District/Post Place"
                            name="occ_address"
                          >
                            <Input placeholder="Enter Occ/District/Post Place" />
                          </Item>
                        </Col>

                        <Col xs={24} sm={12} lg={4}>
                          <Item className="custom-form-item" label="Announce Type" name="Announce_type">
                            <Select placeholder="Announce Type">
                              <Option value="Monthly">Monthly</Option>
                              <Option value="Yearly">Yearly</Option>
                            </Select>
                          </Item>

                        </Col>

                        <Col xs={24} sm={12} lg={4}>
                          <Item
                            label="Announced Amount"
                            name="amount"
                          >
                            <Input type="number" placeholder="Enter Amount Pledge" />
                          </Item>
                        </Col>

                        <Col xs={24} sm={4} lg={4}>
                          <Item label="Announced Date" >
                            <DatePicker onChange={handleDateChangeLast} value={lastDate && moment(lastDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select Announced Date" prefix={<CalendarOutlined />} />

                          </Item>
                        </Col>

                        <Col xs={24} sm={4} lg={4}>
                          <Item label="DOB" >
                            <DatePicker onChange={handleDateChange} value={txnDate && moment(txnDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select DOB" prefix={<CalendarOutlined />} />

                          </Item>
                        </Col>

                        <Col xs={24} sm={12} lg={4}>
                          <Item
                            label="Alternate No"
                            name="alternate_no"
                          >
                            <Input type="number" placeholder="Enter Alternate No" />
                          </Item>
                        </Col>



                        <Col xs={24} sm={12} lg={8}>
                          <Item
                            label="Remark"
                            name="remark"
                          >
                            <TextArea placeholder="Enter Remark" rows={2} />
                          </Item>
                        </Col>

                      </Row>
                      <Row style={{ textAlign: 'right' }}>
                        <Col xs={24} sm={24} lg={24}>
                          <Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() => setSendMessage(false)}
                              style={{ marginRight: '10px' }} // Add space between buttons
                            >
                              {isUpdateMode ? "Update" : "Save"}
                            </Button>

                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() => setSendMessage(true)}
                            >
                              {isUpdateMode ? "Update" : "Save/Send"}
                            </Button>
                          </Item>
                        </Col>
                      </Row>

                    </Form>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>

        )}





        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="New Announced Form List"
            extra={
              <div style={{ display: 'flex', gap: '10px' }}>

<Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={() => setIsModalVisibleImportList(true)}
                  style={{
                    padding: '4px 15px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    borderRadius: '5px',
                  }}
                >
                  Import List
                </Button>
                {hasPermission('createPermission', 'Create User') && (

                  <button onClick={toggleFormVisibility} className="custom-btn">Add Announced Form</button>


                )}
                <Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={exportToExcel}
                  style={{
                    padding: '4px 15px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    borderRadius: '5px',
                  }}
                >
                  Excel Download
                </Button>
              </div>
            }

          >
            <div className="table-responsive">
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <FilterOutlined /> <Checkbox onChange={handleUserFilterCheckboxChange}><Title style={{ fontSize: 13 }} level={5}>Form Filter</Title></Checkbox>
                      </Col>
                      {showUserFilter && (
                        <>
                          <Col span={4}> <Input placeholder="Phone no" value={filters.phone_no} onChange={e => handleFilterChange('phone_no', e.target.value)} /></Col>

                          <Col span={4}><Input placeholder="Name" value={filters.name} onChange={e => handleFilterChange('name', e.target.value)} /></Col>
                          <Col span={4}> <Input placeholder="Father Name" value={filters.father_name} onChange={e => handleFilterChange('father_name', e.target.value)} /></Col>
                          <Col span={4}><Input placeholder="Village" value={filters.village} onChange={e => handleFilterChange('village', e.target.value)} /></Col>

                          <Col span={4}>
                            <Input placeholder="District" value={filters.district} onChange={e => handleFilterChange('district', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input placeholder="Occ/Post" value={filters.occ_post} onChange={e => handleFilterChange('occ_post', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input placeholder="Occ/District" value={filters.occ_address} onChange={e => handleFilterChange('occ_address', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input placeholder="Announced Amount" value={filters.amount} onChange={e => handleFilterChange('amount', e.target.value)}
                            />
                          </Col>

                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.last_date ? moment(filters.last_date, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('last_date', dateString)} placeholder="Announced Date"
                            />
                          </Col>

                          <Col span={4}>
                            <Input placeholder="Alternative no" value={filters.alternate_no} onChange={e => handleFilterChange('alternate_no  ', e.target.value)}
                            />
                          </Col>

                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.dob ? moment(filters.dob, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('dob', dateString)} placeholder="Dob Date"
                            />
                          </Col>



                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.createdAt ? moment(filters.createdAt, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('createdAt', dateString)} placeholder="Entry Date"
                            />
                          </Col>


                        </>
                      )}

                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select defaultValue={20} onChange={handleLimitChange}>
                          <Option value={20}>20</Option>
                          <Option value={40}>40</Option>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}


                dataSource={userList.map((user, index) => {

                  const isMatch = user.phone_no === user.user_phone_no;

                  return {
                    key: (limit * (currentPage - 1)) + index + 1,

                    phone_no: (
                      <div className="author-info">
                        <Tooltip title="Number match with origin member!">
                          <Title
                            level={5}
                            style={{
                              textAlign: 'center',
                              color: isMatch ? 'white' : 'inherit',
                              backgroundColor: isMatch ? 'green' : 'inherit',
                              padding: isMatch ? '5px' : 'inherit',
                              borderRadius: isMatch ? '5px' : 'inherit'
                            }}
                          >
                            {user.phone_no}
                          </Title>
                        </Tooltip>
                      </div>
                    ),




                    name: (
                      <>
                        <div className="author-info">
                          <Title level={5}> {user.name}</Title>
                        </div>
                      </>
                    ),
                    father_name: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.father_name}  </Title>
                        </div>
                      </>
                    ),
                    
                    Announce_type: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.Announce_type}  </Title>
                        </div>
                      </>
                    ),
                    amount: (
                      <>
                        <div className="author-info">
                          <Title level={5}>Rs.{user.amount}  </Title>
                        </div>
                      </>
                    ),
                    last_date: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.last_date ? moment(user.last_date).format('DD MMMM YYYY') : '-'}</Title>
                        </div>
                      </>
                    ),

                    dob: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.dob ? moment(user.dob).format('DD MMMM YYYY') : '-'}</Title>
                        </div>
                      </>
                    ),
                    village: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.village}  </Title>
                        </div>
                      </>
                    ),
                    district: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.district}  </Title>
                        </div>
                      </>
                    ),
                    occ_post: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.occ_post}  </Title>
                        </div>
                      </>
                    ),
                    occ_address: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.occ_address}  </Title>
                        </div>
                      </>
                    ),
                    alternate_no: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.alternate_no}  </Title>
                        </div>
                      </>
                    ),

                    payment_status: (
                      <div className="author-info">
                        <Tooltip title="Number match with origin member!">
                          <Title
                            level={5}
                            style={{
                              textAlign: 'center',
                              color: isMatch ? 'white' : 'inherit',
                              backgroundColor: isMatch ? 'green' : 'inherit',
                              padding: isMatch ? '5px' : 'inherit',
                              borderRadius: isMatch ? '5px' : 'inherit'
                            }}
                          >
                            {isMatch ? "Received" : 'Pending'}
                          </Title>
                        </Tooltip>
                      </div>
                    ),



                    remark: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.remark}  </Title>
                        </div>
                      </>
                    ),

                    createdAt: (
                      <>
                        <div className="author-info">
                          <Title level={5}>{user.createdAt ? moment(user.createdAt).format('DD MMMM YYYY') : '-'}</Title>

                        </div>
                      </>
                    ),


                    action: (
                      <div className="button-container" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>

                        <Button onClick={() => AddUserDetails(user.id)} className="update-btn">
                          <EditOutlined />
                        </Button>

                        <Button onClick={() => showDeleteConfirm(user.id)} className="delete-btn">
                          <DeleteOutlined />
                        </Button>

                        <Button icon={<SendOutlined />} onClick={() => handleResendMessage(user.id)}>
                          {user.is_send === 1 ? 'Re-send' : 'Send'}

                        </Button>

                      </div>
                    )
                  };
                })}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>




      <Drawer
        title="Announced Details"
        width={1000}
        onClose={AddUserDetailsClose}
        open={AddUserDetailsFormModel}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        maskClosable={false}
      >


        <AddUserDetailsModel userId={userId} />

      </Drawer>

      <ImportListModal
        isVisible={isModalVisibleImportList}
        onClose={handleCancelImportList}
        onSuccess={handleOnSuccess}
      />



    </div>
  );
}


export default SuspenseEntryList;
