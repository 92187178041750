import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import PurnimaMember from "./pages/Purnima-members";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import RoleList from "./pages/role/List";
import RoleForm from "./pages/role/Form";
import StaffList from "./pages/staff/List";
import StaffForm from "./pages/staff/Form";

import UsersList from "./pages/sn_users/List";
import UsersForm from "./pages/sn_users/Form";
import UsersView from "./pages/sn_users/View";

import SuspenseList from "./pages/sn_suspense_entries/List";
import SuspenseForm from "./pages/sn_suspense_entries/Form";
import SuspenseView from "./pages/sn_suspense_entries/View";

import RegistrationList from "./pages/sn_registration/List";
import RegistrationForm from "./pages/sn_registration/Form";

import AnnouncedList from "./pages/sn_announced/List";
import AnnouncedForm from "./pages/sn_announced/Form";

import PurnimaUserList from "./pages/sn_purnima_form/List";
import PurnimaUserForm from "./pages/sn_purnima_form/Form";

import VisheshSahyogiList from "./pages/sn_vishesh_sahyogi/List";
import VisheshSahyogiForm from "./pages/sn_vishesh_sahyogi/Form";

import InquiryList from "./pages/sn_inquiry/List";
import InquiryForm from "./pages/sn_inquiry/Form";

import WorkManagmentList from "./pages/sn_work_managment/List";
import WorkManagmentForm from "./pages/sn_work_managment/Form";

import VillageList from "./pages/sn_village/List";
import VillageForm from "./pages/sn_village/Form";

import MessageLogList from "./pages/sn_message_logs/List";

import VisitorList from "./pages/sn_visitor/List";
import VisitorForm from "./pages/sn_visitor/Form";
  

import PaymentsList from "./pages/sn_payments/List";
import PaymentsForm from "./pages/sn_payments/Form";
import PaymentsView from "./pages/sn_payments/ViewPayments"; 
import AddUserPayment from "./pages/sn_payments/AddUserPayment"; 
import EditUserPayment from "./pages/sn_payments/EditUserPayment"; 
import TrackingList from "./pages/sn_tracking/List";
import TrashPayments from "./pages/sn_payments/TracePayment";

import ManualReport from "./pages/sn_reports/ManualReport";
import BirthdayReport from "./pages/sn_reports/BirthdayReport";
import CallingReport from "./pages/sn_reports/CallingReport";
import YealyReport from "./pages/sn_reports/YealyReport";
import ImportData from "./pages/sn_reports/ImportData";

import ThankYouPage from "./pages/thank-you"; 
import OnlinePaymentUser from "./pages/Online-payments";
import PaymentSuccess from "./pages/Payment-success";
import DecodeUser from "./pages/Decode-User";

import Visitor from "./pages/New-visitor";
import Visitors from "./pages/New-Visitor-List";
 
  
  
function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/purnima-member" exact component={PurnimaMember} />
        <Route path="/purnima-member-confirm" exact component={ThankYouPage} />
        <Route path="/donation-payment" exact component={OnlinePaymentUser} /> 
        <Route path="/payment-success" exact component={PaymentSuccess} /> 
        <Route path="/decode-user" exact component={DecodeUser} />
        <Route exact path="/new-visitors" component={Visitor} />
        <Route exact path="/visitors" component={Visitors} />

       
        
        <Route path="/sign-in" exact component={SignIn} />
        <Main>
          <Route exact path="/" component={Home} />
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} />
          
          {/* Role */}
          <Route exact path="/role" component={RoleList} />
          <Route exact path="/role/add" component={RoleForm} />
          <Route exact path="/role/update/:id" component={RoleForm} />
           {/* Staff */}
          <Route exact path="/staff" component={StaffList} />
          <Route exact path="/staff/add" component={StaffForm} />
          <Route exact path="/staff/update/:id" component={StaffForm} />

          <Route exact path="/users" component={UsersList} />
          <Route exact path="/users/add" component={UsersForm} />
          <Route exact path="/users/update/:id" component={UsersForm} />
          <Route exact path="/users/view/:id" component={UsersView} />
          
          <Route exact path="/payments-history" component={PaymentsList} />
          <Route exact path="/payments/add" component={PaymentsForm} />
          <Route exact path="/payments/update/:id" component={EditUserPayment} />
          <Route exact path="/payments/view/:id" component={PaymentsView} />
          <Route exact path="/payments/add_user_payment" component={AddUserPayment} />
          <Route exact path="/tracking" component={TrackingList} />
          <Route exact path="/trash-payments" component={TrashPayments} />

          <Route exact path="/suspense-entries" component={SuspenseList} />
          <Route exact path="/suspense-entries/add" component={SuspenseForm} />
          <Route exact path="/suspense-entries/update/:id" component={SuspenseForm} />
          <Route exact path="/suspense-entries/view/:id" component={SuspenseView} />

          <Route exact path="/registration" component={RegistrationList} />
          <Route exact path="/registration/add" component={RegistrationForm} />
          <Route exact path="/registration/update/:id" component={RegistrationForm} />

          <Route exact path="/visitor" component={VisitorList} />
          <Route exact path="/visitor/add" component={VisitorForm} />
          <Route exact path="/visitor/update/:id" component={VisitorForm} />


          <Route exact path="/announced" component={AnnouncedList} />
          <Route exact path="/announced/add" component={AnnouncedForm} />
          <Route exact path="/announced/update/:id" component={AnnouncedForm} />

          <Route exact path="/purnima-form" component={PurnimaUserList} />
          <Route exact path="/purnima-form/add" component={PurnimaUserForm} />
          <Route exact path="/purnima-form/update/:id" component={PurnimaUserForm} />

          <Route exact path="/vishesh-sahyogi" component={VisheshSahyogiList} />
          <Route exact path="/vishesh-sahyogi/add" component={VisheshSahyogiForm} />
          <Route exact path="/vishesh-sahyogi/update/:id" component={VisheshSahyogiForm} />


          <Route exact path="/inquiry" component={InquiryList} />
          <Route exact path="/inquiry/add" component={InquiryForm} />
          <Route exact path="/inquiry/update/:id" component={InquiryForm} />

          <Route exact path="/work_managment" component={WorkManagmentList} />
          <Route exact path="/work_managment/add" component={WorkManagmentForm} />
          <Route exact path="/work_managment/update/:id" component={WorkManagmentForm} />
 
          <Route exact path="/village" component={VillageList} />
          <Route exact path="/village/add" component={VillageForm} />
          <Route exact path="/village/update/:id" component={VillageForm} />

          <Route exact path="/message-log" component={MessageLogList} /> 

          <Route exact path="/reports/manual-report" component={ManualReport} />
          <Route exact path="/reports/birthday-list" component={BirthdayReport} />
          <Route exact path="/reports/calling-report" component={CallingReport} />
          <Route exact path="/reports/yearly-report" component={YealyReport} /> 
          <Route exact path="/reports/import" component={ImportData} />

        

   
        </Main>
      </Switch>
    </div>
  );
}

export default App;
