import React, { useState, useEffect } from "react";
import { Result, Button, Spin, Table, Row, Col, Typography, Input, Card, message } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { PlusOutlined, MinusOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';


import axios from "axios";
import { baseUrl, secretKey, rozerpay_key_id } from "../config";
import CryptoJS from "crypto-js";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import moment from 'moment';
import dayjs from 'dayjs';
import 'antd/dist/antd.css';
import '../assets/styles/custom_style.css';


//npm install --save-dev @types/crypto-js --force
//npm install --save-dev @types/react-router-dom --force
//npm install --save-dev @types/react-dom  

const { Title, Text } = Typography;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface UserData {
  name: string;
  phone_no: string;
  father_name: string;
  announced_amount: number;
  received_amount: number;
  last_transaction_date: string;
  relation : string,
  last_transaction_amount :string   
}
interface TableData {
  key: string;
  field: string;
  value?: string; // Optional since some values might be undefined
}
interface PaymentResponse {
  message: string;
  payment_id?: number;
}



const OnlinePaymentUser = () => {
  const query = useQuery();
  const id = query.get("id");
  const history = useHistory();

  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);


  const { isLoading, Razorpay } = useRazorpay();
  const [orderId, setOrderId] = useState("");
  const [amount, setAmount] = useState<number>();
  const [userId, setUserId] = useState<string>(); // Assume this is the logged-in userId


  const [monthlyShareAmount, setMonthlyShareAmount] = useState<number | undefined>(undefined);
  const [months, setMonths] = useState(1);
  const [totalAmount, setTotalAmount] = useState((monthlyShareAmount ?? 0) * months);

  const [emailAddress, setEmailAddress] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [phoneNo, setPhoneNo] = useState<string | undefined>(undefined);

  const incrementMonth = () => {
    setMonths((prevMonths) => prevMonths + 1);
    setTotalAmount((monthlyShareAmount ?? 0) * (months + 1));
  };

  const decrementMonth = () => {
    if (months > 1) {
      setMonths((prevMonths) => prevMonths - 1);
      setTotalAmount((monthlyShareAmount ?? 0) * (months - 1));
    }
  };

  const handleTotalAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTotal = Number(e.target.value);
    setTotalAmount(newTotal);

    if (monthlyShareAmount) {
      // Calculate months only when totalAmount is a multiple of monthlyShareAmount
      const calculatedMonths = Math.floor(newTotal / monthlyShareAmount);
      setMonths(calculatedMonths > 0 ? calculatedMonths : 1); // Ensure at least 1 month
    }
  };


  useEffect(() => {
    const fetchUserData = async () => {
      if (!id) {
        setError("No ID provided.");
        setLoading(false);
        return;
      }
      try {
        const decodedId = decodeURIComponent(id);
        const bytes = CryptoJS.AES.decrypt(decodedId, secretKey);
        const memberId = bytes.toString(CryptoJS.enc.Utf8);
        if (!memberId) {
          setError("Invalid encrypted ID.");
          setLoading(false);
          return;
        }

        const response = await axios.get(`${baseUrl}/onlinePaymentUser/userDetail/${memberId}`);
        setUserId(memberId);
        setUserData(response.data.result);
        setAmount(response?.data?.result?.announced_amount);
        setMonthlyShareAmount(response?.data?.result?.announced_amount);
        setTotalAmount(response?.data?.result?.announced_amount * months);
        setName(response?.data?.result?.name_eng);
        setEmailAddress(response?.data?.result?.email_address);
        setPhoneNo(response?.data?.result?.phone_no);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch user details.");
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const handleBackHome = () => {
    // navigate.push("/");
  };

  useEffect(() => {
    // Ensure Razorpay script is loaded and ready
    if (!Razorpay) {
      console.error("Razorpay not loaded");
    }
  }, [Razorpay]);

  // Fetch order ID from backend
  const fetchOrder = async () => {
    try {
      const response = await axios.post(`${baseUrl}/onlinePaymentUser/generate-payment-token`, {
        amount: totalAmount, // Use the input amount
        currency: "INR",
      }, {
        headers: {
          'Cache-Control': 'no-cache', // Disable caching
        }
      });
      setLoading(false);

      setOrderId(response.data.id); // Save order ID
      return response.data.id;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching order:", error);
      return null;
    }
  };

  const handlePayment = async () => {
    if (!totalAmount) {
      message.error("Please enter a valid amount greater than 0.");
      return;
    }
    setLoading(true);

    const order_id = await fetchOrder();

    if (!order_id) return;

    const options: RazorpayOrderOptions = {
      key: rozerpay_key_id,
      amount: totalAmount * 100, // Convert amount to paise (multiply by 100)
      currency: "INR",
      name: "अखिल भारतीय राजपुरोहित समाज विकास संस्थान आसोतरा",
      description: "समाज शिक्षा निधि समर्पण योजना",
      order_id: order_id, // Use the order_id from the backend
      handler: async (response) => {
        // Send payment details to backend to update status
        try {
          console.log('response',response);
          
          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;

          const paymentData = {
            payment_id: razorpay_payment_id,
            order_id: razorpay_order_id,
            signature: razorpay_signature,
            amount: Number(totalAmount),
            userId: userId,
            totalmonth: months,
            monthlyShareAmount: monthlyShareAmount,
            phoneNo: phoneNo
          };

          // Send the payment data to backend for verification and update
          //  await axios.post(`${baseUrl}/onlinePaymentUser/add-payment`, paymentData);

          const responsePayment = await axios.post(`${baseUrl}/onlinePaymentUser/add-payment`, paymentData);
          console.log("responsePayment", responsePayment)
          console.log("responsePayment.status", responsePayment.status)
          console.log("responsePayment.data.payment_id", responsePayment.data.payment_id)

          if (responsePayment.status === 200 && responsePayment.data.payment_id) {

            const id = responsePayment.data.payment_id;
            const encryptedId = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();
            const encodedId = encodeURIComponent(encryptedId);
            history.push(`/payment-success?id=${encodedId}`);

            const paymentId = responsePayment.data.payment_id;
            console.log("Payment verified successfully. Payment ID:", paymentId);
            // You can use paymentId as needed in the frontend
          } else {
            console.error("Payment verification failed or payment ID not received");
          }


        } catch (error) {
          console.error("Error verifying payment:", error);
          alert("Payment verification failed");
        }
      },
      prefill: {
        name: name,
        email: emailAddress ?? '',
        contact: phoneNo,
      },
      theme: {
        color: "#F37254",
      },
    };

    const razorpayInstance = new Razorpay(options);
    razorpayInstance.open();
  };



  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "100vh",
  //         width: "100vw",
  //         backgroundColor: "#e0f7ff", // Light blue background for loading overlay
  //         position: "fixed",
  //         top: 0,
  //         left: 0,
  //         zIndex: 9999,
  //       }}
  //     >
  //       <Spin tip="Loading user details......." size="large" />
  //     </div>
  //   );
  // }



  if (error) {
    return (
      <Result
        status="error"
        title="Something went wrong"
        subTitle={error}
        extra={[
          <Button type="primary" key="home" onClick={handleBackHome}>
            Back to Home
          </Button>
        ]}
      />
    );
  }

  const columns: ColumnsType<TableData> = [
    { title: "सदस्य", dataIndex: "field", key: "field", responsive: ["md"], width: 150 },
    {
      title: "जानकारी",
      dataIndex: "value",
      key: "value",
      render: (text: string | undefined) => <strong>{text}</strong>
    },
  ];

  const data: TableData[] = [
    { key: "1", field: "संपर्क सूत्र", value: userData?.phone_no }, 
    { key: "2", field: "नाम", value: userData?.name + " " + userData?.relation  + " " + userData?.father_name  },
    { key: "3", field: "मासिक/वार्षिक समर्पण", value: "Rs." + (userData?.announced_amount ?? '-') },
    {
      key: "4",
      field: "पिछला समर्पण एवं दिनांक",
      value: userData?.last_transaction_date
        ? "Rs." + (userData?.last_transaction_amount ?? '-') + moment(userData.last_transaction_date).format('DD MMM YYYY')
        : '-'
    },
    { key: "5", field: "कुल प्राप्त समर्पण", value: userData?.received_amount ? "Rs." + userData.received_amount : '-'  },    
   
  ];


  return (
    <div>

      {/* Loading Overlay */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(251, 252, 253, 0.5)", // Semi-transparent blue background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <Spin tip="Processing payment..." size="large" />
        </div>
      )}

      <Row justify="center" style={{ padding: "40px 0", backgroundColor: "#f5f7fa" }}>
        <Col xs={24} md={16} lg={12}>
          <Card
            style={{
              backgroundColor: "#ffffff",
              padding: "20px",
              borderRadius: "12px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
            bodyStyle={{ padding: 0 }}
          >
            <div style={{ padding: "20px", textAlign: "center" }}>
              <div style={{ marginBottom: "5px", paddingTop: "0px", marginTop: "0px" }}>

                <Title level={5} style={{ color: "red" }}>
                  जय गुरु महाराज री सा
                </Title>  

                <Title level={3}   style={{ color: "#1890ff",fontSize: "18px" }}>
                अखिल भारतीय राजपुरोहित समाज विकास संस्थान ब्रह्मसरोवर, श्री खेतेश्वर ब्रह्मधामतीर्थ, आसोतरा
                </Title>

                <Title level={4} style={{ color: "red",fontSize: "18px"  }}>
                समाज शिक्षा निधि समर्पण योजना
                </Title>  

               
              </div>

              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                style={{ backgroundColor: "#fafafa", borderRadius: "8px", marginBottom: "20px" }}
                rowKey="key"
              />

              <div style={{ marginBottom: "20px", textAlign: "left" }}>

                <div style={{ marginBottom: "20px", display: "flex", gap: "20px" }}>
                  {/* Monthly Share Amount Section */}
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "50%" }}>
                    <Text strong style={{ fontSize: "16px", color: "#595959", marginBottom: "8px" }}>
                    मासिक/वार्षिक समर्पण:
                    </Text>
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "8px",
                        border: "1px solid #d9d9d9",
                        backgroundColor: "#fafafa",
                        width: "100%",
                        fontSize: "16px",
                        color: "#595959",
                      }}
                    >
                      Rs. {monthlyShareAmount}
                    </div>
                  </div>

                  {/* Number of Months Section */}
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "50%" }}>
                    <Text strong style={{ fontSize: "16px", color: "#595959", marginBottom: "8px" }}>
                    माह की संख्या:
                    </Text>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        onClick={decrementMonth}
                        disabled={months === 1}
                        style={{
                          marginRight: "8px",
                          fontSize: "24px", // Increase font size
                          fontWeight: "bold", // Make text bold
                          lineHeight: "1", // Center the icon vertically
                          padding: "0 12px", // Add horizontal padding
                        }}
                      >
                        &ndash; {/* Custom minus symbol */}
                      </Button>

                      <Text style={{ fontSize: "16px", color: "#595959" }}>
                        {months} {months === 1 ? "Month" : "Months"}
                      </Text>

                      <Button
                        onClick={incrementMonth}
                        style={{
                          marginLeft: "8px",
                          fontSize: "24px", // Increase font size
                          fontWeight: "bold", // Make text bold
                          lineHeight: "1", // Center the icon vertically
                          padding: "0 12px", // Add horizontal padding
                        }}
                      >
                        &#43; {/* Custom plus symbol */}
                      </Button>
                    </div>
                  </div>
                </div>




                <div style={{ marginBottom: "20px", textAlign: "left" }}>
                  <Text strong style={{ fontSize: "16px", color: "#595959" }}>Total Amount:</Text>
                  <Input
                    type="number"
                    value={totalAmount}
                    onChange={handleTotalAmountChange}
                    prefix="Rs."
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "8px",
                      borderColor: "#d9d9d9",
                      marginTop: "8px",
                      fontSize: "16px",
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </div>

              </div>

              <Button
                type="primary"
                size="large"
                block
                onClick={handlePayment}
                style={{
                  background: "linear-gradient(90deg, #ff7e5f, #feb47b)",
                  borderColor: "transparent",
                  color: "#fff",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  transition: "background 0.3s",
                }}

              >
                Pay Now
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OnlinePaymentUser;
