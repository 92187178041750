import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Typography, notification, Form, Input, InputNumber, Select, Upload, Checkbox, DatePicker, Modal, message, Avatar } from "antd";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import { UploadOutlined, UserOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined, PhoneOutlined, MailOutlined, CalendarOutlined, HomeOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import '../../assets/styles/custom_style.css';
import UserModalContent from './UserModalContent';
import moment from 'moment';


const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

function PaymentsForm() {
    const { id } = useParams(); // Extract id from URL
    const history = useHistory();
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [file, setFile] = useState(null);  // state to store the selected file


    useEffect(() => {
        // Check if id exists to determine if it's an update mode
        if (id) {
            setIsUpdateMode(true);
            fetchUserDetails();
        }
        // check phone number


    }, [id]);


    const fetchUserDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/payments/read/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                // setRoleData(response.data.result);
                const userData = response.data.result;
                userData.transaction_date = userData.transaction_date ? moment(userData.transaction_date) : null;
                userData.payment_entry_date = userData.payment_entry_date ? moment(userData.payment_entry_date) : null;
                form.setFieldsValue(userData);

                if (userData.profile_img) {
                    setImageUrl(userData.profile_img);
                }

            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            Object.keys(values).forEach(key => {
                formData.append(key, values[key] ? values[key] : '');
            });
            if (file) {
                formData.append("file", file);
            }
            if (isUpdateMode) {
                const response = await axios.patch(`${baseUrl}/payments/update/${id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Payment updated successfully!',
                        placement: 'topRight'
                    });
                    history.push('/payments');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            } else {
                const response = await axios.post(`${baseUrl}/payments/create`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Payment added successfully!',
                        placement: 'topRight'
                    });
                    //Show model data here
                  //  setAddedUserData(values); // Save added payments data
                  //  setIsModalVisible(true); // Show modal
                    form.resetFields();
                     history.push('/payments');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };



    //For model show data
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addedUserData, setAddedUserData] = useState(null);
    const handleModalClose = () => {
        setIsModalVisible(false);
        setAddedUserData(null);
        history.push('/payments');
    };
    const userModalContent = addedUserData ? (
        <div>
            <p><strong>Phone No.:</strong> {addedUserData.phone_no}</p>
            {/* Add more fields as required */}
        </div>
    ) : null;

    const durationOptions = Array.from({ length: 12 }, (_, index) => index + 1);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [showAmountDuration, setShowAmountDuration] = useState(false);

    const handlePhoneNumberChange = async (e) => {
        const value = e.target.value;
        if (/^\d{0,12}$/.test(value)) {
            setPhoneNumber(value);
            if (value.length >= 10) {
                await checkPhoneNumber(value); // Call the async function to check the phone number
            }
        }
    }

    const checkPhoneNumber = async (phone, type = '') => {
        try {
            const response = await axios.post(
                `${baseUrl}/payments/checkUserPhone`,
                { phone_no: phone },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200 && response.data.exists) {
                message.success('This is an already existing user. Please add their payment details.');
                const { announced_amount, payment_type_user, announced_type } = response.data.details;
                form.setFieldsValue({
                    amount_announce: announced_amount,
                    duration_type: announced_type,
                });

                if (type == 'view') {
                    setModalViewVisible(true);
                    setAddedUserData(response.data.details); // Save added payments data
                }

            } else {
                message.success("This is a new user. Please add their payment details so the system can create them automatically.");
                form.setFieldsValue({
                    amount_announce: null,
                    duration_type: null,
                });
            }
        } catch (error) {
            console.error('There was an error!', error);
            message.error('Error checking phone number');
        }
    };

    const checkPhoneNumberView = async (phone, type = '') => {
        try {
            const response = await axios.post(
                `${baseUrl}/payments/checkUserPhoneView`,
                { phone_no: phone },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                }
            );
            if (response.status === 200 && response.data.exists) { 
                if (type == 'view') {
                    setModalViewVisible(true);
                    setAddedUserData(response.data.details); // Save added payments data
                }

            } else {
                message.success("This is a new user. Please add their payment details so the system can create them automatically.");
               
            }
        } catch (error) {
            console.error('There was an error!', error);
            message.error('Error checking phone number');
        }
    };

    const handlePaymentTypeChange = async (value) => {

        const phoneNumber = form.getFieldValue('phone_no');
        console.log(phoneNumber)

        if (value === 'Recurring') {
            setShowAmountDuration(true)
            if (phoneNumber.length >= 10) {
                await checkPhoneNumber(phoneNumber); // Call the async function to check the phone number
            }
        } else {
            setShowAmountDuration(false)
        }

    }

    const [countryCode, setCountryCode] = useState('+91');
    const handleCountryCodeChange = (value) => {
        setCountryCode(value);
        form.setFieldsValue({ country_code: value });
    };
    const [modalViewVisible, setModalViewVisible] = useState(false); // State to manage the visibility of the modal 
    const onViewButtonClick = async () => {
        if (phoneNumber.length >= 10) {
            await checkPhoneNumberView(phoneNumber, 'view'); // Call the async function to check the phone number
        }
    };

    const handleViewModalClose = () => {
        setModalViewVisible(false); // Close modal
    };


    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title={isUpdateMode ? "Update Payment" : "Add Payment/भुगतान जोड़ें"}
                    >
                        <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                            <Row gutter={[16, 16]}>


                                <Col xs={24}><Title level={5}>स्वैच्छिक भुगतान</Title> </Col>

                                <Col xs={24} sm={12} lg={12}>
                                    <Item className="phone_no" label="Phone No." name="phone_no"
                                        rules={[{ required: true, message: 'Please enter phone no' },
                                        {
                                            pattern: /^\d{10,12}$/,
                                            message: 'Phone no must be between 10 and 12 digits!',
                                        }
                                        ]} >
                                        <Input onChange={handlePhoneNumberChange} placeholder="Enter Phone no."
                                            addonBefore={
                                                <Select
                                                    defaultValue={countryCode}
                                                    onChange={handleCountryCodeChange}
                                                    style={{ width: 80 }}
                                                >
                                                    <Option value="+91">+91</Option>
                                                    <Option value="+1">+1</Option>
                                                    <Option value="+44">+44</Option>
                                                    {/* Add more country codes as needed */}
                                                </Select>
                                            }
                                            prefix={<PhoneOutlined />}
                                            addonAfter={
                                                <Button onClick={onViewButtonClick}>View</Button>
                                            }
                                        />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={12}>
                                    <Item label="Payment Type" name="payment_type" >
                                        <Select onChange={handlePaymentTypeChange} className="payment_type" placeholder="Select Payment Type">
                                            <Option value="OneTime">One Time/एक बार</Option>
                                            <Option value="Recurring">Recurring/पुनरावर्ती</Option>
                                        </Select>
                                    </Item>
                                </Col>
                                {showAmountDuration && (
                                    <>
                                        <Col xs={24}><Title level={5}>समर्पण अवधि/घोषित राशि</Title> </Col>
                                        <Col xs={24} sm={12} lg={12}>
                                            <Item label="Duration Type(In a Single Year)/समर्पण अवधि" name="duration_type" >
                                                <Select placeholder="Select Duration Type">
                                                    {durationOptions.map(option => (
                                                        <Option key={option} value={option}>{option} Time in year(वर्ष में समय)</Option>
                                                    ))}

                                                </Select>
                                            </Item>
                                        </Col>
                                    </>
                                )}

                                {showAmountDuration && (
                                    <>
                                        <Col xs={24} sm={12} lg={12}>
                                            <Item label="Announced Amount(घोषित राशि)Rs." name="amount_announce" rules={[{ required: true, message: 'Please enter  announced amount' }]}>
                                                <InputNumber className="input_number_type amount_announce" placeholder="Enter Announced Amount Rs." prefix={<MoneyCollectOutlined />} />
                                            </Item>
                                        </Col>
                                    </>
                                )}

                                <Col xs={24}><Title level={5}>भुगतान विवरण</Title> </Col>

                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="Payment Category/भुगतान श्रेणी" name="payment_category" >
                                        <Select placeholder="Select Payment Category">
                                            <Option value="ShikshaNidi">Shiksha Nidi/शिक्षानीदी</Option>
                                            <Option value="Gosala">Gosala/गौशाला</Option>
                                            <Option value="Birthday">Birthday/जन्मदिन</Option>
                                            <Option value="Marriage">Marriage/शादी</Option>
                                            <Option value="Other/अन्य">Other</Option>
                                        </Select>
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="Amount/भुगतान Rs." name="amount" rules={[{ required: true, message: 'Please enter amount' }]}>
                                        <InputNumber className="input_number_type" placeholder="Enter Amount Rs." prefix={<MoneyCollectOutlined />} />
                                    </Item>
                                </Col>



                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="Transaction/लेन-देन Date" name="transaction_date" >
                                        <DatePicker className="filter_dob" format="DD-MM-YYYY" placeholder="Select Tnx Date" prefix={<CalendarOutlined />} />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="Payment Entry/भुगतान प्रविष्टि Date" name="payment_entry_date" >
                                        <DatePicker className="filter_dob" format="DD-MM-YYYY" placeholder="Select Payment Entry Date" prefix={<CalendarOutlined />} />
                                    </Item>
                                </Col>


                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="URT No" name="utr_number">
                                        <Input placeholder="Enter URT No." />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="Transaction Id" name="transaction_id">
                                        <Input placeholder="Enter Transaction Id" />
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="Payment Status/भुगतान की स्थिति" name="payment_status" rules={[{ required: true, message: 'Please select payment status' }]} >
                                        <Select placeholder="Please select Payment Status">
                                            <Option value="Success">Success</Option>
                                            <Option value="Failed">Failed</Option>
                                            <Option value="Pending">Pending</Option>
                                        </Select>
                                    </Item>
                                </Col>

                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="Payment Verified Status/भुगतान सत्यापित स्थिति" name="payment_virify" rules={[{ required: true, message: 'Please select verified status' }]} >
                                        <Select placeholder="Please select Verified Status">
                                            <Option value="Verified">Verified</Option>
                                            <Option value="Failed">Failed</Option>
                                            <Option value="Pending">Pending</Option>
                                        </Select>
                                    </Item>
                                </Col>


                                <Col xs={24} sm={12} lg={8}>
                                    <Item label="Relation" name="received_paymment_type" >
                                        <Select placeholder="Please select Received Type">
                                            <Option value="Paytm">Paytm</Option>
                                            <Option value="PhonePe">PhonePe</Option>
                                            <Option value="Gpay">Gpay</Option>
                                            <Option value="Gpay">UPI</Option>
                                            <Option value="Bank Transfer">Bank Transfer</Option>
                                            <Option value="Cash">Cash</Option>
                                            <Option value="Other">Other</Option>
                                        </Select>
                                    </Item>
                                </Col>


                            </Row>
                            <Row>
                                <Col xs={24} sm={12} lg={8}>
                                    <Item>
                                        <Button type="primary" htmlType="submit">
                                            {isUpdateMode ? "Update Payment" : "Add Payment"}
                                        </Button>
                                    </Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <Modal width={1000}
                title="Payment Information"
                open={isModalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleModalClose}>
                        OK
                    </Button>,
                ]}
            >
                <div className="scrollable-modal-content">
                    {addedUserData && <UserModalContent userData={addedUserData} />}
                </div>

            </Modal>

            <Modal width={1000}
                title="User Details"
                open={modalViewVisible}
                onOk={handleViewModalClose}
                onCancel={handleViewModalClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleViewModalClose}>
                        OK
                    </Button>,
                ]}
            >
                <div className="scrollable-modal-content">
                    {addedUserData && <UserModalContent userData={addedUserData} />}
                </div>

            </Modal>

            

        </div>
    );
}

export default PaymentsForm;