import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Typography,
  notification,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber, TimePicker, TimePickerProps
} from "antd";

import axios from "axios";
import { UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

function StaffForm({ userId }) {


  // const { id } = useParams(); // Extract id from URL
  const history = useHistory();
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [txnDate, setTxnDate] = useState();
  const [timeInDate, setTimeInDate] = useState(null);
  const [timeOutDate, setTimeOutDate] = useState(null);


  useEffect(() => {
    // Check if id exists to determine if it's an update mode
    if (userId) {
      setIsUpdateMode(true);
      fetchRoleDetails();
    }
  }, [userId]);



  const fetchRoleDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/visitor/read/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        // setRoleData(response.data.result);
        const result = response.data.result;

        // Convert time strings to moment objects
        setTxnDate(response.data.result.visit_date ? response.data.result.visit_date : null) // Format the date if present)
        setTimeInDate(result.visit_time_in ? moment(result.visit_time_in, "HH:mm:ss") : null);
        setTimeOutDate(result.visit_time_out ? moment(result.visit_time_out, "HH:mm:ss") : null);

        // Populate the form with other data
        form.setFieldsValue({
          ...result,
          visit_time_in: result.visit_time_in ? moment(result.visit_time_in, "HH:mm:ss") : null,
          visit_time_out: result.visit_time_out ? moment(result.visit_time_out, "HH:mm:ss") : null,
        });


      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const onFinish = async (values) => {

    try {
      if (txnDate) {
        const formattedDate = moment(txnDate).format('YYYY-MM-DD');
        values.visit_date = formattedDate;
      } else {
        values.visit_date = '';
      }

      if (timeInDate) {
        const formattedDate = moment(timeInDate).format('h:mm:ss A');
        values.visit_time_in = formattedDate;
      } else {
        values.visit_time_in = '';
      }

      if (timeOutDate) {
        const formattedDate = moment(timeOutDate).format('h:mm:ss A');
        values.visit_time_out = formattedDate;
      } else {
        values.visit_time_out = '';
      }

      if (isUpdateMode) {
        const response = await axios.patch(`${baseUrl}/visitor/update/${userId}`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Record updated successfully!',
            placement: 'topRight'
          });
          history.push('/visitor');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      } else {
        const response = await axios.post(`${baseUrl}/visitor/create`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Visitor added successfully!',
            placement: 'topRight'
          });
          form.resetFields();
          history.push('/visitor');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };



  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setTxnDate(formattedDate)
    } else {
      setTxnDate(null);
    }
  };



  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={isUpdateMode ? "Update Visitor" : "Add Visitor"}
          >

            <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
              <Row gutter={[16, 16]}>


                <Col xs={24} sm={4} lg={4}>
                  <Item
                    label="Phone No"
                    name="phone_no"
                    rules={[{ required: true, message: 'Please Enter  Phone no' },
                    {
                      pattern: /^\d{10,12}$/,
                      message: 'Phone no must be between 10 and 12 digits!',
                    }
                    ]}
                  >

                    <Input type="number" placeholder="Enter Phone no" />
                  </Item>
                </Col>
                <Col xs={24} sm={4} lg={4}>
                  <Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please Enter Name' }]}
                  >
                    <Input placeholder="Enter Name" />
                  </Item>
                </Col>
                <Col xs={24} sm={6} lg={4}>


                  <Item
                    label="Visit Branch"
                    name="visit_branch"
                  >
                    <Select placeholder="Select Branch">
                      <Option value="अखिल भारतीय राजपुरोहित समाज विकास संस्थान">अखिल भारतीय राजपुरोहित समाज विकास संस्थान</Option>
                      <Option value="श्री खेतेश्वर गुरुकुल, मायलावास">श्री खेतेश्वर गुरुकुल, मायलावास</Option>
                      <Option value="श्री खेतेश्वर गुरुकुल, बिजरोल खेड़ा">श्री खेतेश्वर गुरुकुल, बिजरोल खेड़ा</Option>
                      <Option value="श्री खेतेश्वर गुरुकुल, सायला">श्री खेतेश्वर गुरुकुल, सायला</Option>
                      <Option value="श्री खेतेश्वर गुरुकुल, सवाई छोटी">श्री खेतेश्वर गुरुकुल, सवाई छोटी</Option>
                      <Option value="श्री खेतेश्वर भवन, हरिद्वार">श्री खेतेश्वर भवन, हरिद्वार</Option>
                    </Select>

                  </Item>
                </Col>

                <Col xs={24} sm={6} lg={4}>
                  <Item
                    label="Purpose of Visit"
                    name="purpose_visit"
                  >
                    <Input placeholder="Enter Purpose Visit" />
                  </Item>
                </Col>

                <Col xs={24} sm={4} lg={4}>
                  <Item label="Visit Date" >
                    <DatePicker onChange={handleDateChange} value={txnDate && txnDate != "0000-00-00" && moment(txnDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select DOB" prefix={<CalendarOutlined />} />

                  </Item>
                </Col>

                <Col xs={24} sm={12} lg={4}>
                  <Item label="Visit Time In" name="visit_time_in">
                    <TimePicker
                      use12Hours
                      format="h:mm A"
                      value={timeInDate} // Pass valid moment object or null
                      onChange={(time) => setTimeInDate(time)}
                    />
                  </Item>
                </Col>

                <Col xs={24} sm={12} lg={4}>
                  <Item label="Visit Time Out" name="visit_time_out">
                    <TimePicker
                      use12Hours
                      format="h:mm A"
                      value={timeOutDate} // Pass valid moment object or null
                      onChange={(time) => setTimeOutDate(time)}
                    />
                  </Item>
                </Col>



                <Col xs={24} sm={12} lg={8}>
                  <Item
                    label="Remark"
                    name="remark"
                  >
                    <Input placeholder="Enter Remark" />
                  </Item>
                </Col>

              </Row>
              <Row style={{ textAlign: 'right' }}>
                <Col xs={24} sm={24} lg={24}>
                  <Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: '10px' }} // Add space between buttons
                    >
                      {isUpdateMode ? "Update" : "Save"}
                    </Button>


                  </Item>
                </Col>
              </Row>

            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default StaffForm;
