import React from 'react';
import { Layout, Form, Input, Button, message, Divider, Row, Col, Card } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { baseUrl, secretKey } from '../config';
 
 import CryptoJS from 'crypto-js';
 
import logoBg from "../assets/images/brahmdhamtirth-logo.jpeg";

const { Header, Content } = Layout;

const FullMoonForm = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = async (values) => {
    axios.post(`${baseUrl}/purnima_form/create`, values)
      .then(response => {
        if (response.status === 200) {
          message.success('जय ब्रह्माजी री सा। आपका पूर्णिमा सूचना फ़ॉर्म सफलतापूर्वक भर दिया गया है।');
          form.resetFields();
          const id = response.data.data.user.id;
          const encryptedId = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();
 
           const encodedId = encodeURIComponent(encryptedId);
           history.push(`/purnima-member-confirm?id=${encodedId}`)
 
        } else {
          message.error(response.message);
        }
      })
      .catch(error => {
        message.error('There was an error saving the form data.');
      });
  };

  const onFinishFailed = (errorInfo) => {
    message.error('Please fill all required fields!');
  };

  return (
    <Layout style={{

      backgroundSize: 'cover',
      minHeight: '100vh'
    }}>

      <Header style={{ backgroundColor: 'rgba(251, 189, 70, 0.78)', textAlign: 'center', padding: '20px 10', height: "auto" }}>
        <h1 style={{ color: 'red', fontSize: '28px', fontWeight: 'bold', marginBottom: '10px' }}>
          जय गुरु महाराज री सा
        </h1>
        <p style={{ color: '#fff', fontWeight: 'bold', fontSize: '16px', margin: '0 0 10px', lineHeight: '1.6' }}>
          श्री खेतेश्वर ब्रह्मधाम तीर्थ, आसोतरा में पूर्णिमा पर्व पर पधारने वाले बंधु/भगिनी अपनी जानकारी इस पूर्णिमा फॉर्म में अवश्य भरे,
          जिससे आपको आगामी पूर्णिमा की जानकारी पूर्व में मिल सके एवं व्यवस्थाएँ आपके लिए अनुकूल बनायी जा सके।
        </p>
        <h2 style={{ color: '#fff', fontWeight: 'bold', fontSize: '16px', lineHeight: '1.5' }}>
          अखिल भारतीय राजपुरोहित समाज विकास संस्थान <br /> श्री खेतेश्वर ब्रह्मधाम तीर्थ, आसोतरा
        </h2>
      </Header>

      <Divider />

      {/* Content with improved styling and responsive form */}
      <Content style={{ padding: '20px', minHeight: '80vh' }}>
        <Row justify="center">
          <Col xs={24} sm={20} md={16} lg={12} xl={10}>
            <Card
              title="पूर्णिमा फॉर्म"
              bordered={false}
              style={{
                background: 'rgba(255, 255, 255, 0.8)', // Transparent background
                borderRadius: '10px',
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
                padding: '30px'
              }}
              headStyle={{
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                {/* सम्पर्क सूत्र / Phone No */}
                <Form.Item
                  label="सम्पर्क सूत्र"
                  name="phone_no"
                  rules={[
                    { required: true, message: 'कृपया सम्पर्क सूत्र दर्ज करें!' },
                    { pattern: /^[0-9]{10}$/, message: 'कृपया मान्य मोबाइल नंबर दर्ज करें!' }
                  ]}
                >
                  <Input placeholder="फोन नंबर दर्ज करें" />
                </Form.Item>

                {/* नाम / Name */}
                <Form.Item
                  label="नाम"
                  name="name"
                  rules={[{ required: true, message: 'कृपया नाम दर्ज करें!' }]}
                >
                  <Input placeholder="अपना नाम दर्ज करें" />
                </Form.Item>

                {/* पिता का नाम / Father's Name */}
                <Form.Item
                  label="पिता का नाम"
                  name="father_name"
                  rules={[{ required: true, message: 'कृपया पिता का नाम दर्ज करें!' }]}
                >
                  <Input placeholder="पिता का नाम दर्ज करें" />
                </Form.Item>

                {/* गाँव / Village */}
                <Form.Item
                  label="गाँव"
                  name="village"
                  rules={[{ required: true, message: 'कृपया गाँव का नाम दर्ज करें!' }]}
                >
                  <Input placeholder="गाँव का नाम दर्ज करें" />
                </Form.Item>

                {/* तहसील / Tehsil */}
                <Form.Item
                  label="तहसील"
                  name="tehsil"
                  rules={[{ required: true, message: 'कृपया तहसील दर्ज करें!' }]}
                >
                  <Input placeholder="तहसील दर्ज करें" />
                </Form.Item>

                {/* जिला / District */}
                <Form.Item
                  label="जिला"
                  name="district"
                  rules={[{ required: true, message: 'कृपया जिला दर्ज करें!' }]}
                >
                  <Input placeholder="जिला दर्ज करें" />
                </Form.Item>

                {/* व्यवसाय / Occupation */}
                <Form.Item
                  label="व्यवसाय"
                  name="occupation"
                  rules={[{ required: true, message: 'कृपया व्यवसाय दर्ज करें!' }]}
                >
                  <Input placeholder="व्यवसाय दर्ज करें" />
                </Form.Item>

                {/* व्यवसाय स्थान / Occupation District */}
                <Form.Item
                  label="व्यवसाय स्थान"
                  name="occ_district"
                  rules={[{ required: true, message: 'कृपया व्यवसाय स्थान दर्ज करें!' }]}
                >
                  <Input placeholder="व्यवसाय स्थान दर्ज करें" />
                </Form.Item>

                {/* प्रेरणा सोत्र / Inspiration Remark */}
                <Form.Item label="प्रेरणा सोत्र" name="inspiration_remark">
                  <Input.TextArea placeholder="प्रेरणा सोत्र दर्ज करें" />
                </Form.Item>

                {/* Submit Button */}
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    फॉर्म सबमिट करें
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default FullMoonForm;
