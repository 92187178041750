import React, { useEffect, useState } from "react";
import { Input, Form, DatePicker, Button, Row, Col, notification,Divider,Typography  } from "antd";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../../../config";

const { Title } = Typography;
const AddMessage = (data) => {
    // Explicitly create the form instance
    const [form] = Form.useForm();
    // const [record, setRecord] = useState({});
    // useEffect(() => {
    //     getLatestRecord()
    // }, []);

    useEffect(() => {
        console.log("record", data);
        form.setFieldsValue({
            purnima_msg: data.record.purnima_msg || "",
            purnima_date: data.record.purnima_date ? moment(data.record.purnima_date) : null,
            scheduledDate: data.record.purnima_date_schedule_before ? moment(data.record.purnima_date_schedule_before) : null,
        });
    }, [form, data]);

    

    const save = async () => {
        try {
            const values = await form.validateFields();
            handleSave({ ...data.record, ...values });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    // Purnima Message
    const handleSave = async (newMessage) => {
        try {
            console.log("newMessage", newMessage);
            // Call your API to submit the new message
            const response = await axios.post(`${baseUrl}/purnima-message-setting/add-or-update`, newMessage,  {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                }
            });
            if (response.data.status) {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
                // setRecord(response.data.result);
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }

            // Update the state with the new message
            // setMessages([...messages, response.data]);

            // Success notification
            // antdMessage.success("Message saved successfully!");
        } catch (error) {
            console.error("API call failed:", error);
            // antdMessage.error("Failed to save the message.");
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                message: data.record.message || "",
                date: data.record.date ? moment(data.record.date) : null,
                scheduledDate: data.record.scheduledDate ? moment(data.record.scheduledDate) : null,
            }}
            style={{
                border: "1px solid #d9d9d9", // Adds a border to the form
                padding: "16px", // Adds padding inside the form
                borderRadius: "8px", // Adds rounded corners
                backgroundColor: "#f9f9f9", // Optional: Adds a background color
            }}
        >
             <Title level={5}>Scheduled Message</Title>

            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item
                        label="Message"
                        name="purnima_msg"
                        rules={[{ required: true, message: "Message is required" }]}
                    > 
                       <Input />
                    </Form.Item>
                </Col>

              

                <Col span={4}>
                    <Form.Item
                     
                        label="Scheduled Date"
                        name="scheduledDate"
                        rules={[{ required: true, message: "Scheduled Date is required" }]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={4}> 
                <Button type="primary" onClick={save} style={{ marginTop: '25px' }}>
                    Save Message Scheduled
                </Button>
                </Col>
            
            </Row>

           
        </Form>
    );
};

export default AddMessage;
