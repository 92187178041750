import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Typography,
  notification,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Space,
  Upload
} from "antd";
import axios from "axios";
import { UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

function StaffForm({ userId }) {


  // const { id } = useParams(); // Extract id from URL
  const history = useHistory();
  const [form] = Form.useForm();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [txnDate, setTxnDate] = useState();
  const [staffList, setStaffList] = useState([]);
  const [meetingDate, setMeetingDate] = useState();
  const [startDate, setStartDate] = useState();
  const [status, setStatus] = useState('Pending');
  const [imageUrl, setImageUrl] = useState(null); // Store the image URL if already uploaded
  const [fileList, setFileList] = useState([]); // Store the image file list for Upload


  useEffect(() => {

    getStaffList();
    // Check if id exists to determine if it's an update mode
    if (userId) {
      setIsUpdateMode(true);
      fetchRoleDetails();
    }
  }, [userId]);



  const fetchRoleDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/work_managment/read/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) { 
        // setRoleData(response.data.result);
        form.setFieldsValue(response.data.result); // Populate form fields with fetched data
        setTxnDate(response.data.result.assigned_date ? response.data.result.assigned_date : null) // Format the date if present)
         setStartDate(response.data.result.date_completion ? response.data.result.date_completion : null) // Format the date if present)
        setStatus(response.data.result.status); // Populate status from fetched data

        if (response.data.result.file_document) {
          setImageUrl(response.data.result.file_document);
          setFileList([
            {
              uid: '-1',
              name: 'File',
              status: 'done',
              url: response.data.result.file_document,
            },
          ]);
        }


      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const onFinish = async (values) => {

    try {


      const formData = new FormData(); // Create a FormData object to handle files and form data

      // Format the dates
      if (txnDate) {
        const formattedDate = moment(txnDate).format('YYYY-MM-DD');
        formData.append('assigned_date', formattedDate);
      } else {
        formData.append('assigned_date', '');
      }

      if (startDate) {
        const formattedMeetingDate = moment(startDate).format('YYYY-MM-DD');
        formData.append('date_completion', formattedMeetingDate);
      } else {
        formData.append('date_completion', '');
      }
 

      // Append other form fields to formData
      formData.append('assigned_by', values.assigned_by);
      formData.append('description', values.description);
      formData.append('assigned_to', values.assigned_to);
      formData.append('remark_by_assignee', values.remark_by_assignee);
      formData.append('status_task', values.status);
      formData.append('remark_by_staff', values.remark_by_staff);

      console.log('values',values)
       

      // Append image (if any)
      if (values.image && values.image.length > 0 && values.image[0].originFileObj) {
        formData.append('file', values.image[0].originFileObj); // Append the image file
      }

      console.log('formData',formData) 
 

      if (isUpdateMode) {
        const response = await axios.patch(`${baseUrl}/work_managment/update/${userId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            'Content-Type': 'multipart/form-data' // Ensure multipart content type for FormData
          },
        });
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Record updated successfully!',
            placement: 'topRight'
          });
          history.push('/work_managment');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      } else {
        const response = await axios.post(`${baseUrl}/work_managment/create`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
            'Content-Type': 'multipart/form-data' // Ensure multipart content type for FormData
          },
        });


        
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Work Managment added successfully!',
            placement: 'topRight'
          });
          form.resetFields();
          history.push('/work_managment');
        } else {
          notification.info({
            message: 'Info',
            description: response.data.message,
            placement: 'topRight'
          });
        }
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };



  const handleDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setTxnDate(formattedDate)
    } else {
      setTxnDate(null);
    }
  };

  const handleMeetingDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setMeetingDate(formattedDate)
    } else {
      setMeetingDate(null);
    }
  };

  const handleStartDateChange = (date, dateString) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setStartDate(formattedDate)
    } else {
      setStartDate(null);
    }
  };

  async function getStaffList() {
    try {
      const response = await axios.get(`${baseUrl}/staff/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setStaffList(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRighft'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  // Handle status change
  const handleStatusChange = (value) => {
    setStatus(value);
  };



  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={isUpdateMode ? "Update Work Managment" : "Add Work Managment"}
          >
            <Form
              initialValues={{ status: "Pending" }}
              style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
              <Row gutter={[16, 16]}>


                <Col xs={24} sm={4} lg={4}>
                  <Item
                    label="Assigned By"
                    name="assigned_by"
                    rules={[{ required: true, message: 'Please Enter  Assigned By' }
                    ]}
                  >
                    <Input placeholder="Enter Assigned By" />
                  </Item>
                </Col>

                <Col xs={24} sm={4} lg={4}>
                  <Item label="Assigned Date" >
                    <DatePicker onChange={handleDateChange} value={txnDate && txnDate != "0000-00-00" && moment(txnDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select Assigned Date" prefix={<CalendarOutlined />} />

                  </Item>
                </Col>


                <Col xs={24} sm={8} lg={8}>
                  <Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: 'Please Enter Description' }]}
                  >
                    <TextArea placeholder="Enter Description" />
                  </Item>
                </Col>

                <Col xs={24} sm={12} lg={8}>
                  <Item
                    label="Work Document"
                    name="image"
                    valuePropName="file"
                    rules={[{ required: false, message: 'Please upload an image' }]}
                  >
                    <Upload
                      name="image"
                      listType="picture" 
                      beforeUpload={() => false}  
                      onChange={({ fileList: newFileList }) => {
                        setFileList(newFileList); // Update the file list when a new file is selected
                        form.setFieldsValue({ image: newFileList }); // Store the file list in form fields
                      }}
                      fileList={fileList} // Use fileList state to control the display
                      maxCount={1} // Restrict to a single image
                    >
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>



                  </Item>
                </Col>


                <Col xs={24} sm={6} lg={4}>

                  <Item className="custom-form-item" label="Assigned To" name="assigned_to">
                    <Select style={{ width: '100%' }} placeholder="Select Staff"  >
                      <Option value="">Select Staff</Option>
                      {staffList && staffList.map((item, i) => (
                        <Option value={item.id}>{item.firstName} {item.lastName}</Option>
                      ))}
                    </Select>
                  </Item>

                </Col>

                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Remark By Assignee"
                    name="remark_by_assignee"
                  >
                    <Input placeholder="Enter Remark By Assignee" />
                  </Item>
                </Col>



                <Col xs={24} sm={12} lg={4}>

                  <Item className="custom-form-item" label="Status" name="status"
                    rules={[{ required: true, message: 'Select Status' }]}
                  >
                    <Select value={status} onChange={handleStatusChange} className="status" placeholder="Select Account" defaultValue="Pending" >
                      <Option value="Pending">Pending</Option>
                      <Option value="InProcess">InProcess</Option>
                      <Option value="Completed">Completed</Option>
                    </Select>
                  </Item>
                </Col>

                {status === 'InProcess' && (
                  <Col xs={24} sm={4} lg={4}>
                    <Item label="Date Completion" >
                      <DatePicker onChange={handleStartDateChange} value={startDate && startDate != "0000-00-00" && moment(startDate)} className="filter_dob" format="DD-MM-YYYY" placeholder="Select Date Completion" prefix={<CalendarOutlined />} />

                    </Item>
                  </Col>
                )}

                <Col xs={24} sm={12} lg={4}>
                  <Item
                    label="Remark By Staff"
                    name="remark_by_staff"
                  >
                    <Input placeholder="Enter Remark By Staff" />
                  </Item>
                </Col>


              </Row>
              <Row style={{ textAlign: 'right' }}>
                <Col xs={24} sm={24} lg={24}>
                  <Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: '10px' }} // Add space between buttons
                    >
                      {isUpdateMode ? "Update" : "Save"}
                    </Button>


                  </Item>
                </Col>
              </Row>

            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default StaffForm;
