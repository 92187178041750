import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Row, Col, Spin,Card, Table, Button, Input, AutoComplete, notification, Modal, Select, Avatar, Typography, TableColumnsType, Checkbox, DatePicker, Drawer } from "antd";
import axios from "axios";
import { baseUrl } from "../../config";
import { DeleteOutlined, FileExcelOutlined, FilterOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import userDummy from "../../assets/images/dummy-user.png";
import { getPermissions, hasPermission } from '../../components/permissions';
import '../../assets/styles/custom_style.css';
import moment from 'moment';
import AddUserDetailsModel from '../sn_payments/AddUserDetailsModel';
import * as XLSX from 'xlsx';

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;

const createStyledTitle = (text) => (
  <span style={{ fontSize: '15px', color: '#0056b3' }}>{text}</span>
);

// Table columns definition
const columns = [

  {
    title: createStyledTitle("#"),
    dataIndex: "key",
    key: "key",
    fixed: 'left',
    width: 50,
  },
  {
    title: createStyledTitle("Name"),
    dataIndex: "name",
    key: "name",
    fixed: 'left',
    // sorter: true,
    width: 200,
  },
  {
    title: createStyledTitle("Phone no"),
    dataIndex: "phone_no",
    key: "phone_no",
    // sorter: true,

  },
  {
    title: createStyledTitle("Father Name"),
    dataIndex: "father_name",
    key: "father_name",
    // sorter: true,
  },
  {
    title: createStyledTitle("Monthly Share"),
    dataIndex: "announced_amount",
    key: "announced_amount",
    render: (text, user) => {
      let amountStyle = {};
      if (user.announced_amount >= 1000) {
        amountStyle = { color: 'green', fontWeight: 'bold' }; // High amount
      } else if (user.announced_amount >= 500) {
        amountStyle = { color: 'orange' }; // Medium amount
      } else {
        amountStyle = { color: 'red' }; // Low amount
      }

      return (
        <div className="author-info">
          <Title level={5} style={amountStyle}>
            {user.announced_amount ? 'Rs.' + user.announced_amount : '-'}
          </Title>
        </div>
      );
    },
  },

  {
    title: createStyledTitle("DOB Date"),
    dataIndex: "dob",
    key: "dob",
    // sorter: true,
  },
  {
    title: createStyledTitle("Joining Date"),
    dataIndex: "createdAt",
    key: "createdAt",
    // sorter: true,
  },
  {
    title: createStyledTitle("Street/House No"),
    dataIndex: "per_address",
    key: "per_address",
    // sorter: true,
  },
  {
    title: createStyledTitle("Village/Town"),
    dataIndex: "per_village",
    key: "per_village",
    // sorter: true,
  },
  {
    title: createStyledTitle("Post"),
    dataIndex: "per_post",
    key: "per_post",
    
  },
  {
    title: createStyledTitle("PinCode"),
    dataIndex: "per_pin_code",
    key: "per_pin_code",
    
  },
  {
    title: createStyledTitle("Tahsil/City"),
    dataIndex: "per_tahsil",
    key: "per_tahsil",
    // sorter: true,
  },
  {
    title: createStyledTitle("District"),
    dataIndex: "per_district",
    key: "per_district",
    // sorter: true,
  },
  {
    title: createStyledTitle("State"),
    dataIndex: "per_state",
    key: "per_state",
    // sorter: true,
  },


  {
    title: createStyledTitle("STATUS"),
    dataIndex: "status",
    key: "status",
    render: (text) => (
      <div className="status-info">
        <Title level={5} style={{ fontWeight: 'bold', color: text === 'Active' ? 'green' : 'red' }}>
          {text.toUpperCase()}
        </Title>
      </div>
    ),
  },
  {
    title: createStyledTitle("Action"),
    key: "action",
    dataIndex: "action",
    fixed: 'right',
  }
];

function UsersList() {

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const currentPageQuery = parseInt(queryParams.get('page')) || 1;
  const limitQuery = parseInt(queryParams.get('limit')) || 20;
  const [paymentList, setPaymentList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(currentPageQuery);
  const [limit, setLimit] = useState(limitQuery);
  const [loading, setLoading] = useState(false); // State to manage the loade
  const [userList, setUserList] = useState([]);


  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [staffList, setStaffList] = useState([]);


  const [filters, setFilters] = useState({
    name: queryParams.get('name') || '',
    email: queryParams.get('email') || '',
    phone: queryParams.get('phone') || '',
    father_name: queryParams.get('father_name') || '',
    gotra: queryParams.get('gotra') || '',
    kuldevi: queryParams.get('kuldevi') || '',
    kulrishi: queryParams.get('kulrishi') || '',
    alternate_phone_no: queryParams.get('alternate_phone_no') || '',
    alternate_phone_no2: queryParams.get('alternate_phone_no2') || '',
    dob: queryParams.get('dob') || '',
    joining_date: queryParams.get('joining_date') || '',
    min_monthly_amount: queryParams.get('min_monthly_amount') || '',
    max_monthly_amount: queryParams.get('max_monthly_amount') || '',
    status: queryParams.get('status') || '',
    maritial_status: queryParams.get('maritial_status') || '',

    per_village_en: queryParams.get('per_village_en') || '',
    per_post_en: queryParams.get('per_post_en') || '',
    per_tahsil_en: queryParams.get('per_tahsil_en') || '',
    per_pin_code_en: queryParams.get('per_pin_code_en') || '',
    per_district_en: queryParams.get('per_district_en') || '',
    per_state_en: queryParams.get('per_state_en') || '',
    per_country_en: queryParams.get('per_country_en') || '',

    txn_tem_address_en: queryParams.get('txn_tem_address_en') || '',
    tem_village_en: queryParams.get('tem_village_en') || '',
    tem_street_en: queryParams.get('tem_street_en') || '',
    tem_city_en: queryParams.get('tem_city_en') || '',
    tem_pin_code_en: queryParams.get('tem_pin_code_en') || '',
    tem_district_en: queryParams.get('tem_district_en') || '',
    tem_state_en: queryParams.get('tem_state_en') || '',
    tem_country_en: queryParams.get('tem_country_en') || '',

  });

  useEffect(() => {
    const { state } = history.location; // Get the state from history
    const restoredPage = state?.page || currentPage; // Restore page from state or use current page
    const restoredLimit = state?.limit || limit; // Restore limit from state or use current limit

    // Call getUserList with restored page and limit
    getUserList(restoredPage, restoredLimit, sortField, sortOrder, search, filters);
  }, [history.location]);

  async function getStaffList() {
    try {
      const response = await axios.get(`${baseUrl}/staff/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setStaffList(response.data.result);
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  async function getUserList(page = 1, limit = 20, sortField = null, sortOrder = null, search = '', filters = {}) {
    try {
      setCurrentPage(page);
      setLimit(limit);
      const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
      const searchParam = search ? `&search=${search}` : '';
      const filterParams = Object.keys(filters)
        .map(key => `${key}=${filters[key]}`)
        .join('&');
      const response = await axios.get(`${baseUrl}/users/list?page=${page}&limit=${limit}${sortParam}${searchParam}&${filterParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      if (response.data.status) {
        setUserList(response.data.result);
        setPagination({
          current: page,
          pageSize: limit,
          total: response.data.totalCount
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  async function handleDelete(id) {
    try {
      const response = await axios.delete(`${baseUrl}/users/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if (response.status === 200) {
        getUserList(pagination.current, pagination.pageSize, sortField, sortOrder, search, filters);
        notification.success({
          message: 'Success',
          description: 'User deleted successfully!',
          placement: 'topRight'
        });
      } else {
        notification.info({
          message: 'Info',
          description: response.data.message,
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("API error:", error);
      notification.info({
        message: 'Info',
        description: error.response?.data?.message,
        placement: 'topRight'
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id);
      },
    });
  };

 
  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const formattedOrder = order === 'ascend' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(formattedOrder);
    setFilters(filters);

    // Update both current page and limit in the history
    history.push(`?page=${current}&limit=${pageSize}`);

    // Fetch the updated payment list
    getUserList(current, pageSize, field, formattedOrder, search, filters);
  };

  const handleSearch = value => {
    setSearch(value);
    getUserList(currentPage, limit, sortField, sortOrder, value, filters);
  };

 

  const handleLimitChange = value => {
    setLimit(value);

    // Update URL with the new limit and reset page to 1
    const updatedQueryParams = new URLSearchParams(location.search);
    updatedQueryParams.set('limit', value);
    updatedQueryParams.set('page', 1); // Reset to first page whenever limit changes

    // Push new URL with updated query params
    history.push(`?${updatedQueryParams.toString()}`);

    // Call the API to fetch data with updated limit
    getUserList(1, value);  // Assuming your API call accepts page and limit
  };

  const debouncedGetUserList = useCallback(debounce((page, limit, sortField, sortOrder, search, filters) => {
    getUserList(page, limit, sortField, sortOrder, search, filters);
  }, 300), []);



  // useEffect(() => {
  //   const filterKeys = Object.keys(filters);

  //   // Check if any filter other than 'createdBy' has a length of 3 or more
  //   const anyFilterHasMinLength = filterKeys.some(
  //     key => key !== 'createdBy' && filters[key].length >= 3
  //   );

  //   // Check if all filters are either empty or specifically 'createdBy'
  //   const allFiltersEmptyOrCreatedBy = filterKeys.every(
  //     key => key === 'createdBy' || !filters[key]
  //   );

  //   console.log('Filters:', filters);
  //   console.log('Any Filter Has Min Length:', anyFilterHasMinLength);
  //   console.log('All Filters Empty Or CreatedBy:', allFiltersEmptyOrCreatedBy);

  //   if (anyFilterHasMinLength || allFiltersEmptyOrCreatedBy) {
  //     console.log('Calling debouncedGetUserList');
  //     debouncedGetUserList(currentPage, limit, sortField, sortOrder, search, filters);
  //   }
  // }, [filters, currentPage, limit, sortField, sortOrder, search]);

  // const handleFilterChange = (field, value) => {
  //   setFilters({
  //     ...filters,
  //     [field]: value
  //   });
  // };


  const handleFilterChange2 = (field, value) => {
    const newFilters = {
      ...filters,
      [field]: value
    };
    setFilters(newFilters);

    // Update URL with new filters
    const updatedQueryParams = new URLSearchParams(location.search);
    updatedQueryParams.set('page', 1); // Reset to first page when filters change
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key]) {
        updatedQueryParams.set(key, newFilters[key]);
      } else {
        updatedQueryParams.delete(key); // Remove empty filters from the URL
      }
    });
    history.push(`?${updatedQueryParams.toString()}`);

    // Trigger a new API call
    getUserList(1, limit, newFilters);
  };

  const handleFilterChange = (field, value) => {
    const newFilters = {
      ...filters,
      [field]: value
    };
    setFilters(newFilters);

    // Optionally, update the URL without triggering a search immediately
    const updatedQueryParams = new URLSearchParams(location.search);
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key]) {
        updatedQueryParams.set(key, newFilters[key]);
      } else {
        updatedQueryParams.delete(key); // Remove empty filters from the URL
      }
    });
    history.push(`?${updatedQueryParams.toString()}`);
  };





  const [showUserFilter, setShowUserFilter] = useState(true);
  const [showPermanentAddress, setShowPermanentAddress] = useState(false);
  const [showTemporaryAddress, setShowTemporaryAddress] = useState(false);

  const handleUserFilterCheckboxChange = (e) => {
    setShowUserFilter(e.target.checked);
    if (staffList.length === 0) {
      getStaffList();
    }
  };


  const handleView = (id) => {
    history.push({
      pathname: `/users/view/${id}`,
      state: { page: currentPage, limit: limit } // Pass both page and limit
    });
  };

  const handlePermanentAddressCheckboxChange = (e) => {
    setShowPermanentAddress(e.target.checked);
  };

  const handleTemporaryAddressCheckboxChange = (e) => {
    setShowTemporaryAddress(e.target.checked);
  };

  //For show image on model larges
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const showModal = (image) => {
    setCurrentImage(image || userDummy);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const [AddUserDetailsFormModel, setAddUserDetailsModel] = useState(false);
  const [userBasicDetail, setuserBasicDetail] = useState({});  // state to store the selected file

  const [AddEditUserDetailsForm, setAddEditUserDetailsForm] = useState(null);


  const AddUserDetails = async (userId) => {

    setAddUserDetailsModel(true)

    const response = await axios.post(
      `${baseUrl}/payments/EditUserDetail`,
      { userId: userId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      }
    );
    if (response.status === 200 && response.data.exists) {

      setAddEditUserDetailsForm(response.data.details);
      setuserBasicDetail({})



    } else {
      // message.success("This is a new user. Please add their payment details so the system can create them automatically.");

    }




  }
  const AddUserDetailsClose = () => {
    setAddUserDetailsModel(false)
  }

  // Get Suggestion Values
  const [optionSuggestion, setOptionSuggestion] = useState([]);
  const getPanelValue = async (searchText, columnName, tableName) => {
    if (searchText.length < 3) {
      setOptionSuggestion([]);
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}/payments/getSuggestionValue/`, {
        params: { q: searchText, column: columnName, table: tableName },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
        },
      });
      if (response.status === 200) {
        setOptionSuggestion(response.data.result);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rowClassName = (record) => {
    return record.status === 'Inactive' ? 'inactive-row' : '';
  };

  const exportToExcel = async () => {
    setLoading(true);
    const sortParam = sortField && sortOrder ? `&sortField=${sortField}&sortOrder=${sortOrder}` : '';
    const searchParam = search ? `&search=${search}` : '';
    const filterParams = Object.keys(filters)
      .map(key => `${key}=${filters[key]}`)
      .join('&');
    const response = await axios.get(`${baseUrl}/users/list?page=${1}&limit=all${sortParam}${searchParam}&${filterParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }
    });
    if (response.data.status) {
      setLoading(false);
      const formattedData = response.data.result.map((user, index) => ({
        "Sr.No": index + 1,
        "Phone no": user.phone_no,
        "Name": user.name,
        "Father Name": user.father_name,
        "Monthly Share": user.announced_amount,
        "DOB Date": user.dob ? moment(user.dob).format('DD-MM-YYYY') : '-',
        "Joining Date": user.joining_date ? moment(user.joining_date).format('DD-MM-YYYY') : '-',
        "Street/House No": user.per_address,
        "Village/Town": user.per_village,
        "Post": user.per_post,
        "Pin-Code": user.per_pin_code,
        "Tahsil/City": user.per_tahsil,
        "District": user.per_district,
        "State": user.per_state,
        "Status": user.status,
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "User List");
      XLSX.writeFile(workbook, "User-List.xlsx");
    } else {
      setLoading(false);
      notification.info({
        message: 'Info',
        description: response.data.message,
        placement: 'topRight'
      });
    }
  };


  //For filter set
  const handleSearchReport = () => {
    // Trigger API call with current filters
    getUserList(1, limit, sortField, sortOrder, search, filters);
  };

  // Reset filters and update URL
  const handleResetFilters = () => {
    const resetFilters = {
      name: '',
      email: '',
      phone: '', 
      father_name: '', 
      gotra: '', 
      kuldevi: '', 
      kulrishi: '', 
      alternate_phone_no: '', 
      alternate_phone_no2: '', 
      dob: '', 
      joining_date: '', 
      min_monthly_amount: '', 
      max_monthly_amount: '', 
      status: '', 
      maritial_status: '', 
      per_village_en: '', 
      per_post_en: '', 
      per_tahsil_en: '', 
      per_pin_code_en: '', 
      per_district_en: '', 
      per_state_en: '', 
      per_country_en:'', 

      txn_tem_address_en: '', 
      tem_village_en: '', 
      tem_street_en: '', 
      tem_city_en:'', 
      tem_pin_code_en: '', 
      tem_district_en: '', 
      tem_state_en: '', 
      tem_country_en: '' 

    };

    setFilters(resetFilters);

    // Update URL with empty filters
    const updatedQueryParams = new URLSearchParams(location.search);
    Object.keys(resetFilters).forEach(key => {
      updatedQueryParams.delete(key); // Remove all filters from URL
    });
    history.push(`?${updatedQueryParams.toString()}`);

    // Optionally trigger a reset search
    getUserList(1, limit, sortField, sortOrder, search, resetFilters);
  };

  return (

    
    <>

      {loading && (
        <div className="full-page-loader">
          <Spin size="large" />
        </div>
      )}
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="User List"
            extra={
              <div style={{ display: 'flex', gap: '10px' }}>
                {hasPermission('createPermission', 'Create User') && (
                  <Link
                    className="custom-btn"
                    to="/payments/add_user_payment"
                    style={{
                      display: 'inline-block',
                      padding: '10px 15px',
                      fontSize: '14px',
                      lineHeight: '22px',
                      borderRadius: '5px',
                      background: '#1890ff',
                      color: '#fff',
                      textAlign: 'center',
                    }}
                  >
                    Add User
                  </Link>
                )}
                <Button
                  type="default"
                  icon={<FileExcelOutlined />}
                  onClick={exportToExcel}
                  style={{
                    padding: '4px 15px',
                    fontSize: '14px',
                    lineHeight: '22px',
                    borderRadius: '5px',
                  }}
                >
                  Excel Download
                </Button>
              </div>
            }
          >

            <div className="table-responsive">
              <Table
                columns={columns}
                rowClassName={rowClassName}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
                title={() => (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>

                        <FilterOutlined /> <Checkbox onChange={handleUserFilterCheckboxChange}><Title style={{ fontSize: 13 }} level={5}>User Filter</Title></Checkbox>
                      </Col>
                      {showUserFilter && (
                        <>
                          <Col span={4}> <Input placeholder="Phone" value={filters.phone} onChange={e => handleFilterChange('phone', e.target.value)} /></Col>

                          <Col span={4}> <Input placeholder="Name/नाम" value={filters.name} onChange={e => handleFilterChange('name', e.target.value)} /></Col>
                          <Col span={4}><Input placeholder="Email" value={filters.email} onChange={e => handleFilterChange('email', e.target.value)} /></Col>
                          <Col span={4}><Input placeholder="Father Name/पिता का नाम" value={filters.father_name} onChange={e => handleFilterChange('father_name', e.target.value)} /></Col>

                          <Col span={4}>
                            <Input placeholder="Gotra" value={filters.gotra} onChange={e => handleFilterChange('gotra', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input placeholder="Kuldevi" value={filters.kuldevi} onChange={e => handleFilterChange('kuldevi', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input placeholder="Kulrishi" value={filters.kulrishi} onChange={e => handleFilterChange('kulrishi', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input placeholder="Alt 1 No." value={filters.alternate_phone_no} onChange={e => handleFilterChange('alternate_phone_no', e.target.value)}
                            />
                          </Col>
                          <Col span={4}>
                            <Input placeholder="Alt 2 No." value={filters.alternate_phone_no2} onChange={e => handleFilterChange('alternate_phone_no2', e.target.value)}
                            />
                          </Col>

                          <Col span={4}>

                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.dob ? moment(filters.dob, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('dob', dateString)} placeholder="Date of Birth"
                            />


                          </Col>

                          <Col span={4}>
                            <DatePicker className="filter_dob CustomInputSet"
                              format="DD-MM-YYYY" value={filters.joining_date ? moment(filters.joining_date, 'DD-MM-YYYY') : null}
                              onChange={(date, dateString) => handleFilterChange('joining_date', dateString)} placeholder="Joining Date"
                            />
                          </Col>

                          <Col span={4} >

                            <Select className="CustomInputSet" style={{ width: '100%' }} placeholder="Maritial" value={filters.maritial_status} onChange={value => handleFilterChange('maritial_status', value)}>
                              <Option value="">Select</Option>
                              <Option value="Married">Married</Option>
                              <Option value="UnMarried">UnMarried</Option>
                              <Option value="Widowed">Widowed</Option>
                            </Select>


                          </Col>

                          <Col span={4} ><Select style={{ width: '100%' }} placeholder="Status" value={filters.status} onChange={value => handleFilterChange('status', value)}>
                            <Option value="">Select</Option>
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                          </Select>
                          </Col>
                          <Col span={8}>
                            <div className="amount-range-input">
                              <Input
                                placeholder="Min Monthly Share"
                                value={filters.min_monthly_amount}
                                onChange={(e) => handleFilterChange('min_monthly_amount', e.target.value)}
                              />
                              <span className="range-separator">-</span>

                              <Input
                                placeholder="Max Monthly Share"
                                value={filters.max_monthly_amount}
                                onChange={(e) => handleFilterChange('max_monthly_amount', e.target.value)}
                              />

                            </div>
                          </Col>




                          <Col xs={24}>

                            <FilterOutlined /> <Checkbox onChange={handlePermanentAddressCheckboxChange}> <Title style={{ fontSize: 13 }} level={5}>Permanent Address/स्थाई पता</Title></Checkbox>
                          </Col>
                          {showPermanentAddress && (
                            <>

                              <Col span={4}> <Input placeholder="Street/Building/House No" value={filters.per_address_en} onChange={e => handleFilterChange('per_address_en', e.target.value)} /></Col>
                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="Village/Town/Ward"
                                    options={optionSuggestion}
                                    value={filters.per_village_en}
                                    onChange={(value) => handleFilterChange('per_village_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'per_village_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="Post"
                                    options={optionSuggestion}
                                    value={filters.per_post_en}
                                    onChange={(value) => handleFilterChange('per_post_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'per_post_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="Tahsil"
                                    options={optionSuggestion}
                                    value={filters.per_tahsil_en}
                                    onChange={(value) => handleFilterChange('per_tahsil_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'per_tahsil_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="Zip Code"
                                    options={optionSuggestion}
                                    value={filters.per_pin_code_en}
                                    onChange={(value) => handleFilterChange('per_pin_code_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'per_pin_code_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="District"
                                    options={optionSuggestion}
                                    value={filters.per_district_en}
                                    onChange={(value) => handleFilterChange('per_district_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'per_district_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="State"
                                    options={optionSuggestion}
                                    value={filters.per_state_en}
                                    onChange={(value) => handleFilterChange('per_state_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'per_state_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="Country"
                                    options={optionSuggestion}
                                    value={filters.per_country_en}
                                    onChange={(value) => handleFilterChange('per_country_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'per_country_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>


                            </>
                          )}

                          <Col xs={24}>

                            <FilterOutlined />  <Checkbox onChange={handleTemporaryAddressCheckboxChange}><Title style={{ fontSize: 13 }} level={5}>Temporary Address/अस्थाई पता</Title></Checkbox>
                          </Col>
                          {showTemporaryAddress && (
                            <>

                              <Col span={4}> <Input placeholder="House/Flat/Building" value={filters.tem_address_en} onChange={e => handleFilterChange('tem_address_en', e.target.value)} /></Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="Village/Town/Ward"
                                    options={optionSuggestion}
                                    value={filters.tem_village_en}
                                    onChange={(value) => handleFilterChange('tem_village_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'tem_village_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}> <Input placeholder="Town/City" value={filters.tem_street_en} onChange={e => handleFilterChange('tem_street_en', e.target.value)} /></Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="City"
                                    options={optionSuggestion}
                                    value={filters.tem_city_en}
                                    onChange={(value) => handleFilterChange('tem_city_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'tem_city_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>


                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="Zip Code"
                                    options={optionSuggestion}
                                    value={filters.tem_pin_code_en}
                                    onChange={(value) => handleFilterChange('tem_pin_code_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'tem_pin_code_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="District"
                                    options={optionSuggestion}
                                    value={filters.tem_district_en}
                                    onChange={(value) => handleFilterChange('tem_district_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'tem_district_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="State"
                                    options={optionSuggestion}
                                    value={filters.tem_state_en}
                                    onChange={(value) => handleFilterChange('tem_state_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'tem_state_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>

                              <Col span={4}>
                                <div className="auto-complete-container">
                                  <AutoComplete
                                    placeholder="Country"
                                    options={optionSuggestion}
                                    value={filters.tem_country_en}
                                    onChange={(value) => handleFilterChange('tem_country_en', value)}
                                    onSearch={(text) => getPanelValue(text, 'tem_country_en', 'sn_user_address')}
                                  />
                                </div>
                              </Col>



                            </>
                          )}
                        </>
                      )}

                      <Row gutter={16} style={{ marginBottom: '16px', textAlign: 'right' }}>
                        <div className="amount-range-input">
                          <Col span={24} style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                            <Button type="primary" icon={<SearchOutlined />} onClick={handleSearchReport} >
                              Search 
                            </Button>
                            <Button type="default" onClick={handleResetFilters}>
                              Reset Filters
                            </Button>

                          </Col>
                        </div>
                      </Row>

                      <Col xs={24}>
                        <span style={{ marginRight: 10 }}>Records per page:</span>
                        <Select value={limit} onChange={handleLimitChange}>
                          <Option value={20}>20</Option>
                          <Option value={40}>40</Option>
                          <Option value={60}>60</Option>
                          <Option value={80}>80</Option>
                          <Option value={100}>100</Option>
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )}


                dataSource={userList.map((user, index) => ({
                  key: (limit * (currentPage - 1)) + index + 1,
                  name: (
                    <>
                      <Avatar.Group>
                        <Avatar
                          className="shape-avatar"
                          shape="square"
                          size={40}
                          src={user.profile_img ? user.profile_img : userDummy}
                          icon={!user.profile_img && <UserOutlined />}
                          onClick={() => showModal(user.profile_img ? user.profile_img : userDummy)}
                          style={{ cursor: 'pointer' }}
                        />
                        <div className="avatar-info">
                          <Title level={5}>
                            <Link to={`/users/view/${user.user_id}`}  >
                              {user.name}
                            </Link>
                          </Title>
                          <p>{user.email_address}</p>
                        </div>
                      </Avatar.Group>{" "}
                    </>
                  ),
                  phone_no: (
                    <>
                      <div className="author-info">
                        <Title level={5} style={{ color: '#1a73e8', backgroundColor: '#e8f0fe', padding: '4px 8px', borderRadius: '4px' }}>
                          {user.phone_no}
                        </Title>
                        <p>{user.alternate_phone_no}</p>
                      </div>
                    </>
                  ),
                  father_name: (
                    <>
                      <div className="author-info">
                        <Title level={5}> {user.relation}</Title>
                        <p>
                          {user.father_name}
                        </p>
                      </div>
                    </>
                  ),
                  announced_amount: user.announced_amount,
                  dob: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.dob ? moment(user.dob).format('DD/MM/YYYY') : '-'}  </Title>

                      </div>
                    </>
                  ),
                  createdAt: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.joining_date ? moment(user.joining_date).format('DD MMMM YYYY') : '-'}</Title>

                      </div>
                    </>
                  ),

                  per_address: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.per_address ? user.per_address : ''}</Title>
                        <p>{user.tem_address ? user.tem_address : ''}</p>
                      </div>
                    </>
                  ),
                  per_village: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.per_village}</Title>
                        <p>{user.tem_street}</p>
                      </div>
                    </>
                  ),
                  per_post: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.per_post}</Title>
                        <p>{user.tem_city}</p>
                      </div>
                    </>
                  ),
                  per_tahsil: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.per_tahsil}</Title>
                        <p>{user.tem_city}</p>
                      </div>
                    </>
                  ),
                  per_pin_code: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.per_pin_code}</Title>
                        <p>{user.tem_pin_code}</p>
                      </div>
                    </>
                  ),
                  per_district: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.per_district}</Title>
                        <p>{user.tem_district}</p>
                      </div>
                    </>
                  ),
                  per_state: (
                    <>
                      <div className="author-info">
                        <Title level={5}>{user.per_state}</Title>
                        <p>{user.tem_state}</p>
                      </div>
                    </>
                  ),

                  status: user.status,
                  action: (
                    <div className="button-container">
                      {hasPermission('editPermission', 'Edit User') && (
                        <button onClick={() => AddUserDetails(user.user_id)} className="update-btn">
                          <EditOutlined />
                        </button>
                      )}
                      {hasPermission('readPermission', 'Read User') && (
                        <Link onClick={() => handleView(user.user_id)} className="update-btn">
                          <EyeOutlined />
                        </Link>
                      )}
                      {hasPermission('deletePermission', 'Delete User') && (
                        <button onClick={() => showDeleteConfirm(user.user_id)} className="delete-btn">
                          <DeleteOutlined />
                        </button>
                      )}
                    </div>
                  )
                }))}
                pagination={{
                  ...pagination,
                  showTotal: total => `Total ${total} records`,
                  style: { marginRight: 20 }
                }}
                onChange={handleTableChange}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          src={currentImage}
          alt="Profile"
          style={{ width: '100%' }}
        />
      </Modal>

      <Drawer
        title="User Details"
        width={1000}
        onClose={AddUserDetailsClose}
        open={AddUserDetailsFormModel}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        maskClosable={false}
      >


        <AddUserDetailsModel userData={AddEditUserDetailsForm} userBasicDetail={userBasicDetail} primaryId='' onIdChange='' />

      </Drawer>



    </div>
    </>
  );
}


export default UsersList;
