// UserModalContent.js
import { useState, useEffect } from "react";
import { baseUrl } from "../../config";
import moment from 'moment';

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message, notification, Select, Typography, Form
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";

import BgProfile from "../../assets/images/bg-profile.jpg";
import profilavatar from "../../assets/images/face-1.jpg";

const UserModalContent = ({ userData }) => {

  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={userData.profile_img} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{userData.name_eng}</h4>
                  <p>{userData.phone_no}</p>
                </div>
              </Avatar.Group>
            </Col>

          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>


        <Col span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Descriptions>
              <Descriptions.Item label="Name/नाम" span={3}>
             
              {userData.name_eng && userData.name_hn ? `${userData.name_eng} / ${userData.name_hn}` :  userData.name_eng || userData.name_hn || '' }
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
              {userData.phone_no}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
              {userData.email_address}
              </Descriptions.Item>
 
              <Descriptions.Item label="Relation/संबंध" span={3}>
              
              {userData.relation_en && userData.relation_hn ? `${userData.relation_en} / ${userData.relation_hn}` :  userData.relation_en || userData.relation_hn || '' }
              </Descriptions.Item>

              <Descriptions.Item label="Father Name/पिता का नाम" span={3}>
              {userData.father_name_en} / {userData.father_name_hn}
              {userData.father_name_en && userData.father_name_hn ? `${userData.father_name_en} / ${userData.father_name_hn}` :  userData.father_name_en || userData.father_name_hn || '' }
              </Descriptions.Item>

              <Descriptions.Item label="DOB/जन्मदिन की तारीख" span={3}>
             
              {userData.dob ? moment(userData.dob).format('DD-MM-YYYY') : 'Date of birth not provided'}

              </Descriptions.Item>

              <Descriptions.Item label="Gotra/गोत्र" span={3}>
              {userData.gotra}
              </Descriptions.Item>

              <Descriptions.Item label="Alternate Phone No" span={3}>
              {userData.alternate_phone_no}
              </Descriptions.Item>

              <Descriptions.Item label="Occupation/पेशा" span={3}>
              {userData.occupation}
              </Descriptions.Item>

              <Descriptions.Item label="Occupation Address" span={3}>
              {userData.occ_address}
              </Descriptions.Item>

           


              <Descriptions.Item label="Social" span={3}>
                <a href={userData.twitter_url} className="mx-5 px-5">
                  {<TwitterOutlined />}
                </a>
                <a href={userData.facebook_url} className="mx-5 px-5">
                  {<FacebookOutlined style={{ color: "#344e86" }} />}
                </a>
                <a href={userData.instagram_url} className="mx-5 px-5">
                  {<InstagramOutlined style={{ color: "#e1306c" }} />}
                </a>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>



        <Col span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Permanent Address/स्थाई पता</h6>}
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Descriptions>
              <Descriptions.Item label="Street/Building/House No." span={3}>
              
              {userData.per_address_en && userData.per_address_hn ? `${userData.per_address_en} / ${userData.per_address_hn}` :  userData.per_address_en || userData.per_address_hn || '' }

              </Descriptions.Item>
              <Descriptions.Item label="Village/गांव" span={3}>
                
              {userData.per_village_en && userData.per_address_hn ? `${userData.per_village_en} / ${userData.per_village_hn}` :  userData.per_village_en || userData.per_village_hn || '' }
 
              </Descriptions.Item>
              <Descriptions.Item label="Post/पोस्ट" span={3}>
            
              {userData.per_post_en && userData.per_post_hn ? `${userData.per_post_en} / ${userData.per_post_hn}` :  userData.per_post_en || userData.per_post_hn || '' }
              </Descriptions.Item>
              <Descriptions.Item label="Tahsil/तहसील" span={3}>
              
              {userData.per_tahsil_en && userData.per_tahsil_hn ? `${userData.per_tahsil_en} / ${userData.per_tahsil_hn}` :  userData.per_tahsil_en || userData.per_tahsil_hn || '' }
              </Descriptions.Item>
              <Descriptions.Item label="Pin Code/पिनकोड" span={3}>
             
              {userData.per_pin_code_en && userData.per_pin_code_hn ? `${userData.per_pin_code_en} / ${userData.per_pin_code_hn}` :  userData.per_pin_code_en || userData.per_pin_code_hn || '' }
              </Descriptions.Item>
              <Descriptions.Item label="District/जिला" span={3}>
              
              {userData.per_district_en && userData.per_district_hn ? `${userData.per_district_en} / ${userData.per_district_hn}` :  userData.per_district_en || userData.per_district_hn || '' }
              </Descriptions.Item>
              <Descriptions.Item label="State/राज्य" span={3}>
             
              {userData.per_state_en && userData.per_state_hn ? `${userData.per_state_en} / ${userData.per_state_hn}` :  userData.per_state_en || userData.per_state_hn || '' }
              </Descriptions.Item>
            
            </Descriptions>
          </Card>
        </Col>





        <Col span={24} md={24} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Temporary Address/अस्थाई पता </h6>}
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
                 <Descriptions>
              <Descriptions.Item label="House No/Flat No/Building Name" span={3}>
              
              {userData.tem_address_en && userData.tem_address_hn ? `${userData.tem_address_en} / ${userData.tem_address_hn}` :  userData.tem_address_en || userData.tem_address_hn || '' }
              </Descriptions.Item>
              <Descriptions.Item label="Town/City/Ward/नगर/कस्बा/वार्ड" span={3}>
             
              {userData.tem_street_en && userData.tem_street_hn ? `${userData.tem_street_en} / ${userData.tem_street_hn}` :  userData.tem_street_en || userData.tem_street_hn || '' }
              </Descriptions.Item>
              <Descriptions.Item label="City/सिटी" span={3}>
             
              {userData.tem_city_en && userData.tem_city_hn ? `${userData.tem_city_en} / ${userData.tem_city_hn}` :  userData.tem_city_en || userData.tem_city_hn || '' }
              </Descriptions.Item>
              <Descriptions.Item label="District/जिला" span={3}>
           
              {userData.tem_district_en && userData.tem_district_hn ? `${userData.tem_district_en} / ${userData.tem_district_hn}` :  userData.tem_district_en || userData.tem_district_hn || '' }
              </Descriptions.Item>
              <Descriptions.Item label="Sambhag/संभाग" span={3}>
             
              {userData.tem_sambhag_en && userData.tem_sambhag_hn ? `${userData.tem_sambhag_en} / ${userData.tem_sambhag_hn}` :  userData.tem_sambhag_en || userData.tem_sambhag_hn || '' }
              </Descriptions.Item>

              <Descriptions.Item label="State/राज्य" span={3}>
              
              {userData.tem_state_en && userData.tem_state_hn ? `${userData.tem_state_en} / ${userData.tem_state_hn}` :  userData.tem_state_en || userData.tem_state_hn || '' }
              </Descriptions.Item>

              <Descriptions.Item label="Country/देश" span={3}>
         
              {userData.tem_country_en && userData.tem_country_hn ? `${userData.tem_country_en} / ${userData.tem_country_hn}` :  userData.tem_country_en || userData.tem_country_hn || '' }
              </Descriptions.Item>
               
              
            </Descriptions>
          </Card>
        </Col>


      </Row>

    </>
  );
};

export default UserModalContent;