import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    Button,
    Typography,
    notification,
    Form,
    Input,
    Select,
    DatePicker,
    InputNumber,
    Space
} from "antd";
import axios from "axios";
import { UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, useParams } from "react-router-dom";
import { baseUrl } from "../../config";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

function StaffForm({userId}) {

   
   // const { id } = useParams(); // Extract id from URL
    const history = useHistory();
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);  
    const [txnDate, setTxnDate] = useState();
  

    useEffect(() => {
        // Check if id exists to determine if it's an update mode
        if (userId) {
            setIsUpdateMode(true);
            fetchRoleDetails();
        }
    }, [userId]);



    const fetchRoleDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/village/read/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                // setRoleData(response.data.result);
                form.setFieldsValue(response.data.result); // Populate form fields with fetched data
                setTxnDate(response.data.result.dob ? response.data.result.dob : null) // Format the date if present)
                
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {

        try {
            if (txnDate) {
                const formattedDate = moment(txnDate).format('YYYY-MM-DD');
                values.dob = formattedDate;
            } else {
                values.dob = '';
            }

            if (isUpdateMode) {
                const response = await axios.patch(`${baseUrl}/village/update/${userId}`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Record updated successfully!',
                        placement: 'topRight'
                    });
                    history.push('/village');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            } else {
                const response = await axios.post(`${baseUrl}/village/create`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                    },
                });
                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: 'Village added successfully!',
                        placement: 'topRight'
                    });
                    form.resetFields();
                    history.push('/village');
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

  

    const handleDateChange = (date, dateString) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setTxnDate(formattedDate)
        } else {
            setTxnDate(null);
        }
    };



    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title={isUpdateMode ? "Update Village" : "Add Village"}
                    >
                        <Form style={{ "padding": "20px" }} form={form} onFinish={onFinish} layout="vertical">
                            <Row gutter={[16, 16]}>


                                 {/* Hindi Fields */}
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="गाँव नाम " name="name_hn"  rules={[{ required: true, message: 'Please Enter Name' }]}>
                            <Input placeholder="नाम दर्ज करें " />
                          </Item>
                        </Col>
                       
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="पोस्ट " name="post_hn">
                            <Input placeholder="पोस्ट दर्ज करें " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="तहसील " name="tehsil_hn">
                            <Input placeholder="तहसील दर्ज करें " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="पिनकोड " name="pincode_hn">
                            <Input placeholder="पिनकोड दर्ज करें " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="जिला " name="district_hn">
                            <Input placeholder="जिला दर्ज करें " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="राज्य " name="state_hn">
                            <Input placeholder="राज्य दर्ज करें " />
                          </Item>
                        </Col>

                        
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="Village Name " name="name_en">
                            <Input placeholder="Enter Village Name " />
                          </Item>
                        </Col>
                        
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="Post " name="post_en">
                            <Input placeholder="Enter Post " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="Tehsil " name="tehsil_en">
                            <Input placeholder="Enter Tehsil " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="Pincode " name="pincode_en">
                            <Input placeholder="Enter Pincode " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="District " name="district_en">
                            <Input placeholder="Enter District " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={4}>
                          <Item label="State " name="state_en">
                            <Input placeholder="Enter State " />
                          </Item>
                        </Col>
                        <Col xs={24} sm={6} lg={8}>
                          <Item label="History/Remark " name="remark">
                            <Input placeholder="Enter History/Remark " />
                          </Item>
                        </Col>

                            </Row>
                            <Row style={{ textAlign: 'right' }}>
                                <Col xs={24} sm={24} lg={24}>
                                    <Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ marginRight: '10px' }} // Add space between buttons
                                        >
                                            {isUpdateMode ? "Update" : "Save"}
                                        </Button>

                                        
                                    </Item>
                                </Col>
                            </Row>

                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default StaffForm;
