import { useState, useEffect } from "react";
import { baseUrl } from "../../config";
import axios from "axios";
import { Row, Col, Button, Space, Upload, notification, Form, Input, Select, Table, Collapse, Image, Modal, AutoComplete, Popconfirm } from "antd";
import '../../assets/styles/custom_style.css';
import { DeleteOutlined, EditOutlined, UploadOutlined } from "@ant-design/icons";
import PdfImage from "../../assets/images/document.png";
import DocImage from "../../assets/images/docs.png";
import XlsxImage from "../../assets/images/xlsx.png";
// import { DeleteOutlined } from '@ant-design/icons';

const { Item } = Form;
const { Option } = Select;
const { Panel } = Collapse;
const { confirm } = Modal;

const OccupationDetails = ({ user_id }) => {
    console.log("user_idaaaaaaa", user_id);
    const [form] = Form.useForm();
    const [recordId, setRecordId] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [userData, setUserData] = useState([]);
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    useEffect(() => {
        setUserData([]);
        setIsTableVisible(false);
        // Fetch user data or any other initial data here
        if (user_id) {
            fetchOccupationData(user_id);
        }
    }, [user_id]);

    async function fetchOccupationData(user_id) {
        try {
            const response = await axios.get(`${baseUrl}/occupation/list/${user_id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                }
            });
            if (response.data.status) {
                setUserData(response.data.result);
                setIsTableVisible(response.data?.result?.length === 0 ? false : true)
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const onFinish = async (values) => {
        try {
            // Check if at least one field is filled
            const isEmpty = Object.values(values).every(x => x === null || x === '' || x === undefined);

            if (isEmpty) {
                notification.info({
                    message: 'Info',
                    description: "Please fill out at least one field.",
                    placement: 'topRight'
                });
                return; // Exit the function if no fields are filled
            }
            if (user_id) {
                const formData = new FormData();
                Object.keys(values).forEach(key => {
                    formData.append(key, values[key] ? values[key] : '');
                });

                if (fileList.length > 0) {
                    fileList.forEach(file => {
                        const blob = new Blob([file.binary], { type: file.type });
                        formData.append('multiFiles', blob, file.name);
                    });
                }

                formData.append("user_id", user_id);

                let response;
                if (isUpdateMode) {
                    response = await axios.patch(`${baseUrl}/occupation/update/${recordId}`, formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                    });
                } else {
                    response = await axios.post(`${baseUrl}/occupation/create`, formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                    });
                }

                if (response.status === 200) {
                    notification.success({
                        message: 'Success',
                        description: isUpdateMode ? 'Occupation detail updated successfully!' : 'Occupation detail added successfully!',
                        placement: 'topRight'
                    });
                    setIsTableVisible(true);
                    fetchOccupationData(user_id); // Assuming you have a fetch function for social status data
                    form.resetFields(); // Reset form fields after successful submission
                    setFileList([]);
                } else {
                    notification.info({
                        message: 'Info',
                        description: response.data.message,
                        placement: 'topRight'
                    });
                }

            } else {
                notification.info({
                    message: 'Info',
                    description: "Please add basic information",
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    // const beforeUpload = (file) => {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //         setFileList(prevState => [...prevState, { name: file.name, binary: e.target.result }]);
    //     };
    //     reader.readAsArrayBuffer(file);
    //     return false; // Prevent automatic upload
    // };

    const beforeUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const newImage = { name: file.name, binary: e.target.result };
            setFileList(prevState => [...prevState, newImage]);
        };
        reader.readAsArrayBuffer(file);
        return false; // Prevent automatic upload
    };

    const handleEdit = (record) => {
        form.setFieldsValue({
            occ_name: record.occ_name,
            occ_field: record.occ_field,
            occ_category: record.occ_category,
            other_spec: record.other_spec,
            shop_no: record.shop_no,
            building_name: record.building_name,
            occ_street: record.occ_street,
            occ_locality: record.occ_locality,
            occ_district: record.occ_district,
            occ_state: record.occ_state,
            occ_country: record.occ_country,
            occ_zip_code: record.occ_zip_code,
            occ_office_phone_no: record.occ_office_phone_no,
            occ_mobile_no: record.occ_mobile_no,
        });
        setRecordId(record.id); // Assuming id is available in the record object
        setIsUpdateMode(true);
        setIsTableVisible(false); // Hide table and show form for editing
    };

    const showDeleteConfirm = (record) => {
        confirm({
            title: 'Are you sure you want to delete this occupation?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleDelete = async (record) => {
        try {
            const response = await axios.delete(`${baseUrl}/occupation/delete/${record.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: 'Occupation deleted successfully!',
                    placement: 'topRight'
                });
                fetchOccupationData(user_id); // Refresh table data after deletion
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    const deleteImage = async (id, imagePath) => {
        try {
            const response = await axios.delete(`${baseUrl}/occupation/deleteImage?id=${id}&imagePath=${imagePath}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}` // If you're using token-based authentication
                }
            });

            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: 'Occupation image deleted successfully!',
                    placement: 'topRight'
                });
                fetchOccupationData(user_id); // Refresh table data after deletion
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error(error);
            throw new Error('Failed to delete image');
        }
    };

    const handleExpand = (record) => {
        const key = record.id; // Use 'id' or another unique key from your data
        setExpandedRowKeys(expandedRowKeys.includes(key) ? [] : [key]);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'occ_name',
            key: 'name',
        },
        {
            title: 'Occupation Field',
            dataIndex: 'occ_field',
            key: 'occupation',
        },
        {
            title: 'Category',
            dataIndex: 'occ_category',
            key: 'category',
        },
        {
            title: 'Specffication',
            dataIndex: 'other_spec',
            key: 'other_spec',
        },
        {
            title: 'Shop No./Plot',
            dataIndex: 'shop_no',
            key: 'shop_no',
        },
        {
            title: 'Building Name',
            dataIndex: 'building_name',
            key: 'building_name	',
        },
        {
            title: 'Street',
            dataIndex: 'occ_street',
            key: 'occ_street',
        },
        {
            title: 'Locality/Area',
            dataIndex: 'occ_locality',
            key: 'occ_locality',
        },
        {
            title: 'District',
            dataIndex: 'occ_district',
            key: 'occ_district',
        },
        {
            title: 'State',
            dataIndex: 'occ_state',
            key: 'occ_state',
        },
        {
            title: 'Country',
            dataIndex: 'occ_country',
            key: 'occ_country',
        },
        {
            title: 'Pincode',
            dataIndex: 'occ_zip_code',
            key: 'occ_zip_code',
        },
        {
            title: 'Office no',
            dataIndex: 'occ_office_phone_no',
            key: 'occ_office_phone_no',
        },
        {
            title: 'Mobile no',
            dataIndex: 'occ_mobile_no',
            key: 'occ_mobile_no',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div className="button-container">
                    <Space size="middle">
                        <button className="update-btn" onClick={() => handleEdit(record)}> <EditOutlined /></button>
                        <button className="delete-btn" onClick={() => showDeleteConfirm(record)}><DeleteOutlined /></button>
                    </Space>
                </div>
            ),
        },
        // Add more columns as needed
    ];


    const [optionSuggestion, setOptionSuggestion] = useState([]);

    const getPanelValue = async (searchText, columnName, tableName) => {
        if (searchText.length < 3) {
            setOptionSuggestion([]);
            return;
        }

        try {
            const response = await axios.get(`${baseUrl}/payments/getSuggestionValue/`, {
                params: { q: searchText, column: columnName, table: tableName },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.status === 200) {
                setOptionSuggestion(response.data.result);
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChangeSuggestion = (value, columnName) => {
        form.setFieldsValue({
            [columnName]: value
        });
    };

    const changeTableView = async () => {
        if (isTableVisible) {
            setIsTableVisible(!isTableVisible)
            form.setFieldsValue({
                occ_name: "",
                occ_field: "",
                occ_category: "",
                other_spec: "",
                shop_no: "",
                building_name: "",
                occ_street: "",
                occ_locality: "",
                occ_district: "",
                occ_state: "",
                occ_country: "",
                occ_zip_code: "",
                occ_office_phone_no: "",
                occ_mobile_no: "",
            });
            setRecordId(null); // Assuming id is available in the record object
            setIsUpdateMode(false);
        } else {
            setIsTableVisible(!isTableVisible)
            // setIsTableVisible(false); // Hide table and show form for editing
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Button
                        type="primary"
                        onClick={() => changeTableView()}
                        style={{ marginBottom: '20px', float: 'right' }}
                    >
                        {isTableVisible ? 'Add Occupation' : 'Show Table'}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="tabled" >

                        {isTableVisible ? (
                            // <Table
                            //     columns={columns}
                            //     dataSource={userData}
                            //     pagination={false}
                            //     scroll={{ x: true }} // Ensures the table is horizontally scrollable
                            //     expandedRowKeys={expandedRowKeys}
                            //     onExpand={(expanded, record) => handleExpand(record)}
                            //     expandedRowRender={record => (
                            //         <Collapse defaultActiveKey={['1']}>
                            //             <Panel header="Occupation Images" key="1">
                            //                 <Image.PreviewGroup
                            //                     preview={{
                            //                         onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                            //                     }}
                            //                 >
                            //                     {record.occ_documents && JSON.parse(record.occ_documents).map((item, i) => {
                            //                         const isPdf = item.endsWith('.pdf');
                            //                         if (isPdf) {
                            //                             return (
                            //                                 <div key={i} style={{ width: 75, height: 75, position: 'relative' }}>
                            //                                     <a
                            //                                         href={`${baseUrl}/${item}`}
                            //                                         target="_blank"
                            //                                         rel="noopener noreferrer"
                            //                                         style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 10 }}
                            //                                     >
                            //                                         <img
                            //                                             src={PdfImage} // Replace with a suitable thumbnail image path
                            //                                             alt="PDF thumbnail"
                            //                                             style={{ width: '100%', height: '100%' }}
                            //                                         />
                            //                                     </a>
                            //                                 </div>
                            //                             );
                            //                         } else {
                            //                             return (
                            //                                 <Image
                            //                                     key={i}
                            //                                     width={75}
                            //                                     src={`${baseUrl}/${item}`}
                            //                                 />
                            //                             );
                            //                         }
                            //                     })}
                            //                 </Image.PreviewGroup>
                            //             </Panel>
                            //         </Collapse>
                            //     )}
                            //     rowKey="id"
                            // />
                            <Table
                                columns={columns}
                                dataSource={userData}
                                pagination={false}
                                scroll={{ x: true }} // Ensures the table is horizontally scrollable
                                expandedRowKeys={expandedRowKeys}
                                onExpand={(expanded, record) => handleExpand(record)}
                                expandedRowRender={record => (
                                    <Collapse defaultActiveKey={['1']}>
                                        <Panel header="Occupation Images" key="1">
                                            <Image.PreviewGroup
                                                preview={{
                                                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                                }}
                                            >
                                                {record.occ_documents && JSON.parse(record.occ_documents).map((item, i) => {
                                                    const isPdf = item.endsWith('.pdf');
                                                    const isDoc = item.endsWith('.doc') || item.endsWith('.docx');
                                                    const isXlsx = item.endsWith('.xlsx') || item.endsWith('.xls');

                                                    const handleDeleteImage = (imagePath) => {
                                                        // Call the delete API and handle response
                                                        deleteImage(record.id, imagePath);
                                                    };

                                                    const getFileThumbnail = () => {
                                                        if (isPdf) {
                                                            return PdfImage; // Use a suitable PDF thumbnail image
                                                        } else if (isDoc) {
                                                            return DocImage; // Use a suitable DOC thumbnail image
                                                        } else if (isXlsx) {
                                                            return XlsxImage; // Use a suitable XLSX thumbnail image
                                                        }
                                                        return null; // In case of other file types, you can return null or a default image
                                                    };

                                                    const fileThumbnail = getFileThumbnail();

                                                    return (
                                                        <div key={i} style={{ display: 'inline-block', margin: '5px', position: 'relative' }}>
                                                            <Popconfirm
                                                                title="Are you sure you want to delete this file?"
                                                                onConfirm={() => handleDeleteImage(item)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <DeleteOutlined
                                                                    style={{
                                                                        color: 'red',
                                                                        fontSize: '16px',
                                                                        position: 'absolute',
                                                                        top: -18,
                                                                        right: 0,
                                                                        cursor: 'pointer'
                                                                    }}
                                                                />
                                                            </Popconfirm>

                                                            {fileThumbnail ? (
                                                                <div style={{ width: 75, height: 75, position: 'relative' }}>
                                                                    <a
                                                                        href={item}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 10 }}
                                                                    >
                                                                        <img
                                                                            src={fileThumbnail}
                                                                            alt={`${item} thumbnail`}
                                                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                <Image
                                                                    width={75}
                                                                    height={75}
                                                                    src={item}
                                                                    style={{
                                                                        objectFit: 'cover',
                                                                        verticalAlign: 'middle',  // Ensures the image aligns vertically in the middle
                                                                        display: 'block'         // Removes any potential inline space that might affect alignment
                                                                    }}
                                                                />

                                                            )}
                                                        </div>
                                                    );
                                                })}

                                            </Image.PreviewGroup>
                                        </Panel>
                                    </Collapse>
                                )}
                                rowKey="id"
                            />
                        ) : (
                            <Form style={{ "padding": "0px" }} form={form} onFinish={onFinish} layout="vertical">
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Occupation" name="occ_name">
                                            <AutoComplete placeholder="Occupation"
                                                options={optionSuggestion}
                                                onChange={(value) => handleChangeSuggestion(value, "occ_name")}
                                                onSearch={(text) => getPanelValue(text, "occ_name", "sn_occupation")}
                                            />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Occupation Field" name="occ_field">
                                            <AutoComplete placeholder="Occupation Field"
                                                options={optionSuggestion}
                                                onChange={(value) => handleChangeSuggestion(value, "occ_field")}
                                                onSearch={(text) => getPanelValue(text, "occ_field", "sn_occupation")}
                                            />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Occ Category" name="occ_category">
                                            <AutoComplete placeholder="Occ Category"
                                                options={optionSuggestion}
                                                onChange={(value) => handleChangeSuggestion(value, "occ_category")}
                                                onSearch={(text) => getPanelValue(text, "occ_category", "sn_occupation")}
                                            />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Other Specification" name="other_spec">
                                            <Input placeholder="Other Specification" />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Shop No./Plot" name="shop_no">
                                            <Input placeholder="Shop No./Plot" />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Building Name" name="building_name">
                                            <Input placeholder="Building Name" />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Street" name="occ_street">
                                            <Input placeholder="Street" />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Locality/Area" name="occ_locality">
                                            <Input placeholder="Locality/Area" />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="District" name="occ_district">
                                            <AutoComplete placeholder="District"
                                                options={optionSuggestion}
                                                onChange={(value) => handleChangeSuggestion(value, "occ_district")}
                                                onSearch={(text) => getPanelValue(text, "occ_district", "sn_occupation")}
                                            />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="State" name="occ_state">
                                            <AutoComplete placeholder="State"
                                                options={optionSuggestion}
                                                onChange={(value) => handleChangeSuggestion(value, "occ_state")}
                                                onSearch={(text) => getPanelValue(text, "occ_state", "sn_occupation")}
                                            />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Country" name="occ_country">
                                            <AutoComplete placeholder="Country"
                                                options={optionSuggestion}
                                                onChange={(value) => handleChangeSuggestion(value, "occ_country")}
                                                onSearch={(text) => getPanelValue(text, "occ_country", "sn_occupation")}
                                            />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Pincode" name="occ_zip_code"
                                            rules={[
                                                {
                                                    pattern: /^\d+$/,
                                                    message: 'Please enter a valid number!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="pincode" />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Office Phone no." name="occ_office_phone_no"
                                            rules={[
                                                {
                                                    pattern: /^\d+$/,
                                                    message: 'Please enter a valid number!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Office Phone no." />
                                        </Item>
                                    </Col>
                                    <Col xs={24} sm={12} lg={4}>
                                        <Item label="Mobile no." name="occ_mobile_no"
                                            rules={[
                                                {
                                                    pattern: /^\d+$/,
                                                    message: 'Please enter a valid number!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Mobile no" />
                                        </Item>
                                    </Col>

                                    <Col xs={24} sm={12} lg={24}>
                                        <Upload
                                            beforeUpload={beforeUpload}
                                            multiple
                                            fileList={fileList.map(file => ({
                                                uid: file.name,
                                                name: file.name,
                                                status: 'done',
                                            }))}
                                            onRemove={(file) => {
                                                setFileList(prevState => prevState.filter(item => item.name !== file.name));
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Click To Upload Occupation Images</Button>
                                        </Upload>
                                    </Col>
                                </Row>
                                <div className="user_add_form_details" >
                                    <Space>
                                        <Button type="primary" onClick={() => form.submit()}>Save Detail</Button>
                                    </Space>
                                </div>
                            </Form>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default OccupationDetails;
